<template>
  <SfSection class="section products-carousel">
    <div class="products-carousel__header">
      <SfSkeleton v-if="loading" type="heading"></SfSkeleton>
      <h2 v-else-if="title && products.length">{{ title }}</h2>
    </div>
    <div v-if="loading" class="swiper-container swiper-loading">
      <ProductCardLoading v-for="i in 6" :key="i" />
    </div>
    <Swiper
      v-else
      class="swiper-container"
      :class="{ 'center-slides': mappedProducts.length < 3 }"
      :modules="[Navigation]"
      :navigation="true"
      :speed="400"
      :threshold="2"
      :slidesPerView="3"
      :slidesOffsetBefore="slidesOffset"
      :slidesOffsetAfter="slidesOffset"
      :spaceBetween="8"
      :centerInsufficientSlides="centerInsufficientSlides"
      :breakpoints="carouselBreakpointsConfig"
    >
      <SwiperSlide v-for="(product, index) in mappedProducts" :key="`${product.uid}-${index}`">
        <ProductCard
          :product="product"
          :imageLoadingType="getImageLoadingType(index)"
          :showAtcButton="showAtcButtons"
          :hide-rating="true"
        />
      </SwiperSlide>
    </Swiper>
  </SfSection>
</template>

<script setup>
import { Navigation } from 'swiper/modules'

const props = defineProps({
  title: {
    type: String,
    required: false,
    default: '',
  },
  products: {
    type: Array,
    required: false,
    default: () => [],
  },
  loading: {
    type: Boolean,
    required: true,
    default: true,
  },
  centerInsufficientSlides: {
    type: Boolean,
    required: false,
    default: false,
  },
  addToCartButtons: {
    type: Boolean,
    required: false,
    default: false,
  },
  isOnTopOfPage: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const mappedProducts = computed(() =>
  props.products.filter((product) => {
    // Exponea products don't have stock status
    if (product?.__typename === 'ExponeaProduct') {
      return product
    }

    // Check stock for the rest
    const isInStock =
      product?.stock_status === 'IN_STOCK' ||
      (product?.variants?.length > 0 &&
        product?.variants?.some(
          (variant) => variant.product?.stock_status === 'IN_STOCK' || variant?.out_of_stock?.[0] === 'false',
        ))

    return product?.sku && isInStock
  }),
)

const slidesOffset = props.centerInsufficientSlides && mappedProducts.value?.length < 3 ? 0 : 16

const carouselBreakpointsConfig = {
  542: {
    slidesPerView: 4,
    slidesOffsetBefore: slidesOffset,
    slidesOffsetAfter: slidesOffset,
    spaceBetween: 8,
  },
  756: {
    slidesPerView: 5,
    slidesOffsetBefore: slidesOffset,
    slidesOffsetAfter: slidesOffset,
    spaceBetween: 20,
  },
  1024: {
    slidesPerView: 5,
    slidesOffsetBefore: 0,
    slidesOffsetAfter: 0,
    spaceBetween: 20,
  },
}

/**
 * If carousel is on top of page, load the visible images early, the rest without loading property.
 * If carousel is not on top of page, load all lazy.
 *
 * @param {number} index - The index of the image.
 * @returns {string} The loading type for the image.
 */
const getImageLoadingType = (index) => {
  if (!props.isOnTopOfPage) return 'lazy'
  if (index < 3) {
    return 'eager'
  }
  if (index > 4) {
    return 'lazy'
  }
  return 'none'
}

const showAtcButtons = computed(() => {
  return props.addToCartButtons && !props.loading
})
</script>

<style lang="scss">
html.theme--storefront {
  @include for-mobile {
    .hst-container-item:has(.mj-product-carousel) {
      grid-column: 1 / -1;
    }
  }

  .products-carousel {
    position: relative;
    margin: 0;
    padding: 0 0 var(--spacer-lg);

    h2 {
      @include for-mobile {
        margin-left: 16px;
      }

      @media (min-width: 543px) {
        margin-bottom: var(--spacer-xl);
      }
    }

    .sf-skeleton--heading {
      margin-bottom: var(--spacer-xl);
      max-width: 40%;
      height: 40px;

      @include for-mobile {
        margin-bottom: var(--spacer-sm);
        margin-left: 16px;
        height: 25px;
      }
    }

    .sf-section__content {
      margin: var(--spacer-xl) var(--spacer-15) 0;

      @include for-mobile {
        margin: 0;
      }
    }

    .sf-arrow {
      --button-height: 36px;
      --button-width: 70px;
      --button-background: var(--gray-background-color);
      --button-color: var(--black-color);
      --button-border-radius: 0;

      @media (max-width: 542px) {
        display: none;
      }

      &:focus {
        --button-background: var(--gray-background-color);
        --button-color: var(--black-color);
      }

      &:hover {
        --button-background: var(--gray-background-color);
        --button-color: var(--black-color);
        box-shadow: var(--button-hover-box-shadow);
      }

      &:after {
        font-size: 16px;
        color: var(--black-color);
      }
    }

    .swiper-container.center-slides > .swiper-wrapper {
      @include for-mobile {
        display: flex;
        justify-content: center;
      }
    }

    .swiper-button-prev {
      left: 0;
    }

    .swiper-button-next {
      right: 0;
    }

    .swiper-css-mode {
      --swiper-offset-before: 16px;
      --swiper-offset-after: 16px;

      .swiper-slide-transform {
        width: 140px;
      }

      .swiper-slide:first-child {
        .swiper-slide-transform {
          padding-left: var(--swiper-offset-before);
          width: calc(140px + var(--swiper-offset-before));
        }
      }

      .swiper-slide:last-child {
        .swiper-slide-transform {
          width: calc(140px + var(--swiper-offset-before));
        }
      }
    }

    .swiper-loading {
      display: flex;
      overflow-x: scroll;
      flex-direction: row;
      gap: 20px;
      scrollbar-width: none;

      @include for-mobile {
        padding-left: 16px;

        .product-card {
          flex: 0 0 140px;
        }
      }

      @media (max-width: 756px) {
        gap: 8px;
      }

      @include for-desktop {
        .product-card:nth-child(n + 6) {
          display: none;
        }
      }
    }
  }
}
</style>
