<template>
  <footer id="footer" class="page-footer">
    <FooterTop />

    <FooterMiddle />

    <FooterBottom />
  </footer>
</template>

<style lang="scss">
html.theme--storefront {
  .page-footer {
    h2,
    h3,
    h4 {
      font-size: var(--font-size--base);
      font-family: var(--font-family--primary);
      font-weight: var(--font-weight--semibold);
      line-height: 22px;
      letter-spacing: 0.1px;

      @include for-desktop {
        margin-bottom: var(--spacer-sm);
        font-size: var(--font-size--lg);
        line-height: 24px;
      }
    }

    ul {
      list-style-type: none;

      li {
        a {
          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }
      }
    }

    p {
      margin-top: 0;
      margin-bottom: 1.33em;
      font-size: var(--font-size--xs);
      line-height: 1.5;

      @include for-desktop {
        font-size: var(--font-size--base);
      }
    }

    .newsletter {
      .title,
      .label {
        display: none;
      }
    }
  }
}
</style>
