<template>
  <form class="sf-header__search-form" method="get" @submit.prevent="submitSearch">
    <div
      v-click-outside="closeSearch"
      class="sf-search-bar sf-header__search"
      :class="{ 'sf-search-bar--has-term': !!term }"
    >
      <SfButton
        class="sf-search-bar__close-button sf-button--pure"
        type="button"
        :aria-label="$t('Close search')"
        data-testid="closeSearchButton"
        @click="closeSearch"
      >
        <ArrowLeftIcon />
      </SfButton>
      <input
        ref="searchInputElement"
        class="sf-search-bar__input"
        type="search"
        :placeholder="isDesktop ? $t('Search for products, ...') : $t('Search for products, categories, ...')"
        :aria-label="$t('Search')"
        name="q"
        autocomplete="off"
        :value="term"
        data-testid="searchInput"
        data-hj-allow
        @input="(e) => (term = e.target.value)"
        @keydown="handleSearch"
        @keydown.tab="hideSearch"
        @keydown.esc="closeSearch"
        @focus="showSearch"
        @click="showSearch"
      />
      <SfButton
        v-if="!!term"
        class="sf-search-bar__button sf-button--pure"
        :aria-label="$t('Search')"
        data-testid="searchButton"
        @click="closeSearch"
      >
        <span class="sf-search-bar__icon">
          <SearchIcon :aria-label="$t('Cancel')" />
        </span>
      </SfButton>
      <SfButton
        v-else
        class="sf-search-bar__button sf-button--pure"
        :aria-label="$t('Open search')"
        @click="toggleSearch"
        @keydown.tab="hideSearch"
      >
        <span class="sf-search-bar__icon">
          <SearchIcon :aria-label="$t('Search')" />
        </span>
      </SfButton>
    </div>
  </form>
</template>

<script setup>
import debounce from '~/utils/debounce'
import { clickOutside } from '~/components/Sf/utilities/directives/index.js'

import ArrowLeftIcon from '~/storefront/assets/icons/arrow-left.svg'
import SearchIcon from '~/storefront/assets/icons/search.svg'

const emit = defineEmits(['SearchBar:toggle'])

const vClickOutside = clickOutside

const { isDesktop } = useDevice()
const route = useRoute()
const router = useRouter()
const isSearchOpen = ref(false)
const searchInputElement = ref()

const searchStore = useSearchStore()
const { term } = storeToRefs(searchStore)
const { searchContent, searchSuggest } = searchStore
const { clearFilters } = useBloomreachDiscoveryStore()
const { $localePath } = useNuxtApp()

const MIN_TERM_LENGTH = 1

const showSearch = () => {
  if (!term.value?.length) return

  if (!isSearchOpen.value) {
    isSearchOpen.value = true
    emit('SearchBar:toggle', true)
    document?.documentElement.classList.add('no-scroll', 'no-scroll-safari')
  }
}

const hideSearch = () => {
  if (isSearchOpen.value) {
    isSearchOpen.value = false
    searchInputElement.value?.blur()
    emit('SearchBar:toggle', false)
    document?.documentElement.classList.remove('no-scroll', 'no-scroll-safari')

    setTimeout(() => (term.value = ''), 10)
  }
}

const toggleSearch = () => {
  if (isSearchOpen.value) {
    hideSearch()
  } else {
    showSearch()
  }
}

const closeSearch = (event) => {
  if (document) {
    const searchResultsEl = document.getElementsByClassName('search')
    const wishlistSelectionEl = document.getElementsByClassName('wishlist-variant-selection-modal')
    const sidebarEl = document.getElementsByClassName('sidebar')

    if (
      !searchResultsEl[0]?.contains(event.target) &&
      !wishlistSelectionEl[0]?.contains(event.target) &&
      !sidebarEl[0]?.contains(event.target) &&
      !event.target?.closest('.sidebar')
    ) {
      hideSearch()
    }
  } else {
    hideSearch()
  }
}

const submitSearch = async () => {
  if (!term.value) return

  clearFilters()
  handleSearch.cancel()

  await router
    .push({
      path: `${$localePath('/catalogsearch/result/')}`,
      query: { q: term.value },
    })
    .catch(() => {})
}

const handleSearch = debounce(async () => {
  if (term.value.length < MIN_TERM_LENGTH) {
    hideSearch()
    return
  }
  showSearch()

  await Promise.all([searchSuggest(term.value), searchContent(term.value)])
}, 500)

watch(route, hideSearch)
</script>

<style lang="scss">
html.theme--storefront {
  @mixin placeholder {
    &::-webkit-input-placeholder {
      @content;
    }
    &::-moz-placeholder {
      @content;
    }
    &:-ms-input-placeholder {
      @content;
    }
  }
  @mixin iconPosition {
    flex-direction: var(--search-bar-icon-flex-direction, row);
    .sf-search-bar__input {
      --search-bar-input-text-align: center;
      &::placeholder {
        text-align: center;
      }
    }
  }
  .sf-search-bar {
    box-sizing: border-box;
    position: relative;
    display: var(--search-bar-display, flex);
    align-items: center;
    background: var(--search-bar-background);
    padding: var(--search-bar-padding);
    width: var(--search-bar-width);
    height: var(--search-bar-height, 2rem);
    &__button {
      position: absolute;
      bottom: var(--spacer-xs);
      right: var(--search-bar-button-right, 0);
    }
    &__icon {
      --icon-size: 1.25rem;
      display: var(--search-bar-icon-display, block);
    }
    &__input {
      position: relative;
      flex-grow: 1;
      background: transparent;
      text-align: var(--search-bar-input-text-align);
      width: 100%;
      height: 100%;
      @include font(
        --search-bar-font,
        var(--font-weight--normal),
        var(--font-size--base),
        1.6,
        var(--font-family--secondary)
      );
      @include border(--search-bar-border, 0 0 2px 0, solid, var(--c-dark-variant));
      @include placeholder {
        color: var(--search-bar-placeholder-color, var(--c-gray));
        transition: var(--search-bar-placeholder-transition, color 0.2s ease-in);
        font: inherit;
      }
      &:focus {
        --search-bar-border-color: var(--c-primary);
        @include placeholder {
          color: var(--search-bar-placeholder-color-focus, transparent);
        }
      }
      &::-webkit-search-cancel-button {
        opacity: 0;
      }
      &[type='search'] {
        -webkit-appearance: none;
      }
      &::-ms-clear {
        opacity: 0;
      }
    }
    &--position-center {
      @include iconPosition();
    }
    &--no-icon {
      --search-bar-icon-display: none;
    }
    @include for-desktop {
      --search-bar-width: 20rem;
    }
  }

  .sf-header__search-form {
    flex: 0 0 100%;
  }

  .sf-search-bar {
    transition: padding 0.25s ease;
    will-change: padding;

    .sf-search-bar__close-button {
      position: absolute;
      left: 0;
      visibility: hidden;
      opacity: 0;
      --button-width: 44px;
      --button-height: 46px;
      --button-background: var(--gray-background-color);
      --button-padding: 1px;
      --button-border-radius: 0;

      @include for-desktop {
        --button-height: 48px;

        &:hover {
          --button-background: var(--primary-color);
        }
      }
    }

    &.sf-search-bar--has-term {
      padding-left: 56px;
      transition: all 0.25s ease;

      .sf-search-bar__close-button {
        visibility: visible;
        opacity: 1;
      }
    }

    &__button {
      position: absolute;
      bottom: 0 !important;
      --button-height: 100%;

      @include for-desktop {
        --button-height: var(--spacer-3rem) !important;
        --search-bar-button-right: var(--spacer-sm);
      }
    }

    &__icon {
      height: var(--spacer-base);
    }

    &__input {
      --search-bar-border-color: var(--gray-dark-accent-color);
      padding: 0 var(--spacer-12);
      border-radius: var(--spacer-2xs);
      outline: none;

      ::placeholder {
        color: var(--black-secondary-color);
        opacity: 1;
      }

      @include for-desktop {
        padding: 0 var(--spacer-xl) 0 var(--spacer-md);
      }

      &:focus {
        --search-bar-border-color: var(--secondary-color) !important;
      }
    }
  }
}
</style>
