<template>
  <div id="wishlist">
    <Sidebar
      position="right"
      :title="$t('Wishlist')"
      description=""
      :visible="isWishlistSidebarOpen"
      @close="toggleWishlistSidebar"
    >
      <SfLoader class="my-wishlist-wrapper" :loading="!wishlistLoaded">
        <div v-if="wishlistIds.items_count > 0" key="my-wishlist" class="my-wishlist">
          <SfProperty class="my-wishlist__total-items" :name="$t('Total items')" :value="wishlistIds.items_count" />
          <div class="collected-product-list">
            <transition-group name="sf-fade" tag="div">
              <WishlistItem
                v-for="(wishlistItem, index) in products"
                :key="`wishlistItem-${index}`"
                :wishlistItem="wishlistItem"
                @itemAddedToCart="itemAddedToCart"
              />
            </transition-group>
          </div>
        </div>
        <div v-else key="empty-wishlist" class="empty-wishlist">
          <SfProperty
            class="empty-wishlist__subtitle sf-property--value"
            :value="$t('You have no items in your wishlist.')"
          />
          <CartAlternativeProducts
            v-if="exponeaRecommendations && exponeaRecommendations.length"
            class="empty-wishlist__alternatives"
            :items="exponeaRecommendations"
            :title="$t('We believe you might like these items')"
          />
        </div>
      </SfLoader>
      <template #content-bottom>
        <div v-if="isAuthenticated">
          <SfLink
            v-if="isAuthenticated"
            class="sf-button sf-button--black sf-button--full-width color-secondary"
            :class="{ 'sf-button--small': wishlistIds.items_count > 10 }"
            :link="localePath(`/wishlist`)"
            :data-link="localePath(`/customer/account/${$t('My wishlist').toLowerCase().replaceAll(' ', '-')}`)"
            data-testid="wishlist-sidebar-view-all"
            @click="toggleWishlistSidebar"
          >
            {{ $t(wishlistIds.items_count > 10 ? 'View all items on your wishlist' : 'Extended overview') }}
          </SfLink>
        </div>
        <div v-else>
          <p class="my-wishlist__login-title">{{ $t('Log in to save your favourites!') }}</p>
          <NuxtLink
            class="sf-button sf-button--full-width sf-button--pink"
            :to="localePath('/customer/account/login')"
            data-testid="wishlist-sidebar-login"
            @click.native="toggleWishlistSidebar"
          >
            {{ $t('Sign in') }}
          </NuxtLink>
        </div>
      </template>
    </Sidebar>
  </div>
</template>
<script setup lang="ts">
import useExponeaConstants from '~/utils/constants/exponea'

const { send: sendNotification } = useUiNotification()
const uiState = useUiState()
const { toggleWishlistSidebar } = uiState
const { isWishlistSidebarOpen } = storeToRefs(uiState)
const { loadWishlist } = useWishlistStore()
const { wishlist, wishlistIds } = storeToRefs(useWishlistStore())
const { isAuthenticated } = useUser()
const localePath = useLocalePath()
const { t } = useI18n()

const wishlistLoaded = ref(false)
const products = computed(() => {
  return (
    wishlist.value?.items_v2?.items?.filter(Boolean)?.sort((a, b) => new Date(b.added_at) - new Date(a.added_at)) ||
    null
  )
})

// Exponea recommended products
const exponeaStore = useExponeaStore()
const { recommendations } = storeToRefs(exponeaStore)
const { EMPTY_CART_RECOMMENDATIONS_ID } = useExponeaConstants()
const exponeaRecommendations = computed(() => recommendations.value.get(EMPTY_CART_RECOMMENDATIONS_ID)?.products || [])

const loadWishlistOrAlternativeProducts = async () => {
  await loadWishlist()
  if (!wishlist.value?.items_count && !exponeaRecommendations.value?.length) {
    await exponeaStore.getRecommendation({ id: EMPTY_CART_RECOMMENDATIONS_ID, fillWithRandom: true, size: 6 })
  }
  wishlistLoaded.value = true
}

const itemAddedToCart = ({ name, response }) => {
  const success = response.status
  const errorMessage =
    response.add_wishlist_items_to_cart_user_errors?.[0]?.message ||
    t("Unfortunately, this item is sold out and can't be added to your shopping cart.")
  sendNotification({
    id: Symbol('user_updated'),
    message: success ? t('You added {product} to your shopping cart.', { product: name }) : errorMessage,
    type: success ? 'success' : 'danger',
    icon: success ? 'added_to_cart' : 'error',
    persist: false,
    title: success ? t('Added to cart') : '',
  })
}

watch(isWishlistSidebarOpen, () => {
  if (isWishlistSidebarOpen.value && !wishlistLoaded.value) {
    loadWishlistOrAlternativeProducts()
  }
})
</script>

<style lang="scss" scoped>
html.theme--storefront {
  #wishlist {
    --sidebar-z-index: 10;
    --overlay-z-index: 10;
  }

  .my-wishlist {
    flex: 1;
    display: flex;
    flex-direction: column;

    &__total-items {
      margin-top: var(--spacer-sm);
      --property-name-margin: 0 var(--spacer-sm) 0 0;
      --property-name-font-size: var(--font-size--sm);
      --property-name-font-line-height: 1.4286;
      --property-value-font-size: var(--font-size--sm);
      --property-value-font-line-height: 1.4286;
      --property-name-color: var(--black-color);
      --property-value-color: var(--black-color);
      --property-name-font-family: var(--font-family--primary);
      --property-value-font-family: var(--font-family--primary);
      --property-name-font-weight: var(--font-weight--semibold);
      --property-value-font-weight: var(--font-weight--semibold);
    }

    &__total-price {
      --property-name-font-size: var(--font-size--xl);
      --price-font-size: var(--font-size--xl);
      margin: 0 0 var(--spacer-xl) 0;
      &-label {
        font: var(--font-weight--normal) var(--font-size--2xl) / 1.6 var(--font-family--secondary);
        color: var(--c-link);
      }
    }

    &__login-title {
      font-weight: var(--font-weight--semibold);
      line-height: var(--global-line-height);
      margin: 0 0 var(--spacer-sm);
      text-align: center;
    }
  }

  .empty-wishlist {
    display: flex;
    flex: 1;
    flex-direction: column;

    &__banner {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    &__label,
    &__description {
      text-align: center;
    }
    &__label {
      --heading-description-margin: 0 0 var(--spacer-xl) 0;
      --heading-title-margin: 0 0 var(--spacer-xl) 0;
      --heading-title-color: var(--c-primary);
      --heading-title-font-weight: var(--font-weight--semibold);
      @include for-desktop {
        --heading-title-font-size: var(--font-size--xl);
        --heading-title-margin: 0 0 var(--spacer-sm) 0;
      }
    }
    &__icon {
      --image-width: 16rem;
      margin: 0 0 var(--spacer-2xl) 7.5rem;
    }
  }
  .heading {
    &__wrapper {
      --heading-title-color: var(--c-link);
      --heading-title-font-weight: var(--font-weight--semibold);
      display: flex;
      justify-content: space-between;
    }

    &__close {
      color: var(--c-gray-variant);
      cursor: pointer;
    }
  }

  .sidebar-bottom {
    margin: auto 0 0 0;
  }

  .collected-product-list {
    flex: 1;

    .sf-collected-product {
      &__aside {
        flex: 0 0 5rem;
      }

      &__image {
        height: auto;
        aspect-ratio: var(--product-image-ratio);
      }
    }
  }

  .sf-collected-product {
    margin: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    --collected-product-width: 100%;

    &__add-to-cart {
      position: absolute;
      right: 0;
      bottom: 52px;
      --button-width: 100px;
      --button-height: var(--spacer-lg);
      --button-padding: var(--spacer-2xs);

      &-loader {
        --loader-color: var(--white-color);
      }
    }

    &__add-to-cart-loader {
      --loader-overlay-background: transparent;

      svg {
        width: auto;
        height: 100%;
      }
    }

    .collected-product__configurable-options {
      list-style: none;
      margin: 0 auto 0 0;
      padding: 0 var(--spacer-2xs);
      font-size: var(--font-size-10);
      line-height: var(--spacer-18);
      color: var(--gray-primary-color);
      flex: 1;

      li {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      @include for-desktop {
        font-size: var(--font-size-13);
      }
    }

    &__actions {
      transition: opacity 150ms ease-in-out;
    }

    &__save,
    &__compare {
      --button-padding: 0;

      &:focus {
        --cp-save-opacity: 1;
        --cp-compare-opacity: 1;
      }
    }

    &__save {
      opacity: var(--cp-save-opacity, 0);
    }

    &__compare {
      opacity: var(--cp-compare-opacity, 0);
    }

    &:hover {
      --cp-save-opacity: 1;
      --cp-compare-opacity: 1;
    }
    .sf-badge__absolute {
      position: absolute;
    }
  }

  .my-wishlist-wrapper {
    flex: 1;
    display: flex;
    height: auto;
    flex-direction: column;
  }
  .notifications {
    z-index: 10;

    .sf-notification--wishlist {
      --notification-font-size: var(--font-size-sm);
    }
  }
}
</style>
