<template>
  <div class="sidebar" :class="`sidebar--${position || 'right'}`">
    <SfOverlay :visible="visible" @click="close" />
    <transition name="slide-right">
      <div v-if="visible && isOpen" v-focus-trap class="sidebar__aside-outer">
        <SidebarCloseButton @close="close" />

        <aside class="sidebar__aside">
          <div class="sidebar__top">
            <slot name="top" />
          </div>

          <div class="sidebar__content">
            <SidebarTitle
              :class="{ 'sidebar__close-button--visible': visible }"
              :title="title"
              :description="description"
            />
            <slot name="content-top" />
            <slot name="default" />

            <div class="sidebar__content-bottom">
              <slot name="content-bottom" />
            </div>
          </div>
        </aside>

        <div class="sidebar__bottom">
          <slot name="bottom" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { focusTrap } from './../Sf/utilities/directives'

type SidebarPosition = 'left' | 'right'
const vFocusTrap = focusTrap

const emit = defineEmits(['close'])
const route = useRoute()

const props = defineProps({
  title: {
    type: String,
    required: false,
    default: '',
  },
  description: {
    type: String,
    required: false,
    default: '',
  },
  position: {
    type: String as PropType<SidebarPosition>,
    default: 'right',
  },
  visible: {
    type: Boolean,
    default: false,
  },
  overlay: {
    type: Boolean,
    default: false,
  },
})

const transition = ref(props.position)
const isOpen = ref(false)

const close = () => {
  isOpen.value = false
  emit('close')
}

const keydownHandler = (e) => {
  if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
    close()
  }
}

watch(
  () => route?.path,
  (to, from) => {
    if (to === from) return
    const { closeAllSidebars } = useUiState()
    closeAllSidebars()
  },
)

watch(
  () => props.visible,
  (value) => {
    if (!import.meta.client) return
    if (value) {
      document.documentElement.classList.add('no-scroll')
      isOpen.value = true
      transition.value = props.position
      document.addEventListener('keydown', keydownHandler)
    } else {
      document.documentElement.classList.remove('no-scroll')
      isOpen.value = false
      transition.value = props.position === 'right' ? 'left' : 'right'
      document.removeEventListener('keydown', keydownHandler)
    }
  },
  { immediate: true },
)
</script>

<style lang="scss">
html.theme--storefront {
  .slide-enter-active {
    transition: all 0.3s ease-out;
  }

  .slide-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-enter-from,
  .slide-leave-to {
    transform: translateX(20px);
  }

  .sidebar {
    .sf-property {
      --property-name-margin: 0 var(--spacer-sm) 0 0;
      --property-name-font-size: var(--font-size--sm);
      --property-name-font-line-height: 1.4286;
      --property-value-font-size: var(--font-size--xs);
      --property-value-font-line-height: var(--spacer-18);

      &--value {
        --property-name-margin: 0;
        --property-name-content: unset;
        --property-name-font-size: 0;
      }
    }

    .sidebar__close-button {
      position: fixed;
      top: 0;
      left: var(--sidebar-width);
      z-index: 1;
    }

    &__aside-outer {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: var(--sidebar-z-index, 10);
      box-sizing: border-box;
      pointer-events: none;

      @include for-desktop {
        padding-left: 0;
        width: var(--sidebar-width);
      }

      > * {
        pointer-events: auto;
      }
    }

    &__aside {
      overflow: auto;
      background-color: var(--white-color);
      width: calc(100vw - var(--sidebar-button-width));
      height: 100%;
      -webkit-overflow-scrolling: touch;

      @include for-desktop {
        width: var(--sidebar-width);
      }
    }

    &__content {
      position: relative;
      display: flex;
      flex-direction: column;
      min-height: 100%;
      padding: var(--spacer-sm) var(--spacer-sm) 0;

      @include for-desktop {
        padding: var(--spacer-base) var(--spacer-base) 0;
      }

      &-bottom {
        background-color: var(--white-color);
        position: sticky;
        bottom: 0;
        padding-top: var(--spacer-xs);
        padding-bottom: var(--spacer-sm);
        margin-top: auto;
        z-index: 1;

        @include for-desktop {
          padding-bottom: var(--spacer-base);
        }
      }
    }

    &--right {
      .sidebar__aside-outer {
        @include for-desktop {
          left: auto;
          right: 0;
          width: var(--sidebar-width);
        }
      }

      .sidebar__aside {
        margin-left: auto;
      }

      .sidebar__close-button {
        left: auto;
        right: var(--sidebar-width);
      }
    }

    .sf-checkbox__checkmark.is-active {
      background: var(--black-color);
      border-color: var(--black-color);
    }

    .sf-radio .sf-radio__checkmark.is-active {
      border-color: var(--black-color);
    }

    .sf-section__content {
      margin: 0;
    }

    .sf-accordion-item__content {
      --accordion-item-content-padding: 0;
    }

    .sf-heading--left .sf-heading__title.h2 {
      margin-bottom: var(--spacer-sm);
    }
  }
}
</style>
