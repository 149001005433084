<template>
  <div ref="swiperContainer" class="swiper">
    <div class="swiper-wrapper">
      <slot></slot>
    </div>

    <template v-if="navigation">
      <SfButton
        v-show="swiper?.initialized"
        ref="prevButtonEl"
        class="sf-arrow swiper-button swiper-button-prev"
        aria-label="prev"
      />
      <SfButton ref="nextButtonEl" class="sf-arrow swiper-button swiper-button-next" aria-label="next" />
    </template>
    <div v-if="pagination" class="swiper-pagination"></div>
  </div>
</template>

<script setup lang="ts">
import Swiper from 'swiper'

import type { SwiperModule, ThumbsOptions } from 'swiper/types'

const props = defineProps({
  autoplay: {
    type: [Object, Boolean],
    default: false,
  },
  breakpoints: Object,
  centerInsufficientSlides: Boolean,
  centeredSlides: Boolean,
  direction: {
    type: String as PropType<'horizontal' | 'vertical'>,
    default: 'horizontal',
  },
  loop: Boolean,
  modules: Array as PropType<SwiperModule[]>,
  navigation: Boolean,
  pagination: Boolean,
  roundLengths: Boolean,
  slidesOffsetAfter: {
    type: Number,
    default: 1,
  },
  slidesOffsetBefore: {
    type: Number,
    default: 1,
  },
  slidesPerGroup: {
    type: Number,
    default: 1,
  },
  slidesPerView: {
    type: [Number, String] as PropType<number | 'auto'>,
    default: 'auto',
  },
  spaceBetween: {
    type: Number,
    default: 0,
  },
  speed: Number,
  threshold: Number,
  thumbs: Object as PropType<ThumbsOptions>,
  watchSlidesProgress: Boolean,
})

const emit = defineEmits(['active-index-change', 'slide-change'])

const swiper = ref()
const swiperContainer = ref()

const prevButtonEl = ref()
const nextButtonEl = ref()

const initializeSwiper = () => {
  swiper.value = new Swiper(swiperContainer.value, {
    autoplay: props.autoplay,
    centerInsufficientSlides: props.centerInsufficientSlides,
    centeredSlides: props.centeredSlides,
    direction: props.direction,
    loop: props.loop,
    modules: props.modules,
    navigation: props.navigation && {
      nextEl: nextButtonEl.value.$el,
      prevEl: prevButtonEl.value.$el,
    },
    observer: true,
    pagination: props.pagination && {
      el: '.swiper-pagination',
      type: 'bullets',
    },
    roundLengths: props.roundLengths,
    slidesOffsetAfter: 0,
    slidesOffsetBefore: 0,
    slidesPerGroup: props.slidesPerGroup,
    slidesPerView: 'auto',
    spaceBetween: 0,
    speed: props.speed || 400,
    threshold: props.threshold,
    thumbs: props.thumbs,
    watchSlidesProgress: props.watchSlidesProgress,
    cssMode: true,
    breakpoints: {
      ...props.breakpoints,
      1024: {
        cssMode: false,
        slidesPerView: props.slidesPerView,
        spaceBetween: props.spaceBetween,
        slidesOffsetAfter: props.slidesOffsetAfter,
        slidesOffsetBefore: props.slidesOffsetBefore,
        ...props.breakpoints?.['1024'],
      },
    },
  })

  swiper.value.on('activeIndexChange', (swiperInstance) => {
    emit('active-index-change', swiperInstance)
  })

  swiper.value.on('slideChange', (swiperInstance) => {
    emit('slide-change', swiperInstance)
  })
}

const slidesOffsetBefore = computed(() => `${props.slidesOffsetBefore}px`)
const slidesOffsetAfter = computed(() => `${props.slidesOffsetAfter}px`)
const spaceBetween = computed(() => `${props.spaceBetween}px`)
onMounted(initializeSwiper)
</script>

<style lang="scss" scoped>
html.theme--storefront {
  .swiper {
    --swiper-offset-before: v-bind(slidesOffsetBefore);
    --swiper-offset-after: v-bind(slidesOffsetAfter);
    --swiper-space-between: v-bind(spaceBetween);
  }

  .sf-button {
    position: absolute;
    --button-width: 0px;
    --button-height: 40px;
    --button-background: var(--gray-background-color);
    --button-color: var(--black-color);
    --button-border-radius: 0;
    top: var(--swiper-navigation-top-offset, 50%);
    z-index: 3;

    @include for-desktop {
      --button-width: 70px;
    }

    &.swiper-button {
      @include for-mobile {
        padding: 0;
        width: 32px;
        height: 32px;

        &:after {
          font-size: 16px;
        }
      }
    }

    &.swiper-button-disabled {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }

    &:focus {
      --button-background: var(--gray-background-color);
      --button-color: var(--black-color);
    }

    &:active {
      &:hover {
        @include for-desktop {
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
      }
    }

    &:hover {
      --button-background: var(--gray-background-color);

      @include for-desktop {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }

    &.is-loading {
      opacity: 0;
    }
  }
}
</style>
