import { TYPE_CONTAINER_INLINE, TYPE_CONTAINER_ITEM_UNDEFINED, TYPE_CONTAINER_NO_MARKUP } from '@bloomreach/spa-sdk'
import type { BrMapping } from '@bloomreach/vue-sdk'

import Fallback from '~/storefront/components/Fallback.vue'
import Banner from '~/storefront/components/Bloomreach/Banner.vue'
import Photoslurp from '~/storefront/components/Bloomreach/Photoslurp.vue'
import LogoBanner from '~/storefront/components/Bloomreach/LogoBanner.vue'
import RichText from '~/storefront/components/Bloomreach/RichText.vue'
import InlineNotification from '~/storefront/components/Bloomreach/InlineNotification.vue'
import AnnouncementHeader from '~/storefront/components/Bloomreach/AnnouncementHeader.vue'
import BlogPostHeader from '~/storefront/components/Bloomreach/BlogPostHeader.vue'
import ArticleFeed from '~/storefront/components/Bloomreach/ArticleFeed.vue'
import CategoryHeader from '~/storefront/components/Bloomreach/CategoryHeader.vue'
import HeaderNotification from '~/storefront/components/Bloomreach/HeaderNotification.vue'
import TopHeaderMenu from '~/storefront/components/TopHeaderMenu.vue'
import FooterCtaSubscription from '~/storefront/components/Footer/CtaSubscription.vue'
import FooterCtaBoutique from '~/storefront/components/Footer/CtaBoutique.vue'
import FooterCtaReviews from '~/storefront/components/Footer/CtaReviews.vue'
import CtaChat from '~/storefront/components/Footer/CtaChat.vue'
import SeoText from '~/storefront/components/Bloomreach/SeoText.vue'
import TikTok from '~/storefront/components/Bloomreach/TikTok.vue'
import Video from '~/storefront/components/Bloomreach/Video.vue'
import ShopInShop from '~/storefront/components/Bloomreach/ShopInShop.vue'
import ProductCarousel from '~/storefront/components/Bloomreach/ProductCarousel.vue'
import IndividualProductCard from '~/storefront/components/Bloomreach/IndividualProductCard.vue'
import Accordion from '~/storefront/components/Bloomreach/Accordion.vue'
import Identifier from '~/storefront/components/Bloomreach/Identifier.vue'
import Instagram from '~/storefront/components/Bloomreach/Instagram.vue'
import StoreGrid from '~/storefront/components/Bloomreach/StoreGrid.vue'
import InlineContainer from '~/storefront/components/Bloomreach/InlineContainer.vue'
import Row from '~/storefront/components/Bloomreach/Row.vue'
import ListHorizontal from '~/storefront/components/Category/ListHorizontal.vue'
import ContentSearchResult from '~/storefront/components/SearchBar/ContentSearchResult.vue'
import RecommendationsCarousel from '~/storefront/components/Bloomreach/RecommendationsCarousel.vue'

import PiercingBooking from '~/storefront/components/Bloomreach/PiercingStores/PiercingBooking.vue'
import Grid from '~/storefront/components/Bloomreach/PiercingStores/Grid.vue'
import ExponeaForm from '~/storefront/components/Bloomreach/ExponeaForm.vue'
import BloomreachData from '~/storefront/components/Bloomreach/BloomreachData.vue'
import Qualifio from '~/storefront/components/Bloomreach/Qualifio.vue'

export default () => {
  const mapping: BrMapping = {
    menu: TopHeaderMenu,
    mjBanner: Banner,
    mjPhotoslurp: Photoslurp,
    mjLogoBanner: LogoBanner,
    mjSEO: SeoText,
    mjHeaderNotification: HeaderNotification,
    mjFooterCtaSubscription: FooterCtaSubscription,
    mjFooterCTABoutique: FooterCtaBoutique,
    mjFooterCTAReviews: FooterCtaReviews,
    mjRichText: RichText,
    mjAnnouncementHeader: AnnouncementHeader,
    mjArticleFeed: ArticleFeed,
    mjBlogPostHeader: BlogPostHeader,
    mjCategoryHeader: CategoryHeader,
    mjFaq: Accordion,
    mjFooterCtaChat: CtaChat,
    mjForm: ExponeaForm,
    mjIdentifier: Identifier,
    mjInlineNotification: InlineNotification,
    mjInstagram: Instagram,
    mjPiercingBooking: PiercingBooking,
    mjPiercingGrid: Grid,
    mjProductCarousel: ProductCarousel,
    mjProduct: IndividualProductCard,
    mjQuickFilter: ListHorizontal,
    mjRow: Row,
    mjTiktok: TikTok,
    mjVideo: Video,
    mjShopInShopGrid: ShopInShop,
    mjStoreGrid: StoreGrid,
    mjSearchContent: ContentSearchResult,
    mjUSPSearchPLP: BloomreachData,
    mjPDPTextSearch: BloomreachData,
    mjUSPSearch: BloomreachData,
    mjBrRecommendations: RecommendationsCarousel,
    mjQualifio: Qualifio,
    [TYPE_CONTAINER_INLINE]: InlineContainer,
    [TYPE_CONTAINER_ITEM_UNDEFINED]: Fallback,
    [TYPE_CONTAINER_NO_MARKUP]: InlineContainer,
  }

  return { mapping }
}
