<template>
  <div
    class="sf-input"
    :class="{
      'has-text': !!inputValue,
      invalid: !!inputValue && errorMessage,
    }"
    :data-testid="name"
  >
    <div class="sf-input__wrapper">
      <textarea
        :id="name"
        class="sf-textarea"
        :name
        :type
        :value="inputValue"
        :autocomplete
        placeholder=""
        :rows
        :required
        @input="handleChange"
        @blur="handleBlur"
      />
      <label class="sf-input__label" :for="name">{{ label }}</label>
      <p v-if="errorMessage" class="sf-input__error-message">
        {{ errorMessage }}
      </p>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  value: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'text',
  },
  autocomplete: {
    type: String,
    default: '',
  },
  rows: {
    type: Number,
    default: 3,
  },
  required: {
    type: Boolean,
    default: false,
  },
})

const name = toRef(props, 'name')

const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(name, undefined, {
  initialValue: props.value.value,
})
</script>

<style lang="scss" scoped>
html.theme--storefront {
  textarea {
    width: 100%;
    border: 1px solid var(--input-border-color);
    padding: var(--spacer-md) var(--spacer-sm);
    border-radius: var(--spacer-2xs);
    font-family: var(--font-family--primary);
    font-size: var(--font-size--lg);

    &:focus,
    &:focus-visible {
      border: 1px solid var(--secondary-color);
      outline: none;
    }
  }

  .sf-input {
    --c-primary: #333;
  }

  .sf-input__label {
    top: 6px;
    font-size: 16px;
    transform: none;

    &::after {
      content: ' *';
    }
  }

  .sf-input textarea:focus ~ .sf-input__label,
  .sf-input textarea:not(:placeholder-shown) ~ .sf-input__label {
    top: 0px;
    font-size: 10px;
  }

  .sf-input__error-message {
    margin: 0;
  }
}
</style>
