<template>
  <div class="app-header grid-container">
    <SfHeader :class="{ 'header-on-top': isSearchOpen }">
      <template #logo>
        <HeaderLogo />
      </template>

      <template #header-icons>
        <SfButton
          class="sf-button--pure sf-header__mobile-menu-toggle mobile-only"
          aria-label="Menu"
          data-testid="toggleMobileMenuIcon"
          @click="toggleNavMenu"
        >
          <BarsIcon />
          <span>menu</span>
        </SfButton>
      </template>

      <template #default>
        {{ null }}
      </template>

      <template #aside>
        <div class="sf-header__icons">
          <StoreSwitcher class="desktop-only sf-header__action--switcher" />
          <ClientOnly>
            <SfLink
              class="sf-button sf-button--pure sf-header__action"
              :aria-label="$t('Account')"
              :link="accountUrl"
              data-testid="accountIcon"
            >
              <UserIcon />
              <SfBadge v-if="isLoggedIn" class="sf-badge--number logged-in-badge">
                <CheckIcon width="10" height="8" />
              </SfBadge>
            </SfLink>
          </ClientOnly>
          <SfButton
            class="sf-button--pure sf-header__action sf-header__action--wishlist"
            aria-label="Wishlist"
            data-testid="wishlistIcon"
            @click="toggleWishlistSidebar"
          >
            <WishlistIcon :fill="wishlistItemsQty ? '' : 'currentColor'" />
            <SfBadge v-if="wishlistItemsQty" class="sf-badge--number cart-badge" :data-count="wishlistItemsQty">
              {{ wishlistItemsQty }}
            </SfBadge>
          </SfButton>
          <SfButton
            class="sf-button--pure sf-header__action"
            aria-label="Toggle cart sidebar"
            data-testid="minicartIcon"
            @click="toggleCartSidebar"
          >
            <ShoppingBagIcon width="24" height="24" />
            <SfBadge v-if="cartTotalItems" class="sf-badge--number cart-badge">
              {{ cartTotalItems }}
            </SfBadge>
          </SfButton>
        </div>
      </template>

      <template #search>
        <SearchBar @SearchBar:toggle="isSearchOpen = $event" />
      </template>
    </SfHeader>
  </div>
  <ClientOnly>
    <SearchBarResults v-if="isSearchOpen" :visible="isSearchOpen" @SearchBar:hide="isSearchOpen = false" />
    <SfOverlay :visible="isSearchOpen" />
  </ClientOnly>
</template>

<script setup lang="ts">
import ShoppingBagIcon from '~/storefront/assets/icons/shopping-bag.svg'
import BarsIcon from '~/storefront/assets/icons/bars.svg'
import UserIcon from '~/storefront/assets/icons/user.svg'
import CheckIcon from '~/storefront/assets/icons/checkmark-alt.svg'
import WishlistIcon from '~/storefront/assets/icons/wishlist.svg'

const { toggleCartSidebar, toggleWishlistSidebar, toggleMobileMenu } = useUiState()

const { loadTotalQty: loadCartTotalQty, cart } = useCart()
const { loadWishlistIds } = useWishlistStore()
const { wishlistIds } = storeToRefs(useWishlistStore())
const { isLoggedIn } = storeToRefs(useCustomerStore())

const isSearchOpen = ref(false)

const wishlistItemsQty = computed(() => wishlistIds.value?.items_count || 0)

const localePath = useLocalePath()

onMounted(async () => {
  await loadCartTotalQty()
  if (isLoggedIn.value) await loadWishlistIds()
})

useCustomerStore().$subscribe(() => {
  if (isLoggedIn.value) {
    loadWishlistIds()
  }
})

const accountUrl = computed(() =>
  isLoggedIn.value ? localePath(`/customer/account`) : localePath(`/customer/account/login`),
)

const toggleNavMenu = () => {
  toggleMobileMenu()
}

const cartTotalItems = computed(() => cart.value?.total_quantity ?? 0)
</script>

<style lang="scss">
html.theme--storefront {
  @import '~/storefront/assets/css/helpers';

  .app-header {
    position: relative;
    z-index: 2;
    background: var(--white-color);

    @include for-mobile {
      box-shadow: 0px 4px 11px rgba(29, 31, 34, 0.1);
      grid-gap: var(--spacer-xs);
      padding: 0 0 var(--spacer-xs);
    }
  }

  .sf-header {
    --header-aside-margin: 0;
    --header-padding: var(--spacer-xs) var(--spacer-xs);
    --header-icons-margin: 0 var(--spacer-2xs) var(--spacer-xs);
    --header-width: 100%;
    --header-box-shadow: none;

    @include for-desktop {
      --header-padding: 0 var(--spacer-sm);
      --header-icons-margin: 0;
    }

    &__switchers {
      display: flex;
    }

    &__actions {
      flex: 0 0 100%;
      order: 2;

      @include for-desktop {
        flex: 1;
        order: -2;
        justify-content: flex-start;
      }
    }

    &__header {
      padding: 0;
    }

    .sf-header__mobile-menu-toggle {
      position: absolute;
      top: 17px;
      left: 0;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      width: auto;
      height: calc(var(--spacer-lg) + var(--spacer-2xs));
      padding: 0 var(--spacer-xs);
      box-sizing: border-box;

      span {
        position: absolute;
        bottom: 4px;
        left: 0;
        right: 0;
        font-size: var(--spacer-xs);
        font-weight: var(--font-weight--normal);
        line-height: var(--spacer-sm);
        padding-top: 1px;
        padding-bottom: 1px;
        text-align: center;
      }

      @include for-desktop {
        display: none;
      }
    }

    .sf-header__action {
      --header-action-margin: 0 0 0 var(--spacer-xs);
      --button-padding: var(--spacer-2xs);
      --button-size: 32px;

    @include for-mobile {
      --button-height: 32px;
    }

      @include for-desktop {
        --button-padding: 0;
        --header-action-margin: 0 0 0 40px;
      }

      &:hover,
      &:focus {
        --button-color: var(--primary-color);
        --c-link-hover: var(--primary-color);
      }
    }

    &__logo {
      order: 1;

      @include for-mobile {
        --header-logo-margin: 0;
        position: absolute;
        z-index: 1;
        left: 49%;
        top: 17.6px;
        transform: translate(-50%, 0);
      }
    }

    &__aside {
      order: 2;
      flex: 1;
      --header-aside-margin: 16px 0;

      @include for-desktop {
        --header-aside-margin: 0;
      }
    }

  &__icons {
    --header-icons-display: flex;
    justify-content: flex-end;
    align-items: center;
  }

    &__navigation {
      display: none;
    }

    &__search {
      --button-height: 100%;
      --header-search-flex: 0 0 100%;
      --search-bar-width: 100%;
      margin: 0;

      @include for-desktop {
        --header-search-flex: 0 1 286px;
        --search-bar-width: 286px;
      }
    }
  }

  .header-on-top {
    z-index: 2;
  }

  .nav-item {
    --header-navigation-item-margin: 0 var(--spacer-sm);

    .sf-header-navigation-item__item--mobile {
      display: none;
    }
  }

  .logged-in-badge,
  .cart-badge {
    position: absolute;
    bottom: 52%;
    left: 48%;
    --badge-width: 20px;
    --badge-height: 20px;
  }
}
</style>
