<template>
  <div v-if="option" class="product-variant product-variant--engraving">
    <div class="product-variant__heading">
      <SfProperty :name="$t('Personalise your jewellery (capital letters only)')" />
    </div>
    <div class="product-variant__input">
      <Form v-slot="{ meta }" :validation-schema="validationSchema">
        <FormField
          :label="$t('See images for the order of engraving').toString()"
          :disabled="option.disabled"
          name="engraving"
          type="text"
          :pattern="`[A-Za-zÀ-ž]{${maxAmountOfCharacters}}`"
          required
          @update:value="checkValueForLettersOnly"
        />
        <p class="product-variant__max_characters">{{ $t('Maximum characters', { amount: maxAmountOfCharacters }) }}</p>
      </Form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Form } from 'vee-validate'

const props = defineProps({
  option: {
    type: Object,
    required: true,
  },
})

const errorUseOnlyLetters = ref(false)
const { productCustomOptionsConfiguration } = storeToRefs(useProductStore())
const maxAmountOfCharacters = computed(() => props.option?.field_option?.max_characters || 4)

const validationSchema = {
  engraving: `required|maxLength:${maxAmountOfCharacters.value}|lettersOnly`,
}

const productConfiguration = computed({
  get: () => {
    // @todo: set to uid when MYJE-4300 is fixed
    // return productCustomOptionsConfiguration[props.option.uid];
    return productCustomOptionsConfiguration[props.option.option_id]
  },
  set: (value) => {
    errorUseOnlyLetters.value = false
    // @todo: set to uid when MYJE-4300 is fixed
    // productCustomOptionsConfiguration.value[props.option.uid] = value
    productCustomOptionsConfiguration.value[props.option.option_id] = value?.toUpperCase()
  },
})

const checkValueForLettersOnly = (value: string) => {
  if (!/^[a-zA-ZÀ-ž]+$/.test(value)) {
    if (productConfiguration.value?.length >= maxAmountOfCharacters.value) return
    productConfiguration.value = '' // Set empty so that field doesn't validate
    return
  }
  productConfiguration.value = value
}
</script>

<style lang="scss" scoped>
html.theme--storefront {
  .product-variant {
    &__input {
      input {
        padding: 0 var(--spacer-sm);
        border: 1px solid var(--gray-dark-accent-color);
        border-radius: var(--spacer-2xs);
        min-height: 48px;
        width: 100%;
        font-size: var(--font-size--base);
        font-family: var(--font-family--primary);
        color: var(--black-secondary-color);
        text-transform: uppercase;

        &::placeholder {
          color: var(--black-secondary-color);
          text-transform: none;
        }
      }
    }

    &__max_characters {
      margin: var(--spacer-2xs) 0;
      font-size: var(--font-size--xs);
    }
  }
}
</style>
