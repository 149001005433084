<template>
  <div>
    <NuxtLink class="sf-header__logo" :to="`/${locale}/`" :aria-label="$t('Back to homepage')" data-testid="logoHome">
      <Logo />
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import Logo from '~/assets/icons/logo.svg'
const { locale } = useI18n()
</script>

<style lang="scss" scoped>
html.theme--storefront {
  .sf-header__logo {
    svg {
      width: 70px;
      height: auto;
    }

    @media (min-width: 1024px) {
      align-items: center;
      display: inline-flex;
      min-height: 82px;

      svg {
        width: 108px;
      }
    }
  }
}
</style>
