<template>
  <div
    class="footer-middle-column"
    :class="[columnOpen ? 'footer-middle-column--open' : null, `footer-middle-column--${showOn}`]"
  >
    <slot :toggleExpanded="toggle" />
  </div>
</template>

<script setup>
const props = defineProps({
  showOn: 'all' | 'mobile' | 'desktop',
})
const columnOpen = ref(false)
const toggle = () => (columnOpen.value = !columnOpen.value)
</script>

<style lang="scss">
html.theme--storefront {
  .footer-middle-column {
    position: relative;
    grid-column: span 4;

    &:first-child {
      grid-column: 2 / span 4;
    }

    &--desktop {
      @include for-mobile {
        display: none;
      }
    }

    &--mobile {
      @include for-desktop {
        display: none;
      }
    }

    @include for-mobile {
      grid-column: 2 / span 4;
      border-bottom: 1px solid var(--white-color, #fff);
      padding-bottom: var(--spacer-md);
    }
  }
}
</style>
