/* eslint-disable  */

export default {
  'Page {currentPage} of {totalPages}': 'Page {currentPage} sur {totalPages}',
  'A 10% discount on your next purchase?': 'Veux-tu profiter de -10% sur ta prochaine commande ?',
  'A customer with the same email address already exists in an associated website.':
    'Un compte avec la même adresse e-mail existe déjà sur un site lié.',
  'About us': 'A propos de nous',
  Account: 'Compte',
  'Account dashboard': 'Tableau de bord',
  'Account Details': 'Détails du compte',
  'Add new address': 'Ajouter nouvelle adresse',
  'Add review': 'Publier ton avis',
  'Add the address': 'Ajouter l’adresse',
  'Add to cart': 'Ajouter au panier',
  'Quick add to cart': 'Ajouter au panier',
  Adding: 'En cours d’ajout',
  'Added!': 'Ajouté!',
  'Add to compare': 'Ajouter pour comparer',
  'Add to Wishlist': 'Ajouter à la wishlist',
  'Added to cart': 'Ajouté au panier',
  'Additional Information': 'Informations Additionnelles',
  'Addresses details': 'Coordonnées',
  'Adjusted opening hours': 'Horaires d’ouverture modifiés',
  'Allow order notifications': 'Autoriser les notifications',
  and: 'et',
  Apply: 'Appliquer',
  'Attention!': 'Attention !',
  'Available again soon': 'Bientôt de nouveau disponible',
  'Available from date': 'Disponible à partir du {date}',
  'BACK SOON': 'BACK SOON',
  'Back to home': 'Retour vers l’acceuil',
  'Back to homepage': 'Retour à la page d’acceuil',
  'Back to top': 'Retour vers le haut',
  'Be aware of upcoming sales and events. Receive gifts and special offers!':
    'Sois informé(e) des ventes et des événements à venir. Reçois des cadeaux et des offres spéciales',
  'Your feedback means a lot to us: write a review!': 'Votre avis est précieux : écrivez un commentaire !',
  Billing: 'Facturation',
  'Billing address': 'Adresse de facturation',
  'Birthday incorrect': 'Adresse d’anniversaire incorrecte',
  Brand: 'Marque',
  'By: {author}': 'Par : {author}',
  Campaign: 'Campagne',
  Cancel: 'Annuler',
  Canceled: 'Annulé',
  Cart: 'Panier',
  Change: 'Changer',
  'Change password': 'Changer le mot de passe',
  'Change password your account':
    'Si tu veux changer le mot de passe pour accéder à ton compte, saisis les informations suivantes.',
  'Change to grid view': 'Présenter par icônes',
  'Change to list view': 'Présenter par liste',
  'Choose an option': 'Choisis une option',
  'Choose your language': 'Choisis une langue',
  City: 'Ville',
  'Clear all': 'Tout effacer',
  Close: 'Fermer',
  'Close search': 'Fermer la rechercher',
  closed: 'fermé',
  'Cocktail & Party': 'Cocktail & Soirée',
  Color: 'Couleur',
  'Colorful summer dresses are already in store': 'Les robes d’été colorées sont déjà en boutique',
  Colors: 'Couleurs',
  'COMING SOON': 'COMING SOON',
  'Coming soon form description': 'Entre ton adresse e-mail et reçois un e-mail le jour de la sortie !',
  'Coming soon form title': 'Reçois un e-mail lorsque cet article est disponible !',
  'Commercial information':
    'et accepte de recevoir des informations commerciales personnalisées de la part de My Jewellery par e-mail.',
  'Contact Details': 'Coordonnées',
  'Contact details updated': 'Coordonnées mises à jour',
  'Contact us': 'Nous contacter',
  'Continue shopping': 'Continuer mon shopping',
  'Continue to billing': 'Passer à la facturation',
  'Continue to payment': 'Passer au paiement',
  'Continue to shipping': 'Passer à la livraison',
  'Cookies Link': '/confidentialite-cookies',
  'Cookies Policy': 'Cookies',
  Country: 'Pays',
  'Create an account': 'Créer un compte',
  'Current Password': 'Mot de passe actuel',
  'Customer Reviews': 'Avis des clients',
  'Customer service': 'Service client',
  Date: 'Date',
  'Date of birth': 'Date de naissance',
  Day: 'Jour',
  Default: 'Par défaut',
  'Default Billing Address': 'Adresse de facturation par défaut',
  'Default Shipping Address': 'Adresse de livraison par défaut',
  Delete: 'Supprimer',
  'Delivery & Payment': 'Livraison & Paiement',
  'Delivery & Returns': 'Livraison & Retours',
  Departments: 'Départements',
  Description: 'Description',
  'Details and status orders':
    'Vérifie les détails et l’état de tes commandes dans la boutique en ligne. Tu peux aussi annuler ta commande ou demander un retour.',
  Discount: 'Réduction',
  'Discover our new items!': 'Découvre nos nouveaux articles !',
  Done: 'Terminé',
  'Done. You’ve subscribed': 'C’est fait. Tu es abonné(e)',
  Download: 'Télécharger',
  'Download all': 'Tout télécharger',
  'Download our application to your mobile': 'Télécharge notre application sur mobile',
  Dresses: 'Robes',
  'Ear piercing in': 'perçage d’oreilles',
  'Eco Sandals': 'Eco-sandales',
  Edit: 'Modifier',
  'Email address': 'Adresse e-mail',
  'Email you are using to sign in': 'L’adresse e-mail que tu utilises pour te connecter',
  'Empty bag': 'Panier vide',
  'Enjoy your free account': 'Profite de ton compte gratuit !',
  'Enter promo code': 'Entre ton code promo',
  'Enter your email address': 'Entre ton adresse e-mail',
  'Existing customers': 'Clients existants',
  'Extended overview': 'Voir le panier',
  'Fashion to take away': 'La mode à emporter',
  Features: 'Caractéristiques',
  Feedback: 'Ton avis compte ! Dis-nous ce qui pourrait être amélioré.',
  'Feel free to edit': 'Sois libre de mettre tes infos à jour à tout moment.',
  Filters: 'Filtres',
  'Filters & sorting': 'Filter et Trier',
  'Find out more': 'En savoir plus',
  'Find stunning women’s cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.':
    'Trouve de superbes robes cocktail et robes de soirée pour femmes. Démarque-toi avec les robes en dentelle et paillettes de ta marque préférée.',
  'First Name': 'Prénom',
  Fit: 'Coupe',
  'Forgot Password': 'Si tu as oublié ton mot de passe, tu peux le réinitialiser.',
  'Forgot Password Modal Email': 'L’adresse e-mail que tu utilises pour te connecter',
  'Forgot Your Password?': 'Tu as oublié ton mot de passe ?',
  forgotPasswordConfirmation:
    'Merci ! S’il existe un compte lié à l’adresse e-mail {0}, tu recevras un e-mail dans ta boîte de réception pour réinitialiser ton mot de passe.',
  'Forgotten password?': 'Mot de passe oublié ?',
  'Free shipping': 'Livraison gratuite',
  'Get inspired': 'Trouve de l’inspiration',
  'Gift? Add a gift wrap!': 'Cadeau ? Ajoute un emballage',
  'Go back': 'Retour',
  'Go back shopping': 'Retour au shopping',
  'Go back to shop': 'Retour à la boutique',
  'Go to checkout': 'Voir le panier',
  'Go To Product': 'Voir le produit',
  'Gold/Silver': 'Doré/Argenté',
  'Grand Total': 'Montant total',
  Guarantee: 'Guarantie',
  Help: 'Aide',
  'Help & FAQs': 'Aide & FAQ',
  hide: 'cacher',
  'Highest price': 'Prix le plus élevé',
  Home: 'Accueil',
  'House/Apartment number': 'Numéro de rue',
  'I agree to': 'J’accepte',
  'I confirm subscription': 'Je confirme l’inscription',
  'I have read and understand the': 'Je reconnais avoir pris connaissance et déclare accepter les',
  'I want to create an account': 'Je souhaite créer un compte',
  'In stock': 'En stock',
  'Info after order':
    'Tu peux te connecter à ton compte en utilisant l’e-mail et le mot de passe définis précédemment. Sur ton compte, tu peux modifier les données de ton profil, vérifier l’historique des commandes et modifier ton abonnement à la newsletter.',
  'Info & inspiration': 'Infos & idées',
  Instruction1: 'Prends soin de moi',
  Instruction2: 'Tu es là pour les conseils d’entretien ?',
  Instruction3: 'C’est ce qu’on pensait !',
  'Invalid email': 'E-mail incorrect',
  'Invalid Password Message':
    'Le mot de passe doit comporter au moins 8 caractères et doit contenir au moins : 1 lettre majuscule ou minuscule, 1 chiffre, ou un caractère spécial (par exemple, . _ & ? etc).',
  'It was not possible to request a new password, please check the entered email address.':
    'Il n’a pas été possible de demander un nouveau mot de passe, vérifie l’adresse e-mail saisie.',
  Item: 'Article',
  Items: 'Articles',
  'Keep me updated': 'Tenez-moi au courant !',
  Kidswear: 'Vêtements enfant',
  'Last Name': 'Nom de famille',
  'Learn more': 'En savoir plus',
  'Let’s create an account. It has many advantages, such as order tracking, fast checkout and registration of multiple addresses.':
    'Créé-toi un compte pour profiter de nombreux avantages, tels que le suivi des commandes, le fast checkout et l’enregistrement de plusieurs adresses.',
  'Let’s start now – we’ll help you': 'Commençons maintenant - nous allons t’aider',
  'Linen Dresses': 'Robes en lin',
  'Load more reviews': 'Voir plus d’avis',
  'Log in to save your favourites!': 'Connecte-toi maintenant pour sauvegarder des favoris !',
  'Log into your account': 'Connecte-toi à ton compte',
  'Log out': 'Se déconnecter',
  Login: 'Se connecter',
  'login in to your account': 'Se connecter à ton compte',
  'Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.':
    'Ton panier est vide. Commence maintenant ton shopping pour le remplir.',
  'Lowest price': 'Prix le plus bas',
  'Make an order': 'Passer commande',
  'Manage Addresses': 'Gérer mes adresses',
  'Manage addresses':
    'Enregistre toutes tes adresses (lieu de travail, domicile...) afin de sélectionner rapidement celle qui te convient à chaque commande.',
  'Manage billing addresses':
    'Enregistre toutes tes adresses de facturation (lieu de travail, domicile...) afin de sélectionner rapidement celle qui te convient à chaque commande.',
  'Manage shipping addresses':
    'Enregistre toutes tes adresses de livraison (lieu de travail, domicile...) afin de sélectionner rapidement celle qui te convient à chaque commande.',
  'Match it with': 'Produits associés',
  Material: 'Composition',
  'Men fashion': 'Mode homme',
  Menu: 'Menu',
  Month: 'Mois',
  'Month flower': 'Fleur de naissance',
  more: 'plus',
  'My Account': 'Mon Compte',
  'My billing and shipping address are the same': 'Mes adresses de livraison et de facturation sont identiques',
  'My Cart': 'Mon panier',
  'My newsletter': 'Ma newsletter',
  'My orders': 'Mes commandes',
  'My profile': 'Mon profil',
  'My reviews': 'Mes avis',
  'My wishlist': 'Ma wishlist',
  Name: 'Nom',
  'Name A-Z': 'Nom A - Z',
  'New customer': 'Nouveau client',
  'New Password': 'Nouveau mot de passe',
  'New Products': 'Nouveaux produits',
  Newest: 'Plus récents',
  'No account': 'Tu n’as pas encore de compte ?',
  'No products for query "{keyword}"': 'Aucun produit ne correspond à la recherche "{keyword}"',
  'No product suggestions found': 'Aucune suggestion de produit trouvée',
  'No products were found for the search query':
    'Un petit instant, nous récupérons actuellement les meilleurs résultats de recherche pour toi. Appuie sur entrée ou clique sur la loupe pour afficher tous les résultats pour “<strong>{query}</strong>”',
  'Notify me on release': 'Créer une alerte',
  'Open search': 'Ouvrir recherche',
  'Opening hours': 'Horaires d’ouverture',
  option: 'option | options',
  or: 'ou',
  'or fill the details below': 'ou remplis les informations ci-dessous',
  'Order {price} more for free shipping': 'Plus que {price} pour bénéficier de la livraison gratuite ',
  'Order details': 'Détails de ta commande',
  'Order history': 'Historique des commande',
  'Order information': 'Information sur ta commande',
  'Order No.': 'Commande n°',
  'Order summary': 'Aperçu de la commande',
  'Original price': 'Prix',
  'Other products you might like': 'Autres produits que tu pourrais aimer',
  'Out of stock': 'Épuisé',
  Overview: 'Aperçu',
  Password: 'Mot de passe',
  'Password change': 'Mise à jour du mot de passe',
  'Password Changed':
    'Le mot de passe a été modifié avec succès. Tu peux maintenant retourner à la page d’accueil et te connecter.',
  'Passwords don’t match': 'Les mots de passe ne sont pas identiques',
  'Pay for order': 'Paiement de la commande',
  Payment: 'Paiement',
  'Payment & Delivery': 'Paiement & Livraison',
  'Payment & shipping methods': 'Paiement & Modes de livraison',
  'Payment method': 'Méthode de paiement',
  'Payment methods': 'Méthodes de paiement',
  'Personal data': 'Informations personnelles',
  'Personal details': 'Informations personnelles',
  'Personal Details': 'Informations Personnelles',
  'Personal information': 'Informations Personnelles',
  'Phone number': 'Numéro de téléphone',
  'Phone model': 'Modèle de téléphone',
  'Please enter your email address below to receive a password reset link.':
    'Entre ici ton adresse e-mail pour recevoir un lien de réinitialisation de mot de passe.',
  'Please select a country first': 'Sélectionne le pays',
  'Please select the {names} below': 'Sélectionne {names} ci-dessous',
  'Please select a color first': 'Sélectionne une couleur',
  'Please select the option above first': "Sélectionne d'abord l'option ci-dessus",
  'Please type your current password to change your email address.':
    'Entre ici ton mot de passe actuel pour modifier ton adresse e-mail.',
  Price: 'Prix',
  Privacy: 'Confidentialité',
  'Privacy Link': '/fr/confidentialite-cookies',
  'Privacy Policy': 'Police de confidentialité',
  Product: 'Produits',
  product: 'produit | produits',
  'Product recommendations unknown user': 'Découvre nos meilleures ventes',
  'Product recommendations with name': 'Tu vas aussi adorer, {firstName}',
  'Product recommendations with name - male': 'Découvre notre sélection de cadeaux, {firstName}',
  'Product recommendations without name': 'Tu vas aussi adorer',
  'Product recommendations without name - male': 'Découvre notre sélection de cadeaux',
  'Product suggestions': 'Suggestions de produits',
  Products: 'Produits',
  'Products found': 'Produits trouvés',
  'Entre ton code de réduction': 'Code de réduction',
  'Purchase terms': 'Conditions d’achat',
  'Quality in the details': 'Caractéristiques du produit',
  Quantity: 'Quantité',
  Rating: 'Avis',
  'Read all reviews': 'Lire tous les avis',
  'Read and understand': 'Je reconnais avoir pris connaissance et déclare accepter les',
  'Read less': 'Lire moins',
  'Read more': 'Lire la suite',
  'Read reviews': 'Lire les avis',
  'Reading time: {readingTime} minutes': 'Temps de lecture : {readingTime} minutes',
  Recommended: 'Recommandé',
  Register: 'S’inscrire',
  'Register today': 'Inscris-toi dès aujourd’hui',
  'Remove Address': 'Supprimer l’adresse',
  'Remove from Wishlist': 'Supprimer de la wishlist',
  'Repeat Password': 'Répéter le mot de passer',
  'Reset Password': 'Réinitialiser le mot de passer',
  'Return home': 'Retour à la page d’accueil',
  Returns: 'Retours',
  Review: 'Avis',
  'Review my order': 'Noter ma commande',
  Reviews: 'Avis',
  SALE: 'Promo',
  'Same as shipping address': 'Identique à l’adresse de livraison',
  'Save changes': 'Enregistrer les changements',
  'Save for later': 'Sauvegarder pour plus tard',
  'Save Password': 'Enregistrer le mot de passe',
  Search: 'Rechercher',
  'Search for items': 'Rechercher des articles',
  'Search for products, ...': 'Rechercher des produits, ...',
  'Search for products, categories, ...': 'Rechercher des produits, catégories, ...',
  'Search results': 'Résultats de la recherche',
  'Search results for: “{keyword}”': 'Résultats de la recherche pour : “{keyword}”',
  Season: 'Saison',
  'Sections that interest you': 'Les rubriques qui t’intéressent',
  'See all': 'Voir tout',
  'See all products': 'Voir tous les produits',
  'See all results': 'Voir tous les résultats',
  'See our new items link': '/fr/nouveautes.html',
  'See our new items!': 'Voir les nouveaux articles',
  'Select payment method': 'Sélectionne un mode de paiement',
  'Select shipping method': 'Sélectionne une méthode de livraison',
  'Send my feedback': 'Envoyer mon commentaire',
  'Set as default billing': 'Enregistrer comme adresse de facturation par défaut',
  'Set as default shipping': 'Enregistrer comme adresse de livraison par défaut',
  'Set up newsletter':
    'Inscris-toi à la newsletter et nous t’enverrons chaque semaine des informations sur les nouveaux produits et les tendances des rubriques que tu as sélectionnées.',
  'Share your experience and help others make the right decision':
    'Partage ton expérience pour aider les autres clients à faire le bon choix',
  'Share your look': 'Partage ta tenue',
  'Share Your Look': 'Partage Ta Tenue',
  Shipping: 'Livraison',
  'Shipping address': 'Adresse de livraison',
  'Shipping details': 'Information de livraison',
  'Shipping method': 'Méthode de livraison',
  'Shop now': 'Achète maintenant',
  'Show all': 'Voir tout',
  'Show all results': 'Voir tous les résultats',
  'Show less shop-in-shops': 'Voir moins de points de vente',
  'show more': 'voir plus',
  'Show more products': 'Voir plus de produits',
  'Show more shop-in-shops': 'Voir plus de points de vente',
  'Show on page': 'Voir sur la page',
  'Show previous products': 'Voir les produits précédents',
  'Sign in': 'S’inscrire',
  'sign me up': 'inscrivez-moi',
  'Sign Up for Newsletter': 'S’inscrire à la Newsletter',
  'Sign-in information': 'Informations d’inscription',
  Size: 'Taille',
  'Size chart': 'Tableau des tailles',
  'Size guide': 'Guide des tailles',
  'Sku color': 'Couleur',
  'Sold out form description':
    'Malheureusement, cet article est actuellement en rupture de stock, mais il sera bientôt de retour ! Inscris-toi et reçois un e-mail lorsque ta couleur préférée ou ta taille est de nouveau en stock !',
  'Sold out form title': 'Reçois un e-mail lorsque cet article est en stock !',
  'Almost sold out shop now': 'Presque épuisé, achète vite tes favoris!',
  'Sorry, the page you are looking for does not seem to exist anymore.':
    'Désolé, la page que tu recherches n’existe plus.',
  'Sort by': 'Trier par',
  'Sort: Default': 'Défaut',
  'Sort: Name A-Z': 'Nom A-Z',
  'Sort: Name Z-A': 'Nom Z-A',
  'Sort: Price from high to low': 'Prix décroissants',
  'Sort: Price from low to high': 'Prix croissants',
  'Start shopping': 'Commencer le shopping',
  'State/Province': 'Pays',
  'Street Name': 'Nom de rue',
  'Style profile': 'Style',
  Subscribe: 'S’inscrire',
  'Subscribe to newsletter': 'S’inscrire à la newsletter',
  'Subscribe to Newsletters': 'S’inscrire aux Newsletters',
  'Subscribe your friend!': 'Parraine une amie',
  subscribeToNewsletterModalContent:
    'Après t’être inscrit(e) à la newsletter, tu recevras des offres spéciales et des messages de My Jewellery par e-mail. Nous ne vendrons ni ne partagerons ton email à un tiers à aucun moment. Consulte nos {0} pour en savoir plus.',
  Subtotal: 'Sous-total',
  'Subtotal price': 'Sous-total',
  'Successful placed order':
    'Tu as passé ta commande avec succès. Tu peux vérifier l’état de ta commande en utilisant sous "état de livraison". Tu recevras un e-mail de confirmation de commande avec les détails de ta commande et un lien pour suivre sa progression.',
  Summary: 'Aperçu',
  'SUMMER COLLECTION {year}': 'COLLECTION ÉTÉ {year}',
  'Summer Sandals': 'Sandales d’été',
  'T-Shirts': 'T-Shirts',
  Tax: 'TVA',
  'Terms and conditions': 'Conditions générales de vente',
  'Thank You Inbox':
    'Si le message n’arrive pas dans ta boîte de réception, essaie une autre adresse e-mail que tu as peut-être utilisée pour t’inscrire.',
  'The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.':
    'La connexion au compte était incorrecte ou ton compte est temporairement désactivé. Merci de patienter et de réessayer plus tard.',
  'The email field must be a valid email': 'Le champ email doit être un e-mail valide',
  'The field should have at least 2 characters': 'Le champ doit comporter au moins 2 caractères',
  'The field should have at least 4 characters': 'Le champ doit comporter au moins 4 caractères',
  'The field should have at least 8 characters': 'Le champ doit comporter au moins 8 caractères',
  'The office life': 'La vie au bureau',
  'The Office Life': 'La Vie au Bureau',
  'The user account data was successfully updated!': 'Les données de ton compte ont été mises à jour avec succès !',
  'There are no shipping methods available for this country. We are sorry, please try with different country.':
    'Il n’y a pas de méthode de livraison disponible pour ce pays. Nous sommes désolés, essaie avec un autre pays.',
  'There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.':
    'Une erreur s’est produite lors de la recherche de modes de livraison. Nous sommes désolés, essaie avec d’autres informations de livraison.',
  'There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.':
    'Une erreur s’est produite lors de la sélection de ce mode de livraison. Nous sommes désolés, essaie avec d’autres informations de livraison.',
  'These are our bestsellers': 'Voici nos meilleures ventes',
  'These are your last viewed items': 'Voici les articles que tu as récemment vus',
  'This field is required': 'Ce champ est obligatoire',
  'This is a required field.': 'Ce champ est obligatoire',
  Total: 'Total',
  'Total items': 'Nombre total d’articles',
  'Total price': 'Prix total',
  'Update password': 'Mot de passé réinitialisé',
  'Update personal data': 'Mise à jour des informations personnelles',
  'Update the address': 'Mise à jour de l’adresse',
  'Use your personal data':
    'Chez My Jewellery, nous attachons une grande importance aux questions de confidentialité et nous nous engageons à protéger les données personnelles de nos clients. Apprends-en plus sur la façon dont nous prenons soin et utilisons tes données personnelles sur notre site.',
  'User Account': 'Compte d’utilisation',
  View: 'Voir',
  'View all items on your wishlist': 'Voir tous les articles de ta wishlist',
  'View details': 'Voir les détails',
  'View our newest items & items that have been saved especially for you below! Or shop from one of our categories.':
    'Découvre nos nouveaux articles et les articles qui ont été sauvegardés spécialement pour toi ci-dessous ! Ou fais tes achats dans l’une de nos catégories.',
  'Vue Storefront Next': 'Vue Storefront Next',
  'We believe you might like these items': 'Tu devrais aussi aimer ça',
  'We haven’t found any results for given phrase': 'Nous n’avons trouvé aucun résultat pour le terme donné.',
  'We think you’ll like these items': 'Tu vas aussi adorer ceci',
  'Who we are': 'Qui somme-nous ?',
  Wishlist: 'Wishlist',
  'Women fashion': 'Mode femme',
  'Write a review': 'Ajouter un avis',
  Year: 'An',
  'You added {product} to your shopping cart.': 'Tu as ajouté {product} à ton panier.',
  'You already have an account. We like it! Use your email address to log in.':
    'Tu as déjà un compte. C’est génial ! Utilise ton adresse e-mail pour te connecter.',
  'You are not authorized, please log in': 'Accès non-autorisé, tu dois te connecter.',
  'You can try one of the popular search queries': 'Essaie l’une des recherche les plus populaires.',
  'You can unsubscribe at any time': 'Tu peux te désinscrire à tout moment',
  'You currently have no orders': 'Tu n’as pas encore passé commande.',
  'You have no items in your shopping cart.': 'Il n’y a pas d’articles dans ton panier.',
  'You have no items in your wishlist.': 'Il n’y a pas d’articles dans ta wishlist.',
  'You have submitted no reviews': 'Tu n’as pas encore posté d’avis.',
  'You haven’t searched for items yet': 'Tu n’as pas encore rechercher d’articles.',
  'You must login or register to add items to your wishlist.':
    'Tu dois te connecter ou t’inscrire pour ajouter des articles à ta wishlist.',
  'Your bag is empty': 'Ton panier est vide.',
  'Your cart is empty': 'Ton panier est vide.',
  'Your current email address is': 'Ton adresse e-mail actuelle est',
  'Your e-mail': 'Ton adresse e-mail',
  'Your rating': 'Ton avis',
  'Your review was submitted!': 'Ton avis a été soumis !',
  'Zip-code': 'Code postal',
  'Zodiac sign': 'Signe astro',
  // Translated after MYJE-2429
  Title: 'Titre',
  'Launch:': 'Lancement : {dateOnline}',
  'Days before launch': 'Disponible dans {days} jours',
  'Last day before launch': 'Le jour-j, c’est demain',
  'Adding product to wishlist...': 'Ajout de l’article à la wishlist...',
  'Adding product to cart...': "Ajout de l'article au panier...",
  'Login to add to wishlist': 'Connecte-toi pour ajouter à la wishlist',
  'You have to be logged in to add items to your wishlist.':
    'Tu dois être connecté pour ajouter des articles à ta wishlist.',
  'Added to your cart!': 'Ajouté à ton panier !',
  'Maximum characters': 'Maximum {amount} caractères',
  'See images for the order of engraving': 'Consulte les images pour voir l’ordre des gravures',
  'Personalise your jewellery (capital letters only)': 'Personnalise ton bijou (majuscules uniquement)',
  'Engraving:': 'Gravure :',
  'No results for selected filters': 'Aucun résultat pour les filtres sélectionnés',
  'No results for this category': 'Aucun résultat pour cette catégorie',
  'Please select an available {names}': 'Sélectionne un {names} disponible',
  'Choose an option notification': 'Choisis une option',
  customisation: 'personnalisation',
  'Giftcard price': 'Prix carte cadeau',
  'Birth year': 'Année de naissance',
  'Birth month': 'Mois de naissance',
  Initial: 'Initiale',
  'Please only enter letters': 'Entre uniquement des lettres',
  'You can enter gift card codes when you proceed to checkout': `Le code de ta carte-cadeau peut être ajouté à l'étape du paiement`,
  'Promo code': 'Code promo',
  "Unfortunately, this item is sold out and can't be added to your shopping cart.":
    'Malheureusement, cet article est épuisé et ne peut pas être ajouté à ton panier.',
  variantMatrixSize: 'ta taille',
  variantMatrixColor: 'une couleur',
  variantMatrixBirthYear: 'une année de naissance',
  variantMatrixBirthMonth: 'un mois de naissance',
  variantMatrixZodiacSign: 'un signe astrologique',
  variantMatrixInitial: 'une initiale',
  variantMatrixGiftcardPrice: 'un montant',
  variantMatrixEngraving: 'ta gravure personnelle',
  variantMatrixPhoneModel: 'un modèle de téléphone',
  "That's one shiny combination!": 'C’est une combinaison brillante !',
  'No size chart available': 'Aucun tableau des tailles disponible',
  'Please select {names}': 'Sélectionne {names}',
  loginToAddToWishlist: 'Tu dois te connecter ou t’inscrire pour ajouter des articles à ta wishlist, va à {0}',
  'login page': 'la page de connexion',
  Cards: 'Cartes',
  'Gift bags': 'Pochettes cadeaux',
  'Gift boxes': 'Boîtes cadeaux',
  'You added {product} to your wishlist.': 'Tu as ajouté {product} à ta wishlist.',
  NOT_SALABLE: "Malheureusement, cet article n'est plus en stock",
  'Your opinion counts': 'Avis',
  Trends: 'Tendances',
  Engravable: 'Gravable',
  'Color groups': 'Groupes de couleurs',
  'Ends in': 'Se termine dans',
  'Invalid phone number': 'Numéro de téléphone invalide',
  'Invalid prefix phone number': 'Veuillez commencer votre numéro de téléphone par +32 ou +33',
  'The field should have at least {amount} characters': 'Le champ doit comporter au moins {amount} caractères',
  'Something went wrong': 'Quelque chose s’est mal passé',
  PRODUCT_NOT_FOUND:
    "Nous n’avons pas pu trouver le produit que tu cherches. Il n'est peut-être plus disponible ou inexistant.",
  CART_NOT_FOUND:
    'Oups ! Nous n’avons pas trouvé ton panier. Il a peut-être été converti en commande ou n’existe plus.',
  CART_NOT_ACTIVE: 'Ton panier est inactif. Crée un nouveau panier pour continuer tes achats.',
  CART_AUTHORIZATION_FAILED: 'Nous n’avons pas pu autoriser ton panier. Reconnecte-toi pour continuer.',
  'In cart': 'Dans le panier',
  'Productgroup old': 'Catégorie',
  'Necklace length': 'Longueur de la chaîne',
}
