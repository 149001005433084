<template>
  <div class="sf-property" v-bind="$attrs.attrs">
    <slot name="name">
      <span class="sf-property__name">
        {{ name }}
      </span>
    </slot>
    <slot name="value">
      <span class="sf-property__value">
        {{ value }}
      </span>
    </slot>
  </div>
</template>
<script>
export default {
  name: 'SfProperty',
  props: {
    name: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
  },
}
</script>
<style lang="scss">
html.theme--storefront {
  @import './styles/atoms/SfProperty.scss';
}
</style>
