<template>
  <div v-if="component" :class="isPreview ? 'has-edit-button' : ''">
    <br-manage-content-button :content="content" />
    <div class="blog-post-header" :class="{ 'type-video': mediaType === 'video' }">
      <BynderAsset class="blog-post-header__asset" :data="model.image" :data-mobile="model.mobileImage" />

      <div class="container blog-post-header__container">
        <figure v-if="mediaType === 'video' && model.video" class="blog-post-header__video">
          <BloomreachVideo :page="page" :component="component" :videoContent="video" />
        </figure>

        <div class="blog-post-header__content">
          <h1 class="blog-post-header__title" v-html="postTitle" />
          <HTMLContent tag="div" :content="postDescription" />

          <div v-if="hasStoreAddress" class="blog-post-header__meta">
            {{ storeAddress }}<span v-if="storeCountry">, {{ storeCountry }}</span>
          </div>
          <div v-else-if="(postDate || authorName || readingTime) && !hideDetails" class="blog-post-header__meta">
            <span v-if="postDate">{{ postDate }}</span
            ><span v-if="authorName"> | {{ authorName }}</span
            ><span v-if="readingTime"><br />{{ readingTime }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import HTMLContent from '~/storefront/components/HTMLContent.vue'

const props = defineProps({
  component: null,
  page: null,
})
const { locale, t } = useI18n()
const content = computed(() => {
  const document = props.component && props.component.getModels()?.document
  return props.page.getContent(document)
})

const model = computed(() => {
  const data = content.value?.getData()

  return (
    (data && {
      ...data,
      image: data.image,
      mobileImage: data.mobileBannerImage,
    }) ||
    {}
  )
})

const document = props.page?.getDocument()
const mediaType = computed(() => model.value?.mediaType?.selectionValues[0]?.key)
const video = computed(() => props.page.getContent(model.value?.video))
const isPreview = props.page?.isPreview()
const postTitle = computed(() => model.value?.title || document.getData().title)
const postDescription = computed(() => model.value?.text?.value || '')
const postDate = computed(() => {
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' }
  const date = document.getData().date && new Date(document.getData().date)
  return date?.toLocaleDateString(locale.value || 'en', options)
})
const authorName = computed(() => (model.value?.author && t('By: {author}', { author: model.value?.author })) || false)
const readingTime = computed(
  () =>
    (model.value?.readingTime && t('Reading time: {readingTime} minutes', { readingTime: model.value?.readingTime })) ||
    false,
)
const hideDetails = computed(() => model.value?.hideDetails)
const pageMeta = computed(() => props.page?.getDocument()?.getData())

const hasStoreAddress = computed(() => {
  return pageMeta.value?.streetName
})
const storeAddress = computed(() => {
  return `${[pageMeta.value?.streetName, pageMeta.value?.houseNumber, pageMeta.value?.addition]
    .filter((s) => s.length)
    .join(' ')
    .trim()}, ${pageMeta.value?.postalCode} ${pageMeta.value?.city}`
})

const storeCountry = computed(() => {
  return ['nl', 'netherlands', 'nederland'].includes(pageMeta.value?.country?.toLowerCase())
    ? null
    : pageMeta.value?.country
})

onMounted(() => {
  video.value?.load?.()
})
</script>

<style lang="scss">
html.theme--storefront {
  @include for-mobile {
    .hst-container-item:has(.blog-post-header) {
      grid-column: 1 / -1;
    }
  }
}
</style>

<style lang="scss" scoped>
html.theme--storefront {
  .blog-post-header {
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    text-align: center;
    padding: var(--spacer-md);
    margin-bottom: var(--mj-component-margin-bottom);
    background-color: var(--sand-background-color);

    @include for-desktop {
      flex-direction: row;
      gap: var(--spacer-md);
      padding: var(--spacer-lg) var(--spacer-xs);
      min-height: 594px;
      justify-content: flex-end;

      .blog-post-header__asset,
      .blog-post-header__video,
      .blog-post-header__content {
        flex: 0 0 calc(50% - calc(var(--spacer-md) / 2));
      }

      .blog-post-header__content {
        margin-left: auto;
      }
    }

    @include for-desktop {
      &__container {
        display: flex;
        flex-flow: row wrap;
        padding: 0 var(--spacer-15);
        justify-content: space-between;
        align-items: center;
      }
    }

    &__asset {
      position: relative;
      width: 100%;
      margin: 0 0 var(--spacer-sm);
      pointer-events: none;

      @include for-desktop {
        margin: 0;
      }

      &:before {
        content: '';
        display: block;
        padding-top: 71.6%;

        @media screen and (min-width: 768px) {
          padding-top: 56.25%;
        }
      }

      &.bynder-asset--video {
        @include for-mobile {
          aspect-ratio: 16 / 9;
          overflow: hidden;

          + .blog-post-header__container > .blog-post-header__video {
            display: none;
          }

          &:before {
            @media screen and (min-width: 768px) {
              padding-top: 56.25%;
            }
          }
        }
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @include for-desktop {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        overflow: hidden;

        &:before {
          display: none;
        }
      }
    }

    &__video {
      margin: 0;
      width: 100%;

      .video-player {
        margin-bottom: 0;
      }
    }

    &__content {
      position: relative;
      flex: 0 0 100%;
      padding: 0;
      background-color: var(--sand-background-color);
      word-break: break-word;
      font-size: var(--font-size--base);
      line-height: var(--global-line-height);

      a:not(.sf-button) {
        text-decoration: underline;

        @include for-desktop {
          &:hover {
            color: var(--primary-color);
          }
        }
      }

      @include for-desktop {
        flex: 0 0 50%;
        font-size: var(--font-size--lg);
        line-height: 1.333;
        padding: calc(var(--spacer-lg) * 2);
        min-height: 498px;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
      }
    }

    &__title {
      margin-bottom: var(--spacer-xs);
    }

    &__meta {
      font-size: var(--font-size-10);
      line-height: 1.8;
      margin-top: var(--spacer-md);

      @include for-desktop {
        font-size: var(--font-size-13);
        line-height: var(--spacer-18);
      }
    }

    + .breadcrumbs {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
</style>
