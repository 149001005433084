<template>
  <div class="layout layout--utils">
    <nav class="utils-nav">
      <div class="utils-nav__content container">
        <NuxtLink class="logo" :to="`/${locale}/`">
          <Logo />
        </NuxtLink>
        <NuxtLink :to="`/${locale}/utils/exponea`">Exponea A/B tests</NuxtLink><br />
        <NuxtLink :to="`/${locale}/utils/cache`">Cache</NuxtLink>
        <SfButton v-if="access" class="logout" @click="logout">Logout</SfButton>
      </div>
    </nav>

    <div class="utils-content">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import Logo from '~/assets/icons/logo.svg'
const { locale } = useI18n()

useHead({
  meta: [{ name: 'robots', content: 'noindex, nofollow' }],
  htmlAttrs: {
    class: 'theme--storefront',
  },
})

const access = ref(false)

onMounted(() => {
  access.value = !!sessionStorage.getItem('utilsToken')
})

const logout = () => {
  sessionStorage.removeItem('utilsToken')
  access.value = false
  navigateTo(`/${locale.value}/utils`)
}
</script>

<style lang="scss" scoped>
html.theme--storefront {
  .container {
    padding: 0 16px;
  }

  .utils-nav {
    height: 64px;
    background: #eee;

    &__content {
      display: flex;
      align-items: flex-end;
      gap: 4px;
      height: 100%;

      a {
        display: flex;
        align-items: center;
        height: 90%;
        padding: 0 16px;

        &:nth-child(2) {
          margin-left: auto;
        }
      }

      .logo {
        padding: 0;
        height: 100%;
      }

      .router-link-active {
        font-weight: bold;
        background: #fff;
        border-radius: 20px 20px 0 0;
        box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.1);
      }
    }
  }

  .utils-content {
    padding: 32px 16px;
  }

  .logout {
    height: 46px;
    margin: 0 0 8px 12px;
    background: #fff;
    color: #000;
  }
}
</style>
