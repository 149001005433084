<template>
  <ul v-if="menu" class="mobile-nav-menu" :class="{ 'has-edit-button': page.isPreview() }">
    <br-manage-menu-button :menu="menu" />
    <header class="mobile-nav-menu__header">
      <span class="mobile-nav-menu__header-title">Menu</span>
      <StoreSwitcher class="store-switcher" />
    </header>
    <MobileNavMenuItem
      v-for="(item, index) in menu.getItems()"
      :key="index"
      :index="index + 1"
      :item="item"
      :level="1"
    />
    <MobileNavCtaItems :loading="ctaUspsLoading" :items="ctaItems" />
    <MobileNavUspItems :loading="ctaUspsLoading" :items="uspItems" />
  </ul>
</template>

<script lang="ts" setup>
import type { Component as BrComponent, Page } from '@bloomreach/spa-sdk'
import { useMenu } from '~/composables'

const {
  $i18n: { locale },
} = useNuxtApp()
const logger = useAppLogger('MobileNavMenu')

const props = defineProps({
  component: {
    type: Object as () => BrComponent,
    required: true,
  },
  page: {
    type: Object as () => Page,
    required: true,
  },
})

const { menu } = useMenu(props)
const ctaItems = ref([])
const uspItems = ref([])
const ctaUspsLoading = ref(false)
const ctaUspsLoaded = ref(false)

const fetchDocumentData = async (ref: string) => {
  const data = await $fetch('/api/bloomreach/document', {
    query: {
      path: ref,
      locale: locale.value,
    },
  })

  const headerLink =
    data?.content?.[data?.content[data?.document?.$ref?.substring(9)]?.data?.headerLink?.$ref?.substring(9)]?.links
      ?.site?.href || null

  const documentData = data?.content[data?.document?.$ref?.substring(9)]?.data
  return documentData ? { ...documentData, headerLink } : {}
}

const loadHeaderItems = async () => {
  ctaUspsLoading.value = true
  try {
    const items = await fetchDocumentData('/header/mobileheaderctausps')
    ctaItems.value = await Promise.all(
      items?.header1?.map((item) => item.$ref.substring(9)).map((ref) => fetchDocumentData(ref)),
    )
    uspItems.value = await Promise.all(
      items?.header2?.map((item) => item.$ref.substring(9)).map((ref) => fetchDocumentData(ref)),
    )
  } catch (error) {
    logger.error('Error', error)
  } finally {
    ctaUspsLoading.value = false
    ctaUspsLoaded.value = true
  }
}

onMounted(() => {
  loadHeaderItems()
})
</script>

<style lang="scss">
html.theme--storefront {
  .mobile-nav-menu {
    list-style-type: none;

    > li {
      line-height: var(--xs-line-height);
      font-weight: var(--font-weight--semibold);
      padding: 0;
      margin: 0 0 var(--spacer-12);

      > .nav-link,
      > a {
        background-color: var(--gray-background-color);
        border-radius: var(--spacer-2xs);
      }
    }

    &__header {
      height: var(--spacer-xl);
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      margin-bottom: var(--spacer-sm);

      &-title {
        font-family: var(--font-family--secondary);
        font-size: var(--h2-font-size);
        line-height: var(--global-line-height);
      }
    }

    .store-switcher {
      --button-height: var(--spacer-lg);
    }
  }
}
</style>
