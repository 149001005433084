/**
 * Store for managing A/B tests.
 *
 * This store is responsible for setting and retrieving A/B tests from Exponea,
 * keeping track of the tests that are running, and storing them in the state.
 *
 * @returns {Object} The store's state and methods.
 * @returns {Ref<Map<number, 'A' | 'B'>>} tests - Reactive state containing the A/B test results.
 * @returns {Function} getAbTest - Method to retrieve an A/B test and store its result.
 * @returns {Function} setAbTests - Method to set all A/B tests.
 */

interface AbTest {
  enabled: boolean
  locale: 'nl-nl' | 'en' | 'de' | 'fr'
  id: string
  name: string
  number: number
  variants: {
    [key: string]: {
      name: string
      percentage: number
    }
  }
}

export const tests: AbTest[] = [
  {
    enabled: true,
    locale: 'nl-nl',
    id: '672ce714aef127aea4dc5325',
    name: 'CRO - A/B - POP - Positioning review score - NL #24040',
    number: 24040,
    variants: {
      v0: { name: 'ab-24040-default-review-score-label', percentage: 50 },
      v1: { name: 'ab-24040-redesigned-review-score-label', percentage: 50 },
    },
  },
  {
    enabled: true,
    locale: 'nl-nl',
    id: '6728991a2ea927a5733b9341',
    name: 'A/B - PDP - Position Photoslurp above stijl dit met - NL #24036',
    number: 24036,
    variants: {
      v0: { name: 'ab-24036-default-photoslurp-position', percentage: 50 },
      v1: { name: 'ab-24036-change-photoslurp-position', percentage: 50 },
    },
  },
  {
    enabled: false,
    locale: 'nl-nl',
    id: '674dc967ad8b5ba9144651e8',
    name: 'A/B - POP - Position category images - NL #24039',
    number: 24039,
    variants: {
      v0: { name: 'ab-24039-image-position-5', percentage: 33 },
      v1: { name: 'ab-24039-image-position-13', percentage: 33 },
      v2: { name: 'ab-24039-image-position-16', percentage: 33 },
    },
  },
]

/**
 * List of all A/B tests
 * All tests are stored in this array.
 */
const abTests: Ref<AbTest[]> = ref(tests)

declare global {
  interface Window {
    hj: any
    exponea: any
  }
}

export const useAbTestStore = defineStore('abTest', () => {
  const { waitUntilExponeaCookieIsSet } = useExponeaStore()
  const logger = useAppLogger('useAbTestStore')
  const {
    $i18n: { locale },
  } = useNuxtApp()

  /*
   * Tests are stored in a map where the number is the test number and the version will be v0, v1, etc.
   * So for example: tests.get(24025) will return 'v0' or 'v1'
   */
  const state = reactive({
    tests: new Map<number, string>(),
  })

  /**
   * Sets all A/B tests upon initialization of the app
   * They'll get loaded as quick as possible and are stored in the state
   */
  const setAbTests = async () => {
    await waitUntilExponeaCookieIsSet()

    const route = useRoute()

    // For QA purposes - Store required version in localStorage
    const abTestStorage = JSON.parse(localStorage.getItem('abtests') || '{}')
    if (route.query.abtest && route.query.abversion) {
      const abTest = route.query.abtest as string
      const abVersion = route.query.abversion as string
      abTestStorage[abTest] = abVersion
      localStorage.setItem('abtests', JSON.stringify(abTestStorage))
    }

    // Enable tests that are set in localStorage
    abTests.value = abTests.value.map((test) => {
      if (abTestStorage[test.number]) return { ...test, enabled: true }
      return test
    })

    abTests.value
      .filter((test) => {
        return test.enabled && test.locale === locale.value
      })
      .forEach((test) => {
        getAbTest(test)
      })
  }

  /**
   * Retrieves and processes an A/B test result using the Exponea service.
   *
   * @param {Object} params - Parameters for the A/B test.
   * @param {string} params.id - Unique identifier for the test.
   * @param {number} params.number - Test number. We use this number to retrieve the test from the state.
   * @param {string} params.name - Name of the test.
   * @param {Object} params.variants - Variants of the test.
   * @param {Object} params.variants.v0 - First variant of the test.
   * @param {string} params.variants.v0.name - Name of the first variant.
   * @param {number} params.variants.v0.percentage - Percentage allocation for the first variant.
   * @param {Object} params.variants.v1 - Second variant of the test.
   * @param {string} params.variants.v1.name - Name of the second variant.
   * @param {number} params.variants.v1.percentage - Percentage allocation for the second variant.
   *
   * @returns {void} - But it will store result 'v0', 'v1', etc or 'ControlGroup' in the state.
   */
  const getAbTest = ({ number, name, variants }: AbTest): void => {
    window.exponea.getAbTest(
      name,
      {
        ...Object.fromEntries(Object.entries(variants).map(([key, value]) => [value.name, value.percentage])),
        ControlGroup: 0,
      },
      (variant: string) => {
        if (!variant) {
          logger.error('No variant found')
          return
        }

        // For QA: if test is set in localStorage, use that version
        const abTestStorage = JSON.parse(localStorage.getItem('abtests') || '{}')
        if (abTestStorage[number]) {
          state.tests.set(number, abTestStorage[number])
          return
        }

        // Otherwise return actual result
        state.tests.set(number, Object.entries(variants)?.find(([key, value]) => value.name === variant)?.[0] ?? 'v0')
      },
    )
  }

  /**
   * Sends an A/B test event to Exponea.
   *
   * @param {number} id - The unique identifier of the A/B test.
   * @param {string} action - The action performed (e.g., 'show', 'click').
   */
  const sendAbTestEvent = (id: number, action: string): void => {
    const test = abTests.value.find((test) => test.number === id)

    if (test?.enabled === false || test?.locale !== locale.value) {
      return
    }

    const abVersion = state.tests.get(id)
    if (!abVersion) {
      logger.error(`Test with id ${id} not found in state`)
      return
    }

    if (!test) {
      logger.error(`Version ${abVersion} not found tests`)
      return
    }

    const variantName = test.variants[abVersion].name

    const exponeaData = {
      action,
      banner_id: test.id,
      banner_name: test.name,
      banner_type: 'banner',
      variant_id: variantName.replaceAll(' ', '_').toLowerCase(),
      variant_name: variantName,
      interaction: false,
      location: window.location.href,
      path: window.location.pathname,
      variant_click: '',
    }

    if (action === 'show') {
      window.hj('tagRecording', [variantName])
      window.hj('trigger', variantName)
    }

    window.exponea.track('banner', exponeaData)
  }

  return {
    ...toRefs(state),
    setAbTests,
    sendAbTestEvent,
  }
})

export default useAbTestStore
