<template>
  <div v-if="items?.length" class="form exponea-form" :class="page.isPreview() ? 'has-edit-button' : ''">
    <br-manage-content-button :content="computedDocument" />
    <SfHeading v-if="data.title" class="exponea-form__heading" :title="data.title" :level="2" />
    <div v-html="data.content?.value" />

    <Form v-slot="{ meta }" :validation-schema="generateValidationSchema()" @submit="submit">
      <div v-for="(item, index) in items" :key="index">
        <div
          v-if="item.type.selectionValues[0].key === 'textarea'"
          class="exponea-form__element"
          :name="item.fieldName"
        >
          <div class="exponea-form__birthday-label" v-html="formatLabel(item)"></div>
          <div style="clear: both"></div>
          <FormTextArea :name="item.fieldName" :cols="60" :rows="10" wrap="soft" :required="item.required" />
        </div>

        <div v-else-if="item.type.selectionValues[0].key === 'birthday'">
          <div class="exponea-form__birthday-label" v-html="formatLabel(item)"></div>
          <div style="clear: both"></div>

          <FormBirthday :name="item.fieldName + '#birthday'" />
        </div>
        <div
          v-else-if="item.type.selectionValues[0].key === 'checkbox'"
          class="exponea-form__element"
          :name="item.fieldName"
        >
          <FormCheckbox
            class="exponea-form__checkbox"
            :name="item.fieldName"
            :value="item.fieldName"
            :label="getLabelWithoutPTags(item)"
          >
            <template #label
              ><div
                class="sf-checkbox__label exponea-form__checkbox-label"
                v-html="
                  item.richTextlabel
                    ? parseBloomreachInternalLinks(item.richTextlabel.value) + (item.required ? '<p>&nbsp;*</p>' : '')
                    : item.required
                      ? '*'
                      : ''
                "
              ></div
            ></template>
          </FormCheckbox>
        </div>

        <div
          v-else-if="item.type.selectionValues[0].key === 'dropdown'"
          class="exponea-form__element"
          :name="item.fieldName"
        >
          <div class="exponea-form__dropdown-label" v-html="item.label"></div>
          <div style="clear: both"></div>
          <FormDropdown :name="item.fieldName" :item :required="item.required" />
        </div>

        <div v-else class="exponea-form__element" :name="item.fieldName">
          <FormField
            :name="item.fieldName"
            :label="getLabelWithoutPTags(item)"
            :required="item.required"
            :type="item.type.selectionValues[0].key"
          />
        </div>
      </div>
      <SfButton v-if="!success" class="exponea-form__button" :disabled="!meta.valid">
        {{ data.submitButton }}
      </SfButton>

      <div v-html="data.privacyPolicy?.value" />
      <div v-if="errors.length" class="errors errors bg-danger--variant text-danger">
        <p v-for="error in errors" :key="error">{{ $t(error) }}</p>
      </div>
      <div v-if="success" class="exponea-form__success">{{ data.submitText }}</div>
    </Form>
  </div>
</template>

<script lang="ts" setup>
import { Form } from 'vee-validate'
import { parseBloomreachInternalLinks } from '~/utils/urlHelpers'

const props = defineProps({
  component: null,
  document: null,
  page: null,
})

const getItemContent = (ref) => {
  return props.page.getContent(ref)?.getData() || {}
}

const errors = ref([])
const success = ref(false)

const computedDocument = computed(() => {
  if (props.document) return props.document

  const document = props.component && props.component.getModels()?.document
  return props.page.getContent(document)
})

const data = ref(computedDocument.value?.getData() || {})

const items = computed(() => data.value.formItems?.map((item) => getItemContent(item)))

const generateValidationSchema = () => {
  let validations = {}
  items.value?.forEach((item) => {
    if (!item.required) return
    if (item.type.selectionValues[0].key === 'email') {
      return (validations[item.fieldName] = 'required|email')
    }
    if (item.type.selectionValues[0].key === 'phone') {
      return (validations[item.fieldName] = 'required|phone')
    }
    if (item.type.selectionValues[0].key === 'birthday') {
      validations[item.fieldName + '#birthday.day'] = 'required'
      validations[item.fieldName + '#birthday.month'] = 'required'
      validations[item.fieldName + '#birthday.year'] = 'required'
      return
    }
    validations[item.fieldName] = 'required'
  })
  return validations
}

const getLabelWithoutPTags = (item) => item?.richTextlabel?.value?.replace('<p>', '').replace('</p>', '').trim()

const formatLabel = (item) =>
  item.richTextlabel?.value ? parseBloomreachInternalLinks(item.richTextlabel.value) : item.label

const pushEventToGa = () => {
  dataLayer.push({
    event: 'GAEvent',
    eventCategory: 'Marketing Automation',
    eventAction: 'Contact Signup',
    eventLabel: data.value.source,
    eventValue: undefined,
  })
}

type Consent = {
  action: 'accept' | 'reject'
  category: 'newsletter' | 'sms_messages' | 'personalised_email' | 'offline_post'
  valid_until: 'unlimited'
}

const submit = async (values) => {
  let formValid = true

  const fields = {}
  Object.keys(values).forEach((key) => {
    if (key.includes('#birthday')) {
      const strippedKey = key.replace('#birthday', '')
      const day = values[key].day
      const month = values[key].month
      const year = values[key].year
      // Not 100% sure this is correct. But it's the same as it was...
      fields[strippedKey] = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), 12, 0, 0).getTime() / 1000
    } else {
      fields[key] = values[key]
    }
  })

  const consentList: Consent[] = []

  // default value if no event is selected in Bloomreach
  if (!data.value.eventCategory?.selectionValues?.length) {
    consentList.push({
      action: 'accept',
      category: 'newsletter',
      valid_until: 'unlimited',
    })
  }

  data.value.eventCategory?.selectionValues?.forEach((category) => {
    if (category.key === 'sms_messages' && fields?.phone_marketing) {
      consentList.push({
        action: data.value.eventAction.selectionValues[0].key,
        category: category.key,
        valid_until: 'unlimited',
      })
    }

    if (category.key !== 'sms_messages') {
      consentList.push({
        action: data.value.eventAction.selectionValues[0].key,
        category: category.key,
        valid_until: 'unlimited',
      })
    }
  })

  const body = {
    action: 'new',
    signup_source: data.value.source,
    consent_list: consentList,
    ...fields,
  }

  try {
    if (formValid) {
      window.exponea.track('double_opt_in', body)
      pushEventToGa()
      success.value = true
      errors.value = []
    }
  } catch (error) {
    success.value = false
    errors.value = [error]
  }
}
</script>

<style lang="scss">
html.theme--storefront {
  .exponea-form {
    margin: 80px auto 120px;
    max-width: 600px;

    a {
      display: inline-block;
      text-underline-offset: 2px;
      text-decoration: underline;
      transition: all 0.2s ease;

      &:hover,
      &:focus {
        color: var(--cta-pink-color);
      }
    }

    &__element {
      display: block;
      margin-bottom: var(--spacer-base);
      width: 100%;
    }

    &__select {
      display: flex;
      align-items: center;
      --select-option-font-size: var(--font-size--lg);
      flex-wrap: wrap;
    }

    &__horizontal {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: var(--spacer-lg);
    }

    &__checkbox-label,
    &__birthday-label {
      p {
        display: inline-block;
        margin: 0;
        text-align: left;
      }
    }

    &__success {
      margin-top: var(--spacer-base);
      border-radius: var(--spacer-2xs);
      padding: var(--spacer-sm);
      font-size: var(--font-size--base);
      background: #32b304;
      color: var(--c-white);
    }

    .sf-input__label {
      max-width: calc(100% - 32px);
      inline-size: inherit;
      word-wrap: break-word;
    }
  }
}
</style>
