import type { Wishlist, WishlistItemInput } from '@vue-storefront/magento-types'
import { useUiNotification, useGTMComposable } from '~/composables'

import type {
  WishlistIds,
  UseWishlistAddItemParams,
  UseWishlistAddItemToCartParams,
  UseWishlistErrors,
  UseWishlistIsInWishlistParams,
  UseWishlistLoadParams,
  UseWishlistRemoveItemParams,
  UseWishlistUpdateItemParams,
} from '~/stores/useWishlistStore/wishlistStore'
import { findItemOnWishlist } from '~/stores/useWishlistStore/helpers/findItemOnWishlist'
import updateProductsInWishlistQuery from '~/magentoMiddleware/customQueries/updateProductsInWishlist.gql'
import addWishlistItemsToCartQuery from '~/magentoMiddleware/customQueries/addWishlistItemsToCart.gql'
import { handleCartError } from '~/composables/useCart/utils/handleCartError'

export const useWishlistStore = defineStore('wishlist', () => {
  const logger = useAppLogger('useWishlistStore')
  const { $sdk, $i18n } = useNuxtApp()
  const { t } = $i18n
  const localePath = useLocalePath()
  const route = useRoute()
  const { products, activeProductSku } = storeToRefs(useProductStore())
  const { isLoggedIn } = storeToRefs(useCustomerStore())
  const { send: sendNotification } = useUiNotification()
  const { trackCartChange } = useGTMComposable()

  const state = reactive({
    loading: <boolean>false,
    wishlist: <Wishlist>{ items_count: 0 },
    wishlistIds: <WishlistIds>{ items_count: 0, items_v2: { items: [] } },
    showWishlistModal: <boolean>false,
  })

  const error = ref<UseWishlistErrors>({
    addItem: null,
    addItemToCart: null,
    removeItem: null,
    updateItem: null,
    load: null,
    clear: null,
    afterAddingWishlistItemToCart: null,
  })

  const loadWishlist = async (params?: UseWishlistLoadParams) => {
    logger.debug('useWishlist/load')

    try {
      state.loading = true
      logger.debug('[Magento Storefront]: useWishlist.load params->', params)

      if (isLoggedIn.value) {
        const { data } = await $sdk.magento.wishlist(params?.searchParams || { currentPage: 1, pageSize: 10 }, {
          wishlist: 'get-wishlist',
        })

        logger.debug('[Result]:', { data })
        const loadedWishlist = data?.customer?.wishlists ?? []
        if (loadedWishlist[0]) {
          ;[state.wishlist] = loadedWishlist
        }
      }

      error.value.load = null
    } catch (err) {
      error.value.load = err
      logger.error('useWishlist/load', err)
    } finally {
      state.loading = false
    }

    return state.wishlist
  }

  const loadWishlistIds = async () => {
    logger.debug('useWishlist/loadWishlistIds')

    try {
      state.loading = true

      if (isLoggedIn.value) {
        const { data } = await $sdk.magento.wishlist(
          { currentPage: 1, pageSize: 50 },
          {
            wishlist: 'get-wishlist-ids',
          },
        )

        logger.debug('[Result]:', { data })
        const loadedWishlist = data?.customer?.wishlists ?? []
        if (loadedWishlist?.[0]) {
          state.wishlistIds = loadedWishlist[0]
        }
      }

      error.value.load = null
    } catch (err) {
      error.value.load = err
      logger.error('useWishlist/loadWishlistIds', err)
    } finally {
      state.loading = false
    }

    return state.wishlistIds
  }

  const addItemToWishlist = async ({ product, configuration = {} }: UseWishlistAddItemParams) => {
    logger.debug('useWishlist/addItem', product)

    if (!isLoggedIn.value) {
      addProductToSessionStorage(configuration)

      return sendNotification({
        id: Symbol('user_updated'),
        message: 'loginToAddToWishlist',
        link: localePath('/customer/account/login?redirectToReferer=1'),
        linkText: t('You have to be logged in to add items to your wishlist.') as string,
        type: 'danger',
        icon: 'error',
        persist: false,
        title: t('Wishlist'),
        timeToLive: 5000,
      })
    }

    try {
      state.loading = true
      logger.debug('[Magento Storefront]: useWishlist.addItem params->', {
        currentWishlist: state.wishlist,
        product,
      })

      if (!state.wishlist) {
        await loadWishlist({})
      }

      const itemOnWishlist = findItemOnWishlist(state.wishlistIds, product)

      if (itemOnWishlist) {
        return
      }

      const items: WishlistItemInput[] = []
      // @ts-ignore
      // eslint-disable-next-line no-underscore-dangle
      switch (product.__typename) {
        case 'VirtualProduct':
        case 'DownloadableProduct':
        case 'GroupedProduct':
        case 'GiftCard':
        case 'SimpleProduct': {
          items.push({
            sku: product.sku,
            quantity: 1,
          })
          break
        }
        case 'ConfigurableProduct': {
          items.push({
            sku: product.sku,
            quantity: 1,
            selected_options: Object.values(configuration),
          })
          break
        }
        case 'BundleProduct': {
          items.push({
            sku: product.sku,
            quantity: 1,
            entered_options: [],
          })
          break
        }
        default:
          // @ts-ignore
          // eslint-disable-next-line no-underscore-dangle
          return logger.error(`Product Type ${product.__typename} not supported in add to wishlist yet`)
      }

      const { data, errors } = await $sdk.magento.addProductToWishList(
        {
          id: '0',
          items,
        },
        {
          addProductsToWishlist: 'add-products-to-wishlist',
        },
      )

      if (errors) {
        throw new Error(errors[0].message)
      }

      loadWishlistIds()

      logger.debug('[Result]:', { data })

      sendNotification({
        id: Symbol('product_added_to_wishlist'),
        message: t('You added {product} to your wishlist.', { product: product.name }) as string,
        type: 'success',
        icon: 'check',
        persist: false,
      })

      state.wishlist = data?.addProductsToWishlist?.wishlist ?? {}
    } catch (err) {
      error.value.addItem = err
      logger.error('useWishlist/addItem', err)
    } finally {
      state.loading = false
    }
  }

  const updateItem = async ({ wishlistItems, customQuery = { wishlist: 'wishlist' } }: UseWishlistUpdateItemParams) => {
    const wishlistId = state.wishlist?.id

    logger.debug('useWishlist/updateItem', wishlistItems)

    try {
      state.loading = true
      logger.debug('[Magento Storefront]: useWishlist.updateItem params->', {
        currentWishlist: state.wishlist,
        wishlistItems,
        customQuery,
      })

      const { data, errors } = await $sdk.magento.customMutation({
        mutation: updateProductsInWishlistQuery?.loc.source.body,
        mutationVariables: { wishlistId, wishlistItems },
      })

      logger.debug('[Result]:', { data })
      error.value.updateItem = null
      state.wishlist = data?.updateProductsInWishlist?.wishlist ?? {}
    } catch (err) {
      error.value.updateItem = err
      logger.error('useWishlist/updateItem', err)
    } finally {
      state.loading = false
    }
  }

  const removeItemFromWishlistIds = (id) => {
    const itemIndex = state.wishlistIds?.items_v2.items.findIndex((item) => item.id === id)
    state.wishlistIds?.items_v2.items.splice(itemIndex, 1)
    state.wishlistIds.items_count--
  }

  const removeItem = async ({ product }: UseWishlistRemoveItemParams) => {
    logger.debug('useWishlist/removeItem', product)

    try {
      state.loading = true
      logger.debug('[Magento Storefront]: useWishlist.removeItem params->', {
        currentWishlist: state.wishlist,
        product,
      })

      const itemOnWishlist = findItemOnWishlist(state.wishlistIds, product)
      const { data } = await $sdk.magento.removeProductsFromWishlist(
        {
          id: '0',
          items: [itemOnWishlist.id],
        },
        { removeProductsFromWishlist: 'remove-products-from-wishlist' },
      )

      removeItemFromWishlistIds(itemOnWishlist.id)

      logger.debug('[Result]:', { data })
      error.value.removeItem = null
      state.wishlist = data?.removeProductsFromWishlist?.wishlist ?? {}
    } catch (err) {
      error.value.removeItem = err
      logger.error('useWishlist/removeItem', err)
    } finally {
      state.loading = false
    }
  }

  const addItemToCart = async ({ wishlistItem, callback }: UseWishlistAddItemToCartParams) => {
    logger.debug('useWishlist/addItemToCart', wishlistItem)
    const wishlistItemIds = [wishlistItem?.id]

    try {
      state.loading = true
      logger.debug('[Magento Storefront]: useWishlist.addItemToCart params->', {
        currentWishlist: state.wishlist,
        wishlistItemIds,
      })

      if (!state.wishlist) {
        await loadWishlist({})
      }

      if (!isLoggedIn.value) {
        logger.error('Need to be authenticated to add a product to wishlist')
      }

      const wishlistId = state.wishlist?.id

      const {
        data: { addWishlistItemsToCart },
        errors,
      } = await $sdk.magento.customMutation({
        mutation: addWishlistItemsToCartQuery.loc?.source.body,
        mutationVariables: { wishlistId, wishlistItemIds },
      })

      if (addWishlistItemsToCart.status) removeItemFromWishlistIds(wishlistItemIds[0])

      callback?.(addWishlistItemsToCart)

      logger.debug('[Result]: addWishlistItemsToCart => ', { addWishlistItemsToCart, errors })

      state.wishlist = addWishlistItemsToCart?.wishlist
        ? {
            ...addWishlistItemsToCart.wishlist,
            items_count: addWishlistItemsToCart.wishlist.items_v2.items.length,
          }
        : {}

      logger.debug('[Result]: wishlistStore after update => ', state.wishlist)

      trackCartChange('addToCart', {
        product: wishlistItem.product,
        sku: wishlistItem.product.sku,
        variant: wishlistItem.configured_variant?.sku,
        quantity: wishlistItem.quantity,
      })
    } catch (err) {
      error.value.addItem = err
      handleCartError('useWishlist/addItemToCart', err, true)
    } finally {
      state.loading = false
    }
  }

  const clear = () => {
    logger.debug('useWishlist/clear')

    try {
      state.loading = true
      error.value.clear = null
      state.wishlist = { items_count: 0 }
    } catch (err) {
      error.value.clear = err
      logger.error('useWishlist/clear', err)
    } finally {
      state.loading = false
    }
  }

  const isInWishlist = ({ product }: UseWishlistIsInWishlistParams) => {
    const wishlistProduct = findItemOnWishlist(state.wishlistIds, product)

    return !!(wishlistProduct?.id && wishlistProduct?.quantity)
  }

  const setWishlist = (newWishlist: Wishlist) => {
    state.wishlist = newWishlist ?? {}
    logger.debug('useWishlist/setWishlist', newWishlist)
  }

  const addProductToWishlist = (productConfiguration) => {
    return addItemToWishlist({
      product: products.value[activeProductSku.value],
      configuration: productConfiguration,
    })
  }

  const addProductToSessionStorage = (productConfiguration) => {
    sessionStorage.setItem(
      'addToWishlistAfterLogin',
      JSON.stringify({
        product: products.value[activeProductSku.value],
        configuration: productConfiguration,
      }),
    )
    // Vue / Magento hack - @todo remove when login page is Vue
    // When going to an external page router.beforeEach is not called, so we do its logic here
    const scrollPositions = JSON.parse(sessionStorage.getItem('scrollPositions') || '{}')
    sessionStorage.setItem(
      'scrollPositions',
      JSON.stringify({
        ...scrollPositions,
        [route.fullPath]: window.scrollY,
      }),
    )
  }

  const addToWishlistAfterLogin = () => {
    if (sessionStorage.addToWishlistAfterLogin && isLoggedIn.value) {
      void addItemToWishlist(JSON.parse(sessionStorage.addToWishlistAfterLogin))
    }

    delete sessionStorage.addToWishlistAfterLogin
  }

  const removeMagentoLocalStorageKeys = () => {
    const keys = ['wishlist', 'wishlist-dynamic']
    const storage = JSON.parse(localStorage['mage-cache-storage'] ?? '{}')
    keys.forEach((key) => delete storage[key])
    localStorage['mage-cache-storage'] = JSON.stringify(storage)
  }

  // Remove Magento local storage keys on every wishlist mutation
  watch(state.wishlist, () => {
    removeMagentoLocalStorageKeys()
  })

  return {
    ...toRefs(state),
    isInWishlist,
    setWishlist,
    updateItem,
    removeItem,
    addItemToCart,
    loadWishlist,
    loadWishlistIds,
    addItemToWishlist,
    clear,
    addProductToWishlist,
    addToWishlistAfterLogin,
  }
})

export default useWishlistStore
