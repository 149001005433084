<template>
  <div class="quantity-selector">
    <label v-if="!isUpdatingQuantity" :disabled="isDisabled">
      <span>{{ $t('Quantity') }}:</span>
      <select v-model="quantity" class="quantity-selector__select">
        <option v-for="i in 10" :key="i" :value="i">{{ i }}</option>
      </select>
      <ChevronDownIcon class="quantity-selector__select-icon" />
    </label>
    <SfLoader v-else></SfLoader>
    <button
      class="quantity-selector__delete-button"
      :disabled="isDeleting || isDeletingDisabled"
      @click="emit('delete')"
    >
      <TrashIcon v-if="!isDeleting" class="quantity-selector__delete-button-icon" />
      <SfLoader v-else></SfLoader>
    </button>
  </div>
</template>

<script setup>
import ChevronDownIcon from '~/storefront/assets/icons/chevron-down-24.svg'
import TrashIcon from '~/storefront/assets/icons/trash.svg'

const props = defineProps({
  qty: {
    type: [Number, String],
    default: 1,
  },
  isInStock: {
    type: Boolean,
    default: true,
  },
  isDeleting: {
    type: Boolean,
    default: false,
  },
  isDeletingDisabled: {
    type: Boolean,
    default: false,
  },
  isUpdatingQuantity: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['input', 'delete'])

const quantity = computed({
  get() {
    return props.qty
  },
  set(value) {
    if (value === 0) {
      isDeleting.value = true
    }
    emit('input', value)
  },
})

const isDisabled = computed(() => !props.isInStock || props.disabled || null)
</script>

<style lang="scss" scoped>
html.theme--storefront {
  .quantity-selector {
    display: flex;
    align-items: center;
    font-size: var(--font-size-10);
    margin-left: auto;
    height: 18px;
    padding-right: var(--spacer-2xs);

    @include for-desktop {
      font-size: var(--font-size-13);
    }

    span {
      margin-right: var(--spacer-xs);
    }

    label {
      position: relative;
      display: flex;
      align-items: center;
      margin-right: var(--spacer-12);
      line-height: 18px;
      color: var(--black-color);
      cursor: pointer;

      &[disabled] {
        pointer-events: none;
        color: var(--gray-dark-accent-color);
      }
    }

    &__select {
      -webkit-appearance: none;
      appearance: none;
      background: transparent;
      font-size: inherit;
      border: none;
      color: inherit;
      padding: 0 var(--spacer-base) 0 var(--spacer-2xs);
      line-height: 18px;

      @include for-desktop {
        &:hover {
          color: var(--black-secondary-color);
        }
      }

      &-icon {
        position: absolute;
        top: -3px;
        right: 0;
        pointer-events: none;
      }
    }

    .sf-loader {
      width: 24px;
      height: 24px;
    }

    > .sf-loader {
      margin-right: var(--spacer-base);
    }
  }

  .quantity-selector__delete-button {
    position: relative;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    color: var(--black-color);

    &[disabled] {
      pointer-events: none;
      color: var(--gray-dark-accent-color);
    }

    @include for-desktop {
      &:hover {
        color: var(--black-secondary-color);
      }
    }

    &-icon {
      display: block;
    }
  }
}
</style>
