<template>
  <div
    v-if="addingProductToWishlist"
    class="wishlist-variant-selection-modal__content wishlist-variant-selection-modal__content--adding"
  >
    <SfLoader />
    <h4>
      {{ product.name }}
    </h4>
    <p>
      {{ $t('Adding product to wishlist...') }}
    </p>
  </div>
  <div v-else class="wishlist-variant-selection-modal__content">
    <h4>{{ product.name }}</h4>
    <ProductVariantMatrix ref="productVariantMatrix" type="wishlist" :sku="sku" :showProductRelatedColors="false" />
    <SfButton
      class="sf-button--add-to-wishlist sf-button--pink sf-button--full-width"
      :disabled="!canAddProductToWishlist"
      :data-sku="selectedProduct.sku"
      :data-id="selectedProduct.id"
      @click="addToWishlist"
    >
      <span>{{ $t('Add to Wishlist') }}</span>
    </SfButton>
  </div>
</template>

<script setup lang="ts">
import { getSelectedProductVariant } from '~/utils/productGetters'

const { products: storeProducts, activeProductSku } = storeToRefs(useProductStore())
const { productCustomOptionsCanAddToCartHandler } = useProductStore()
const { showWishlistModal } = storeToRefs(useWishlistStore())
const { addProductToWishlist } = useWishlistStore()

const product = computed(() => {
  return storeProducts.value[activeProductSku.value]
})
const sku = computed(() => product.value?.sku)
const addingProductToWishlist = ref(false)

const productVariantMatrix = ref()

const productCustomOptionsCanAddToCart = computed(() => productCustomOptionsCanAddToCartHandler(activeProductSku.value))

const canAddProductToWishlist = computed(() => {
  const selectedOptions = Object.values(productVariantMatrix.value?.productConfiguration || {}).sort(sortStrings)
  if (!selectedOptions.length) return false
  return (
    !!product.value?.variants?.find((variant) =>
      variant.attributes.every((attribute) => selectedOptions.includes(attribute.uid)),
    ) && productCustomOptionsCanAddToCart.value
  )
})

const addToWishlistImediately = computed(
  () =>
    showWishlistModal.value &&
    (product.value?.configurable_options?.every((option) => option.values.length <= 1) ||
      product.value?.__typename === 'SimpleProduct'),
)

const addToWishlist = async () => {
  addingProductToWishlist.value = true

  await addProductToWishlist(productVariantMatrix.value.productConfiguration)
  showWishlistModal.value = false

  addingProductToWishlist.value = false
}

const selectedProduct = computed(() =>
  getSelectedProductVariant(product.value, productVariantMatrix.value?.productConfiguration),
)

onMounted(() => {
  if (addToWishlistImediately.value) addToWishlist()
})
</script>

<style lang="scss" scoped>
html.theme--storefront {
  .wishlist-variant-selection-modal {
    .sf-loader {
      min-height: var(--spacer-2xl);

      &__overlay {
        background: none;
      }
    }

    .sf-modal__container {
      --modal-index: 21;
    }

    .sf-modal__overlay {
      --overlay-z-index: 20;
    }

    .sf-button--add-to-wishlist {
      position: relative;
      overflow: hidden;
      margin-top: auto;
      height: auto;
      padding: 16px 32px;
      white-space: normal;
      line-height: 130%;
    }

    &__content {
      min-height: var(--spacer-2xl);
      display: flex;
      flex-direction: column;
      gap: var(--spacer-sm);

      h4 {
        margin-bottom: 0;
        max-width: 92%;
        line-height: 126%;
      }

      p {
        margin-top: 0;
      }

      &--adding {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        .sf-loader {
          min-height: var(--spacer-2xl);
        }

        p {
          margin-top: var(--spacer-10);
        }
      }
    }

    .sf-loader--add-to-wishlist {
      --loader-overlay-background: var(--button-background);
      --loader-spinner-stroke: var(--white-color);
      position: absolute;
    }

    .product-variant-matrix {
      padding: 0 0 var(--spacer-sm);
    }
  }
}
</style>
