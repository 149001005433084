<template>
  <article
    v-if="store"
    class="store-grid-item"
    :class="{ 'store-grid-item--open': showOpeningHours }"
    :aria-expanded="showOpeningHours"
  >
    <nuxt-link class="store-grid-item__link" :to="formatUrl(store.storeUrl)">
      <BynderAsset
        class="store-grid-item__asset"
        :class="{ 'store-grid-item__asset--hidden': showOpeningHours }"
        :data="asset.data"
        :size="asset.size"
        :ratio="asset.ratio"
        :alt="store.storeName"
      />

      <div class="store-grid-item__meta">
        <p class="store-grid-item__name">{{ store.storeName }}</p>
        <h2 class="store-grid-item__city">
          <span>{{ store.address.city }}</span>
          <ChevronRightIcon v-if="!showOpeningHours" aria-hidden="true" />
        </h2>
        <hr />
        <p class="store-grid-item__address">
          <span>{{ formattedAddress }}</span>
          <span>{{ formattedCity }}</span>
        </p>
      </div>
      <div class="store-grid-item__opening-hours" :class="{ 'store-grid-item__opening-hours--open': showOpeningHours }">
        <ul class="store-grid-item__opening-hours-list store-grid-item__opening-hours-list--regular">
          <li v-for="weekDay in store.openingHours" :key="weekDay.day">
            <span>{{ weekDay.day }}</span>
            <span v-if="weekDay.hours">{{ weekDay.hours }}</span>
            <span v-else>{{ $t('closed') }}</span>
          </li>
        </ul>

        <ul class="store-grid-item__opening-hours-list store-grid-item__opening-hours-list--adjusted">
          <li v-for="weekDay in firstAdjustedOpeningHours" :key="weekDay.day">
            <span>{{ weekDay.day }}</span>
            <span v-if="weekDay.hours">{{ weekDay.hours }}</span>
            <span v-else>{{ $t('closed') }}</span>
          </li>
        </ul>
      </div>
    </nuxt-link>

    <SfButton
      class="sf-button--tiny sf-button--black store-grid-item__opening-hours-button"
      :class="{ 'store-grid-item__opening-hours-close-button': showOpeningHours }"
      @click.prevent="showOpeningHours = !showOpeningHours"
    >
      <span v-if="!showOpeningHours">{{ $t('Opening hours') }}</span>
      <ClockIcon v-if="!showOpeningHours" />
      <TimesIcon v-else />
    </SfButton>

    <br-manage-content-button :content="store.document" />
  </article>
</template>

<script setup lang="ts">
import type { Store } from '~/types/storeGrid'
import ChevronRightIcon from '~/storefront/assets/icons/chevron-right.svg'
import ClockIcon from '~/storefront/assets/icons/clock.svg'
import TimesIcon from '~/storefront/assets/icons/times.svg'

const props = defineProps({
  store: {
    type: Object as PropType<Store>,
    required: true,
  },
})

const showOpeningHours = ref(false)

const formattedAddress = computed(() =>
  `${props.store.address.streetName} ${props.store.address.houseNumber} ${props.store.address.addition}`.trim(),
)

const formattedCity = computed(() => `${props.store.address.postalCode} ${props.store.address.city}`.trim())

const firstAdjustedOpeningHours = computed(() => props.store.adjustedOpeningHours.slice(0, 1))

const asset = computed(() => ({
  data: props.store.document.model.data.storeImage,
  size: '(max-width: 375px) 100vw, 400px',
  ratio: 0.5575,
}))
</script>

<style lang="scss">
html.theme--storefront {
  .store-grid-item {
    display: flex;
    position: relative;
    background: var(--gray-background-color);
    border-top-left-radius: var(--spacer-2xs);
    border-top-right-radius: var(--spacer-2xs);
    margin-bottom: var(--spacer-xs);
    overflow: hidden;
    transition: all 0.12s ease;

    .bynder-asset {
      &:before {
        transition: all 0.25s ease;
      }
    }

    &--open {
      .bynder-asset {
        --padding-top: 0 !important;
      }
    }

    .store-grid-item__link {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-height: 324px;
      color: var(--black-secondary-color);
      transition: all 0.25s ease;

      @include for-desktop {
        min-height: 396px;
      }
    }

    @include for-desktop {
      background: none;
      border-radius: var(--spacer-2xs);
      margin-bottom: 0;

      &:hover,
      &:focus,
      &:active {
        background-color: var(--gray-background-color);
      }
    }

    &[aria-expanded='true'] {
      background-color: var(--gray-background-color);
    }

    &__asset {
      position: relative;
      margin: 0;
      padding: 0;
      overflow: hidden;
      transition: all 0.2s ease;
    }

    &__meta {
      padding: var(--spacer-sm);
      min-height: 132px;

      @include for-desktop {
        padding: var(--spacer-md);
        min-height: 175px;
      }

      &--open {
        transform: translateY(-100%);
      }

      p {
        margin-top: 0;
      }
    }

    &__name {
      font-size: var(--font-size-10);
      line-height: 1.125rem;
      color: var(--cta-pink-color);
      margin-bottom: 0;

      @include for-desktop {
        font-size: var(--font-size-13);
        margin-bottom: var(--spacer-2xs);
      }
    }

    &__city {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      line-height: var(--sm-line-height);

      span {
        flex: 1;
      }

      svg {
        margin-left: var(--spacer-2xs);
      }
    }

    &__address {
      font-size: var(--font-size-xs);
      line-height: var(--lg-line-height);
      margin-bottom: 0;

      @include for-desktop {
        font-size: var(--font-size--base);
        line-height: var(--global-line-height);
      }

      span {
        @include for-desktop {
          &:first-child {
            &:after {
              content: ', ';
            }
          }
        }
      }
    }

    &__opening-hours {
      max-height: 0;
      padding: 0 var(--spacer-sm);
      transition: all 0.5s ease;
      opacity: 0;

      @include for-desktop {
        padding: 0 var(--spacer-md);
      }

      &--open {
        margin-top: -16px;
        opacity: 1;
        padding-bottom: var(--spacer-sm);
        max-height: auto;

        @include for-desktop {
          max-height: 0;
          margin-top: -24px;
          padding-bottom: var(--spacer-md);
        }
      }

      ul {
        margin: var(--spacer-sm) 0 1.428em;
        padding: 0;
        font-size: var(--font-size-xs);
        line-height: 1.428;

        @include for-desktop {
          margin-top: var(--spacer-md);
          font-size: var(--font-size-sm);
        }

        &:last-child {
          margin-bottom: 0;
        }

        li {
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;

          > span {
            &:first-child {
              flex: 1;
              padding-right: var(--spacer-xs);
              text-transform: capitalize;
            }
          }
        }
      }
    }

    &__opening-hours-button {
      position: absolute;
      top: var(--spacer-xs);
      right: var(--spacer-xs);

      @include for-desktop {
        top: var(--spacer-md);
        right: var(--spacer-md);
      }
    }

    .sf-button.store-grid-item__opening-hours-close-button {
      --button-padding: var(--spacer-2xs);
    }

    hr {
      background-color: var(--black-color);
      border: 0;
      height: 1px;
      margin: var(--font-size-10) 0;

      @include for-desktop {
        margin: var(--font-size-sm) 0;
      }
    }
  }
}
</style>
