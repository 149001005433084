<template>
  <div class="product-variant-matrix">
    <SfSkeleton v-if="loading" class="product-variant-matrix__heading--loader" />

    <p v-else-if="options && options.length" class="product-variant-matrix__heading">
      {{
        $t('Please select the {names} below', {
          names: productNames,
        })
      }}
    </p>

    <SfSkeleton v-if="loading" class="product-variant-matrix__content--loader" />
    <div v-else class="product-variant-matrix__content">
      <ProductRelatedColors v-if="showProductRelatedColors" :sku="sku" />

      <div v-if="options && options.length" class="product-variant-matrix__options">
        <!-- base_color, color, size, intial, birth_year, giftcard_price -->
        <component
          :is="option.component"
          v-for="(option, index) in options"
          :key="option.uid"
          :option="option"
          :optionsLength="options.length"
          :parentUid="options[index - 1]?.attribute_uid || ''"
          :productConfiguration="productConfiguration"
          :type="type"
          :sku="sku"
          @updateProductConfiguration="updateProductConfiguration"
        />
      </div>

      <div v-if="customizableOptions.length" class="product-variant-matrix__options">
        <ProductVariantEngraving v-for="option in customizableOptions" :key="option.uid" :option="option" :sku="sku" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ConfigurableProductOption } from '~/stores/useProductStore/useProductStore'
import { getProductOptionsNames } from '~/utils/productGetters'
import ProductVariantColor from './Color.vue'
import ProductVariantSize from './Size.vue'
import ProductVariantGiftcardPrice from './GiftcardPrice.vue'
import ProductVariantSelect from './Select.vue'

const props = defineProps({
  type: {
    type: String as PropType<'wishlist' | 'cart'>,
    required: true,
  },
  showProductRelatedColors: {
    type: Boolean,
    default: true,
  },
  sku: {
    type: String,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

const { configurableOptions, products } = storeToRefs(useProductStore())
const { updateGiftcardConfiguration, updateConfigurableOptions, updateProductMedia } = useProductStore()

const productConfiguration = ref({})

const options = computed(() => {
  let options = configurableOptions.value[props.sku]

  return options
    ?.filter((option) => {
      if (option.attribute_code === 'size' || option.attribute_code === 'base_color') {
        return option.values?.length > 1
      }
      return option
    })
    .map((option) => {
      const component = () => {
        switch (option.attribute_code) {
          case 'base_color':
          case 'color':
            return ProductVariantColor
          case 'size':
            return ProductVariantSize
          case 'giftcard_price':
            return ProductVariantGiftcardPrice
          default:
            return ProductVariantSelect
        }
      }

      return {
        ...option,
        parentUid: option.attribute_uid,
        component: component(),
      }
    })
})

const customizableOptions = computed(() => (products.value[props.sku]?.options as ConfigurableProductOption[]) || [])

const productNames = ref(getProductOptionsNames(products.value[props.sku]))

const updateProductConfiguration = async ({ key, value }) => {
  productConfiguration.value = {
    ...productConfiguration.value,
    [key]: value,
  }

  updateGiftcardConfiguration(key, value)
  updateConfigurableOptions(productConfiguration.value)

  const currentSlideIndex = useState('currentSlideIndex')
  currentSlideIndex.value = await updateProductMedia(productConfiguration.value)
}

onMounted(() => {
  products.value[props.sku]?.configurable_options?.forEach((option) => {
    const hasOnlyOneAvailableOption = option.values.length === 1

    updateProductConfiguration({
      key: option.attribute_uid,
      value: hasOnlyOneAvailableOption ? option.values[0].uid : null,
    })
  })
})

defineExpose({
  productConfiguration,
})
</script>

<style lang="scss">
html.theme--storefront {
  .product-variant-matrix {
    --variant-button-height: 34px;
    display: block;
    position: relative;
    padding: 0 var(--spacer-15) var(--spacer-sm);

    @include for-desktop {
      padding: 0 0 var(--spacer-sm);
    }

    .sf-property {
      --property-value-font-size: var(--font-size--xs);
      --property-value-font-weight: var(--font-weight--bold);
      --property-value-color: var(--black-secondary-color);
      --property-name-font-size: var(--font-size--xs);
      --property-name-margin: 0 var(--spacer-xs) 0 0;
      --property-name-color: var(--black-secondary-color);
      margin: 0;

      @include for-desktop {
        --property-name-font-size: var(--font-size--sm);
        --property-value-font-size: var(--font-size--sm);
      }

      &:not(.is-selected) {
        --property-name-content: unset;
      }

      &[data-attribute='size'] {
        --property-name-font-size: var(--font-size--sm);
        --property-value-font-size: var(--font-size--sm);
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: var(--spacer-base);

      &--loader.sf-skeleton {
        margin: var(--spacer-base) 0 0;
        height: 50px;
      }
    }

    &__heading {
      font-size: var(--font-size--sm);
      line-height: 1.4286;
      margin: 0 0 var(--spacer-sm);

      &--loader.sf-skeleton {
        margin: 0 0 14px;
        height: 20px;
        width: 40%;
      }
    }

    &__options {
      display: flex;
      flex-direction: column;
      gap: var(--spacer-base);
    }
  }

  .product-variant {
    &__heading {
      display: flex;
      align-items: center;
      gap: var(--spacer-sm);
      margin-bottom: var(--spacer-xs);
    }

    .sf-property {
      --property-name-font-size: var(--font-size--xs);
      --property-value-font-size: var(--font-size--xs);
    }
  }

  .sf-modal .product-variant-matrix {
    padding: 0;
  }
}
</style>
