<template>
  <div class="image-text-cards" :data-columns="columns">
    <CardItem
      v-for="(banner, index) in banners"
      :key="index"
      :title="banner.bannerTitle"
      :description="banner.bannerDescription"
      :category="getCategory(banner)"
      :image="banner.bannerImage"
      :link="formatUrl(banner.ctaUrl || banner.ctaLink)"
      :asset="banner.asset"
    />
  </div>
</template>

<script setup lang="ts">
import CardItem from '~/storefront/components/Bloomreach/Banner/CardItem.vue'

interface Props {
  banners: any[]
}

const props = defineProps<Props>()
const getCategory = (banner) =>
  banner?.hideCategory ? '' : banner.categoryManually ? banner.categoryManually : banner.category || ''

const columns = computed(() => {
  return props.banners.length % 2 === 0 ? 2 : 3 // When carrot of two equals zero, show two columns. Otherwise show three.
})
</script>

<style lang="scss">
html.theme--storefront {
  .image-text-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--spacer-xs);
    margin-bottom: var(--mj-component-margin-bottom);

    @include for-desktop {
      grid-template-columns: repeat(3, 1fr);
      gap: var(--spacer-md);
      margin-left: auto;
      margin-right: auto;
      max-width: var(--container-width-lg);
    }

    &[data-columns='2'] {
      grid-template-columns: repeat(2, 1fr);
      --card-image-relative-height: 83.771%;

      .sf-card {
        border-radius: var(--spacer-2xs);
      }
    }
  }
}
</style>
