<template>
  <nav class="top-nav grid-container">
    <div class="top-nav__inner">
      <br-component component="menu" />
      <div class="top-nav__arrow"></div>
    </div>
  </nav>
</template>

<script setup lang="ts"></script>

<style lang="scss">
html.theme--storefront {
  .top-nav {
    position: relative;
    visibility: visible;
    width: 100%;
    opacity: 1;
    padding: 0;
    background: var(--black-color);
    color: var(--white-color);

    @include for-mobile {
      display: none;
    }

    @media screen and (min-width: 1080px) and (max-width: 1180px) {
      font-size: 92.5%;
    }

    @media screen and (min-width: 1024px) and (max-width: 1079px) {
      font-size: 87.5%;
    }

    .uitgelicht-item {
      background: var(--primary-color);
      color: var(--black-secondary-color);
      font-size: var(--font-size-xs);
      line-height: var(--global-line-height);
      text-transform: uppercase;
      text-wrap: nowrap;
      padding: var(--spacer-xs);
      border-radius: var(--spacer-2xs);
      pointer-events: none;
      z-index: 0;
      margin-left: var(--spacer-sm);
      margin-right: 0;
      display: inline-block;
      margin-top: -8px;
      margin-bottom: -8px;
    }

    .top-nav__arrow {
      position: absolute;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid var(--primary-color);
      top: -10px;
      left: 0;
      transform: translate(50vw, 50px);
      opacity: 0;
      pointer-events: none;

      &.open {
        opacity: 1;
      }
    }
  }
}
</style>
