<template>
  <div v-if="option" class="product-variant product-variant--giftcard">
    <div class="product-variant__heading">
      <SfProperty :name="$t('Giftcard price')" :value="selectedVariant.label" />
    </div>
    <div class="product-variant__product-giftcard-prices">
      <SfButton
        v-for="value in option.values"
        :key="option.attribute_uid"
        class="product-giftcard-price"
        :class="{
          'product-giftcard-price--selected': selectedVariant.uid === value.uid,
          'product-giftcard-price--out-of-stock': value.out_of_stock !== 'IN_STOCK' || disabled,
        }"
        :disabled="disabled"
        :data-sku="selectedProductSku(value.uid)"
        :data-id="selectedProductId(value.uid)"
        :data-label="value.label"
        :data-attribute_code="option.attribute_code"
        @click="() => selectVariant(value)"
      >
        {{ value.label }}
      </SfButton>
    </div>
    <div class="product-variant__product-giftcards">
      <SfLoader v-if="productsLoading" class="product__giftcard-holder" />
      <Swiper
        v-else
        class="swiper-container product__giftcard-holder"
        :modules="[Navigation]"
        :speed="400"
        :threshold="2"
        :slidesPerView="3.5"
        :spaceBetween="8"
        :navigation="true"
        :breakpoints="{
          768: {
            slidesPerView: 3.5,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
            spaceBetween: 8,
          },
          1024: {
            slidesPerView: 4.5,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
            spaceBetween: 8,
          },
        }"
      >
        <SwiperSlide v-for="product in products" :key="product.uid">
          <label
            class="product__giftcard-holder__label"
            :class="{ 'product__giftcard-holder__label--selected': selectedGiftcardHolderSku === product.sku }"
            @click.stop="selectGiftcardHolder(product)"
          >
            <input class="display-none" type="radio" :value="product.sku" />
            <ProductCard :product="product">
              <template #colors>{{ null }}</template>
              <template #image="imageSlotProps">
                <template v-if="Array.isArray(imageSlotProps.image)">
                  <nuxt-img
                    v-for="(picture, key) in imageSlotProps.image.slice(0, 2)"
                    :key="key"
                    class="product-card__picture"
                    :src="picture"
                    :alt="imageSlotProps.title"
                    :width="imageSlotProps.imageWidth"
                    :height="imageSlotProps.imageHeight"
                  />
                </template>
                <nuxt-img
                  v-else
                  class="product-card__image"
                  :src="imageSlotProps.image"
                  :alt="imageSlotProps.title"
                  :width="imageSlotProps.imageWidth"
                  :height="imageSlotProps.imageHeight"
                />
              </template>
            </ProductCard>
          </label>
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Navigation } from 'swiper/modules'
import { encodeBase64 } from '~/plugins/base64'
import getIsDisabled from '~/utils/getIsDisabledProductVariantOption'
import { getSelectedProductVariantByUid } from '~/utils/productGetters'

const props = defineProps({
  option: {
    type: Object,
    required: true,
  },
  productConfiguration: {
    type: Object,
    required: true,
  },
  sku: {
    type: String,
    required: true,
  },
})

const emit = defineEmits(['updateProductConfiguration'])

const { setSoldOutFormData } = useUiState()
const productStore = useProductStore()
const { products: productsFromStore } = storeToRefs(useProductStore())

const selectedVariant = computed(
  () => props.option.values.find((value) => value.uid === props.productConfiguration[props.option.attribute_uid]) || {},
)

const cacheId = 'giftcardholder'
const holderCatUid = encodeBase64('496')
const products = ref([])
const selectedGiftcardHolderSku = ref(null)
const { getProductList, loading: productsLoading } = useProduct(cacheId)

const fetchProducts = async () => {
  const searchQuery = {
    pageSize: 20,
    customQuery: {
      products: 'products',
      metadata: {},
    },
    filter: {
      category_uid: {
        eq: holderCatUid,
      },
    },
    sort: {
      //
    },
  }
  const result = await getProductList(searchQuery)
  products.value = result?.items
}

const selectGiftcardHolder = (product) => {
  selectedGiftcardHolderSku.value = product?.sku
  productStore.giftcardHolder = product || null
}

const selectVariant = (value) => {
  const product = productsFromStore.value[props.sku]
  const configuration = { ...props.productConfiguration, [props.option.attribute_uid]: value.uid }
  const variantId = product.variants?.find((variant) =>
    variant?.attributes?.every((attr) => Object.values(configuration).includes(attr?.uid)),
  )?.product?.id

  if (value?.out_of_stock === 'OUT_OF_STOCK' && variantId) {
    return setSoldOutFormData({
      isModalVisible: true,
      optionLabel: value.label,
      productId: product.pid,
      variantId,
      type: 'soldOut',
    })
  }
  emit('updateProductConfiguration', { key: props.option.attribute_uid, value: value.uid })
}

const disabled = computed(() => getIsDisabled(props))

const selectedProductSku = (uid) =>
  getSelectedProductVariantByUid(props.sku, props.productConfiguration, props.option.attribute_uid, uid)?.sku
const selectedProductId = (uid) =>
  getSelectedProductVariantByUid(props.sku, props.productConfiguration, props.option.attribute_uid, uid)?.id

onMounted(async () => {
  const isEGiftcard = productsFromStore.value[props.sku]?.variants?.[0]?.product.type_id === 'virtual'
  if (!isEGiftcard) await fetchProducts()

  if (products.value.length) {
    selectGiftcardHolder(products.value[0])
  }
})
</script>

<style lang="scss" scoped>
html.theme--storefront {
  .product-variant--giftcard {
    display: flex;
    flex-direction: column;
  }

  .product-variant__product-giftcard-prices {
    display: flex;
    flex-flow: row wrap;
  }

  .product-variant__product-giftcards {
    display: grid;
    grid-template-columns: 1fr;

    .swiper-container {
      position: relative;
      grid-column: 1 / 3;

      :deep(.swiper-button-next),
      :deep(.swiper-button-prev) {
        top: 35%;
        background: #fff;
        width: 32px;
        height: 30px;
        color: #000;
        padding: 8px 0;

        &:after {
          font-size: 18px;
        }
      }

      :deep(.swiper-button-next) {
        right: 0;
      }

      :deep(.swiper-button-prev) {
        left: 0;
      }

      :deep(.swiper-button-disabled) {
        pointer-events: auto;
      }

      @include for-mobile {
        :deep(.swiper-button-next),
        :deep(.swiper-button-prev) {
          display: none;
        }
      }
    }
  }

  .product-giftcard-price {
    --button-height: var(--variant-button-height);
    --button-background: var(--gray-background-color);
    --button-color: var(--black-color);
    --button-padding: 0 var(--spacer-sm);
    --button-font-size: var(--font-size--xs);
    --button-font-weight: var(--font-weight--normal);
    border: 1px solid var(--gray-background-color);
    margin: 0 var(--spacer-xs) var(--spacer-xs) 0;
    width: 50px;

    &:hover,
    &:focus,
    &--selected {
      --button-background: var(--gray-background-color);
      --button-color: var(--black-color);
      border-color: var(--black-secondary-color);
    }

    &--out-of-stock {
      border: 1px solid var(--gray-background-color);
      --button-background: var(--white-color);
      --button-color: var(--gray-secondary-color);
      --button-box-shadow: none;
      --button-hover-box-shadow: none;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 88%;
        border-top: 1px solid var(--primary-color);
        transform: rotate(144.09deg) translate(16px, 11px);
      }

      &:hover,
      &:focus {
        border: 1px solid var(--gray-background-color);
        --button-background: var(--white-color);
        --button-color: var(--gray-background-color);
        --button-box-shadow: none;
        --button-hover-box-shadow: none;
      }
    }
  }

  .product__giftcard-holder {
    margin: var(--spacer-sm) 0 0;

    .swiper-container {
      @include for-mobile {
        width: calc(100% + var(--spacer-sm) + var(--spacer-sm));
        margin-left: -16px;
        padding-left: 16px;
      }
    }

    :deep(.swiper-slide) {
      @include for-mobile {
        flex-shrink: 1;
      }
    }

    :deep(.swiper-slide-transform) {
      @include for-mobile {
        min-width: 24vw;
      }
    }

    :deep(.product-card__wishlist-icon) {
      display: none;
    }

    &__label {
      display: block;
      position: relative;
      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid transparent;
        z-index: 2;
        pointer-events: none;
        border-radius: var(--spacer-2xs);
        transition: all 0.2s ease;
      }

      &:hover,
      &--selected {
        &:before {
          border-color: var(--black-color);
        }
      }

      .product-card {
        border-radius: var(--spacer-2xs);
        overflow: hidden;
        pointer-events: none;
      }

      &:hover {
        z-index: 2;
      }

      .sf-button {
        --button-height: auto;
      }
    }

    &__button {
      position: absolute;
      top: 50%;
      left: 0;
      width: 30px;
      height: 32px;
      margin-top: -16px;
      z-index: 1;
      background-color: #fff;
      border-radius: 0;
      transition:
        opacity 0.2s ease,
        visibility 0.2s ease;

      &.swiper-button-disabled {
        opacity: 0;
        visibility: hidden;
      }

      @include for-mobile {
        display: none;
      }

      &--prev {
        svg {
          transform: rotate(-90deg);
        }
      }

      &--next {
        left: auto;
        right: 0;

        svg {
          transform: rotate(90deg);
        }
      }
    }
  }
}
</style>

<style lang="scss">
html.theme--storefront {
  .variant-selection-modal {
    .product__giftcard-holder {
      .swiper-slide-transform {
        @include for-mobile {
          min-width: 19vw;
        }
      }
    }
  }
}
</style>
