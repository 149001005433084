<template>
  <div v-if="component" class="footer-cta-chat" :class="{ 'has-edit-button': isPreview }">
    <br-manage-content-button :content="document" />
    <h4>{{ content.title }}</h4>
    <p>{{ content.description }}</p>
    <button
      :id="content.identifier"
      class="sf-button sf-button--hollow action subscribe primary"
      title="chat met ons"
      type="submit"
      aria-label="chat met ons"
      @click="openChat"
    >
      <ChatIcon class="button__icon mobile-only" />
      <span>{{ content.buttonLabel }}</span>
    </button>
  </div>
</template>

<script setup lang="ts">
import ChatIcon from '~/storefront/assets/icons/chat.svg'

const { component, page } = defineProps({
  component: null,
  page: null,
})

const config = useRuntimeConfig()

const document = computed(() => {
  const models = component?.getModels()
  return models?.document && page?.getContent(models.document)
})
const content = computed(() => {
  return component?.model?.meta?.paramsInfo || {}
})
const isPreview = computed(() => {
  return page?.isPreview()
})
const openChat = () => {
  if (!window.document.querySelector('[data-kustomer-api-key]')) {
    window.addEventListener('kustomerLoaded', () => {
      window.Kustomer.start({}, () => {
        window.Kustomer.open()
      })
    })

    const script = window.document.createElement('script')
    script.src = 'https://cdn.kustomerapp.com/chat-web/widget.js'
    script.setAttribute('data-kustomer-api-key', config.public.kustomerApiKey)
    window.document.body.appendChild(script)
  } else {
    window.Kustomer.open()
  }
}

onMounted(() => {
  if (window.location.search.includes('email=chat')) {
    openChat()
  }
})
</script>

<style lang="scss">
html.theme--storefront {
  .footer-cta-chat {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include for-desktop {
      align-items: center;
      text-align: center;
    }

    .sf-button {
      margin-top: auto;
    }
  }
}
</style>
