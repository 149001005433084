<template>
  <div class="sf-rating" v-bind="$attrs.attr">
    <slot v-for="index in Math.ceil(finalScore)" name="icon-positive">
      <SfIcon
        :key="`p${index}`"
        class="sf-rating__icon"
        size="0.875rem"
        :icon="icon"
        :coverage="index === Math.ceil(finalScore) && finalScore % 1 > 0 ? finalScore % 1 : 1"
      />
    </slot>
    <slot v-for="index in parseInt(finalMax, 10) - Math.ceil(finalScore)" name="icon-negative">
      <SfIcon :key="`n${index}`" class="sf-rating__icon sf-rating__icon--negative" size="0.875rem" :icon="icon" />
    </slot>
  </div>
</template>

<script setup>
const props = defineProps({
  max: {
    type: Number,
    default: 5,
  },
  score: {
    type: Number,
    default: 1,
  },
  icon: {
    type: String,
    default: 'star',
  },
})

const finalScore =
  !props.score || props.score < 0 || (props.score > props.max && props.max > 0) || props.max <= 0 ? 0 : props.score

const finalMax = !props.max || props.max <= 0 ? 1 : props.max
</script>

<style lang="scss">
html.theme--storefront {
  @import './styles/atoms/SfRating.scss';
}
</style>
