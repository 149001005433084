<template>
  <div class="payment-providers-wrapper">
    <div v-if="paymentMethods" class="payment-providers__column payment-providers__column-left">
      <figure
        v-for="(image, index) in paymentMethods.imagesLeft"
        :key="index"
        :style="{ width: `${getCalculatedWidth(image)}px` }"
      >
        <img :src="getSvgOrThumbnailImageUrl(image)" :alt="getBynderImageName(image)" loading="lazy" />
      </figure>
    </div>
    <div v-if="paymentMethods" class="payment-providers__column payment-providers__column-right">
      <figure
        v-for="(image, index) in paymentMethods.imagesRight"
        :key="index"
        :style="{ width: `${getCalculatedWidth(image)}px` }"
      >
        <img :src="getSvgOrThumbnailImageUrl(image)" :alt="getBynderImageName(image)" loading="lazy" />
      </figure>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getBynderImageName, getCalculatedWidth, getSvgOrThumbnailImageUrl } from '~/utils/bynderImage'
const { locale } = useI18n()

const { data } = await useFetch('/api/bloomreach/document', {
  query: {
    path: '/footer/footerpaymentmethods',
    locale: locale.value,
  },
  key: `footerpaymentmethods`,
  getCachedData: (key, NuxtApp) => NuxtApp.payload.data[key],
})

const paymentMethods = computed(() => data.value?.content[data.value?.document?.$ref.substring(9)].data)
</script>

<style lang="scss">
html.theme--storefront {
  .payment-providers-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @include for-desktop {
      flex-flow: row wrap;
    }

    @include for-mobile {
      padding-top: var(--spacer-sm);
    }

    figure {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin: 5px 10px;
      max-height: 28px;

      img {
        width: auto;
        height: auto;
        max-width: 100%;
      }

      @include for-desktop {
        padding: 0;
        margin: 0 var(--spacer-sm) 0 0;
        opacity: 0.75;
      }

      &:last-of-type {
        margin-right: 0;

        @include for-desktop {
          margin-right: 0;
        }
      }
    }
  }

  .payment-providers__column {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: var(--spacer-xs);
    margin-left: -10px;
    margin-right: -10px;

    @include for-desktop {
      padding-right: var(--spacer-sm);
      margin: 0;
    }

    &-right {
      @include for-desktop {
        padding-left: var(--spacer-sm);
        padding-right: 0;

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(0, -50%);
          width: 1px;
          height: var(--spacer-md);
          background: var(--black-color);
        }
      }
    }
  }
}
</style>
