<template>
  <div class="mobile-nav-ctas">
    <SfLoader v-if="loading" />
    <ul>
      <li v-for="item in items" :key="item.id">
        <SfLink :link="localePath(item.headerLink)" @click.native="closeMobileMenu">
          <div v-dompurify-html:svg="item.icon" />
          <span>{{ item.title }}</span>
        </SfLink>
      </li>
    </ul>
    <hr v-if="items && items.length" />
  </div>
</template>

<script setup lang="ts">
defineProps({
  loading: { type: Boolean, default: false },
  items: { type: Array, default: [] },
})

const { toggleMobileMenu } = useUiState()

const closeMobileMenu = () => {
  toggleMobileMenu()
}

const localePath = useLocalePath()
</script>

<style lang="scss">
html.theme--storefront {
  .mobile-nav-ctas {
    position: relative;

    .sf-loader {
      height: 40px;
    }

    hr {
      border-width: 1px 0 0 0;
      border-color: var(--black-color);
      margin: var(--spacer-xs) 0 var(--spacer-lg);
    }

    ul {
      list-style-type: none;

      li {
        .sf-link {
          --link-font-family: var(--font-family--primary);
          --link-font-weight: var(--font-weight--semibold);
          --link-text-decoration: none;
          display: flex;
          flex-flow: nowrap;
          align-items: center;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding: var(--spacer-sm) var(--spacer-sm);
          line-height: var(--spacer-sm);

          svg {
            display: block;
            margin-right: var(--spacer-sm);
          }

          span {
            flex: 1;
            line-height: var(--spacer-md);
          }
        }
      }
    }
  }
}
</style>
