<template>
  <div
    class="coupon-code"
    :class="{ 'coupon-code--open': showCoupon, 'coupon-code--has-error': errorMessage && showCoupon }"
  >
    <SfButton class="coupon-code__button sf-button--full-width sf-button--pure" @click="showCoupon = !showCoupon">
      {{ $t('Promo code') }}
      <SfChevron class="coupon-code__chevron" :class="{ 'coupon-code__chevron--open': showCoupon }" />
    </SfButton>

    <form class="coupon-code__form" method="post" @submit.prevent="handleCoupon">
      <SfInput
        v-model="couponCode"
        class="coupon-code__input"
        name="couponCode"
        :valid="Boolean(couponIsApplied) || !error.applyCoupon"
        :error-message="errorMessage && showCoupon ? errorMessage : null"
        :disabled="loading || Boolean(couponIsApplied)"
        :label="Boolean(couponIsApplied) ? $t('Promo code') : $t('Enter promo code')"
      />
      <SfButton v-if="!couponIsApplied" class="coupon-code__apply-button" :disabled="isApplyDisabled">
        <ArrowRightIcon />
      </SfButton>
      <SfButton v-else class="coupon-code__remove-button">
        <TimesIcon />
      </SfButton>
      <SfLoader class="coupon-code__loader" :loading="loading" />
    </form>
    <p v-if="isGiftCardCode && showCoupon" class="disclaimer">
      {{ $t('You can enter gift card codes when you proceed to checkout') }}
    </p>
  </div>
</template>

<script setup>
import cartGetters from '~/utils/getters/magentoCartGetters'
import TimesIcon from '~/storefront/assets/icons/times.svg'
import ArrowRightIcon from '~/storefront/assets/icons/arrow-right.svg'

const { cart, applyCoupon, removeCoupon, error, loading } = useCart()
const couponCode = ref('')
const couponIsApplied = computed(() => cartGetters.getAppliedCoupon(cart.value)?.code)

const setCartCoupon = () => {
  couponCode.value = couponIsApplied.value
}

const handleCoupon = async () => {
  await (couponIsApplied.value
    ? removeCoupon({ currentCart: cart.value })
    : applyCoupon({ couponCode: couponCode.value }))
}

const showCoupon = ref(false)

// Giftcards always start with 6064365921
const isGiftCardCode = computed(() => couponCode.value?.startsWith('6064365921'))
const errorMessage = computed(() => error.value?.applyCoupon?.message)

onMounted(setCartCoupon)
watch(couponIsApplied, setCartCoupon)

const isApplyDisabled = computed(() => !couponCode.value || isGiftCardCode.value || null)
</script>

<style lang="scss" scoped>
html.theme--storefront {
  .coupon-code {
    --button-height: 48px;
    --input-height: 48px;
    margin-bottom: -56px;
    transition: all 0.25s ease-in-out;

    &--open {
      margin-bottom: 0;
    }

    &__button {
      justify-content: space-between !important;
      font-weight: normal !important;
      font-size: var(--font-size-sm) !important;
    }

    &__chevron {
      transition: transform 0.25s ease-in-out;

      &--open {
        transform: rotate(180deg);
      }
    }

    &__form {
      position: relative;
      display: flex;
      gap: var(--spacer-sm);
      transition: all 0.25s ease-in-out;
      margin-bottom: var(--spacer-xs);
    }

    &:not(.coupon-code--open) .coupon-code__form {
      opacity: 0;
    }

    &__input {
      margin: 0;
      height: auto;
      width: 100% !important;
    }

    :deep(.sf-input__wrapper) {
      height: var(--input-height);
    }

    :deep(.sf-input__error-message) {
      min-height: auto;
    }

    &__apply-button,
    &__remove-button {
      height: 48px !important;
      flex: 0 0 48px !important;
      padding: 0 !important;
    }

    &__loader {
      pointer-events: none;
      position: absolute !important;
      top: 0;
      right: 0;
      bottom: 0;
      width: 48px;

      .sf-loader__overlay {
        background: none;
      }
    }

    .disclaimer {
      margin-top: var(--spacer-2xs);
      font-size: var(--font-size-13);
      color: var(--gray-secondary-color);
    }
  }
}
</style>
