<template>
  <div v-if="option" class="product-variant product-variant--size">
    <div class="product-variant__heading">
      <SfProperty :name="$t('Size')" :value="selectedVariant.label" />

      <div class="product-variant__size-chart-button">
        (<SfButton class="sf-button--text" @click="toggleIsSizeChartSidebarOpen"> {{ $t('Size chart') }} </SfButton>)
      </div>
    </div>
    <div class="product-variant__product-sizes">
      <SfButton
        v-for="size in option.values"
        :key="size.uid"
        class="product-size"
        :class="{
          'product-size--selected': selectedVariant.uid === size.uid,
          'product-size--out-of-stock': getDisabledForOption(size.out_of_stock) || disabled,
        }"
        :data-sku="selectedProductSku(size.uid)"
        :data-id="selectedProductId(size.uid)"
        :data-label="size.label"
        :data-attribute_code="option.attribute_code"
        @click="selectVariant(size)"
      >
        {{ size.label }}
        <ProductStockWarningIndicator class="product-size__stock-indication" v-if="type === 'cart' && size.stock_indication === 1"/>
        <BellIcon v-if="getDisabledForOption(size.out_of_stock)" />
      </SfButton>
    </div>
    <SfNotification
      class="no-variant-error"
      :visible="errorNoVariantSelected"
      :message="$t(errorNotificationMessage)"
      :persistent="false"
    />
  </div>
</template>

<script setup lang="ts">
import BellIcon from '~/storefront/assets/icons/bell.svg'
import getIsDisabled from '~/utils/getIsDisabledProductVariantOption'
import { getSelectedProductVariantByUid } from '~/utils/productGetters'

const props = defineProps({
  option: {
    type: Object,
    required: true,
  },
  optionsLength: {
    type: Number,
    required: true,
  },
  parentUid: {
    type: String,
  },
  productConfiguration: {
    type: Object,
    required: true,
  },
  type: {
    type: String as PropType<'wishlist' | 'cart'>,
    required: true,
  },
  sku: {
    type: String,
    required: true,
  },
})

const emit = defineEmits(['updateProductConfiguration'])

const { products } = storeToRefs(useProductStore())
const { setSoldOutFormData } = useUiState()

const errorNoVariantSelected = ref(false)

const selectedVariant = computed(
  () => props.option.values.find((value) => value.uid === props.productConfiguration[props.option.attribute_uid]) || {},
)

const { toggleIsSizeChartSidebarOpen } = useUiState()

const disabled = computed(() => getIsDisabled(props))

const selectVariant = (value) => {
  if (disabled.value) return showErrorNotification()

  const product = products.value[props.sku]
  const configuration = { ...props.productConfiguration, [props.option.attribute_uid]: value.uid }
  const variantId = product.variants?.find((variant) =>
    variant?.attributes?.every((attr) => Object.values(configuration).includes(attr?.uid)),
  )?.product?.id

  if (value?.out_of_stock === 'OUT_OF_STOCK' && variantId && props.type === 'cart') {
    return setSoldOutFormData({
      isModalVisible: true,
      optionLabel: value.label,
      productId: product.pid,
      variantId,
      type: 'soldOut',
    })
  }

  emit('updateProductConfiguration', { key: props.option.attribute_uid, value: value.uid })
}

const getDisabledForOption = (out_of_stock_status) =>
  out_of_stock_status === 'OUT_OF_STOCK' && props.type !== 'wishlist'

const selectedProductSku = (uid) =>
  getSelectedProductVariantByUid(props.sku, props.productConfiguration, props.option.attribute_uid, uid)?.sku
const selectedProductId = (uid) =>
  getSelectedProductVariantByUid(props.sku, props.productConfiguration, props.option.attribute_uid, uid)?.id

watch(
  () => props.productConfiguration[props.parentUid],
  //reset selected variant whenever the parent is changed
  (prev, next) => {
    if (prev !== next) props.productConfiguration[props.option.attribute_uid] = null
  },
)

const showErrorNotification = () => {
  errorNoVariantSelected.value = true
  return setTimeout(() => (errorNoVariantSelected.value = false), 3000)
}

const errorNotificationMessage = ref(
  props.optionsLength > 2 ? 'Please select the option above first' : 'Please select a color first',
)
</script>

<style lang="scss" scoped>
html.theme--storefront {
  .product-variant__product-sizes {
    display: flex;
    flex-flow: row wrap;

    .is-disabled--button {
      pointer-events: all;
    }
  }
  .product-size {
    --button-height: var(--variant-button-height);
    --button-background: var(--gray-background-color);
    --button-color: var(--black-color);
    --button-padding: 0 var(--spacer-sm);
    --button-font-size: var(--font-size--xs);
    --button-font-weight: var(--font-weight--normal);
    border: 1px solid var(--gray-background-color);
    margin: 0 var(--spacer-xs) 0 0;
    width: 50px;

    &:hover,
    &:focus,
    &--selected {
      --button-background: var(--gray-background-color);
      --button-color: var(--black-color);
      border-color: var(--black-secondary-color);
    }

    &__stock-indication{
      position: absolute;
      right: -6px;
      top: -6px;
      width: 12px;
      height: 12px;
      border: 2px solid white;
    }

    &--out-of-stock {
      border: 1px solid var(--gray-background-color);
      --button-background: var(--white-color);
      --button-color: var(--gray-secondary-color);
      --button-box-shadow: none;
      --button-hover-box-shadow: none;

      &:hover,
      &:focus {
        border: 1px solid #c3c3c3;
        --button-background: var(--white-color);
        --button-color: var(--gray-secondary-color);
        --button-box-shadow: none;
        --button-hover-box-shadow: none;
      }

      svg {
        position: absolute;
        top: -3px;
        right: -4px;
        color: var(--black-color);
      }
    }
  }

  .product-variant__size-chart-button,
  .product-variant__size-chart-button button {
    font-size: var(--font-size--xs);
  }
}
</style>
