<template>
  <div class="grid-banner" :class="`grid-banner--${columns}-columns`">
    <nuxt-link v-for="banner in banners" class="grid-banner-item" :to="formatUrl(banner.ctaLink || banner.ctaUrl)">
      <br-manage-content-button :content="banner.content" />
      <div class="grid-banner-item__text">
        <h2 v-html="formatTitle(banner.bannerTitle)" />
        <p v-if="banner.bannerDescription" v-html="banner.bannerDescription" />
      </div>
      <div class="grid-banner-item__image">
        <BynderAsset :data="banner.asset" size="(min-width: 1024px) 84px, 56px" :ratio="1" />
      </div>
    </nuxt-link>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  banners: {
    type: Array as PropType<Record<string, any>[]>,
    required: true,
  },
})

const columns = computed(() => ((props.banners?.length || 0) % 4 === 0 ? 4 : 3))
const formatTitle = (title: string) => title.replace(/\{(.*)\}/, '<span class="sf-badge sf-badge--tiny">$1</span>')
</script>

<style lang="scss">
html.theme--storefront {
  @import '~/storefront/assets/css/helpers';

  .grid-banner {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--spacer-xs);
    margin-bottom: var(--spacer-xl);

    @media screen and (min-width: 641px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    @include for-desktop {
      grid-template-columns: repeat(3, 1fr);
      gap: var(--spacer-md);
      margin-bottom: var(--spacer-3rem);
    }

    &--4-columns {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      @include for-desktop {
        grid-template-columns: repeat(4, 1fr);
        gap: var(--spacer-md);
        margin-bottom: var(--spacer-3rem);
      }

      .grid-banner-item {
        @include for-mobile {
          align-items: center;
          justify-content: flex-start;
          padding: var(--spacer-xs) var(--spacer-sm);

          .grid-banner-item__text {
            text-align: left;

            h2 {
              font-size: var(--font-size--sm);
            }
          }

          .grid-banner-item__image {
            --grid-banner-item-image-size: 42px;
            width: var(--grid-banner-item-image-size);
          }
        }

        @include for-desktop {
          padding: var(--spacer-sm);
        }

        &__text {
          h2 {
            font-size: var(--font-size--xs);

            @include for-desktop {
              font-size: var(--font-size--base);
            }
          }
        }

        &__image {
          flex: 0 0 var(--grid-banner-item-image-size, 28px);
          height: var(--grid-banner-item-image-size, 28px);

          @include for-desktop {
            --grid-banner-item-image-size: 42px;
          }
        }
      }
    }
  }

  .grid-banner-item {
    position: relative;
    display: flex;
    background-color: var(--gray-background-color);
    flex-flow: row wrap;
    border-radius: var(--spacer-2xs);
    padding: var(--spacer-2xs) var(--spacer-sm);
    gap: var(--spacer-2xs);
    color: var(--text-color);

    @include for-desktop {
      padding: var(--spacer-sm) var(--spacer-base);
    }

    &:hover {
      color: var(--primary-color);
    }

    &__text {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-sizing: border-box;
      text-align: left;
      word-break: break-word;
      gap: var(--spacer-2xs);

      @include for-desktop {
        gap: var(--spacer-xs);
      }

      h2 {
        font-family: var(--font-family--primary);
        font-size: var(--font-size-md);
        font-weight: var(--font-weight--semibold);
        line-height: 1;
        margin: 0;
      }

      p {
        font-size: var(--font-size-xs);
        line-height: 1.375;
        margin: 0;
      }

      .sf-badge {
        &--tiny {
          padding: 4px 6px;
          line-height: 1.125;
          vertical-align: text-bottom;
          background-color: var(--primary-color);
          --badge-font-size: var(--font-size-10);

          &:last-child {
            margin-left: 4px;
          }
        }
      }
    }

    &__image {
      flex: 0 0 var(--grid-banner-item-image-size, 56px);
      height: var(--grid-banner-item-image-size, 56px);

      @include for-desktop {
        --grid-banner-item-image-size: 84px;
      }
    }
  }
}
</style>
