<template>
  <div v-if="items && items.length" class="accordion-component" :class="isPreview ? 'has-edit-button' : ''">
    <br-manage-content-button :content="computedDocument" />
    <SfHeading
      v-if="title"
      class="sf-accordion__heading"
      :title="title"
      :level="2"
      :style="backgroundColor ? { backgroundColor: backgroundColor } : {}"
    />
    <SfAccordion multiple showChevron transition="sf-collapse-top">
      <SfAccordionItem v-for="(item, index) in items" :key="index" :header="item.question">
        <div class="rich-text">
          <div v-html="item.answer.value" />
        </div>
      </SfAccordionItem>
    </SfAccordion>
  </div>
</template>

<script setup>
const props = defineProps({
  component: null,
  document: null,
  page: null,
})

const computedDocument = computed(() => {
  if (props.document) return props.document

  const document = props.component && props.component.getModels()?.document
  return props.page.getContent(document)
})

const isPreview = computed(() => props.page?.isPreview())
const data = computed(() => computedDocument.value?.getData() || {})
const title = computed(() => data.value?.title)
const items = computed(() => {
  const faqItems = data.value?.faqItems
  return faqItems?.map((item) => getItemContent(item)).filter((item) => item && item.answer)
})
const backgroundColor = computed(() => data.value?.backgroundColor)

const getItemContent = (ref) => props.page.getContent(ref)?.getData() || {}
</script>

<style lang="scss">
html.theme--storefront {
  @include for-mobile {
    .hst-container-item:has(.accordion-component) {
      grid-column: 1 / -1;
    }
  }

  .sf-accordion {
    --accordion-item-content-font-weight: var(--font-weight--normal);
    margin-bottom: var(--mj-component-margin-bottom);
  }

  @include for-desktop {
    .sf-accordion-item {
      transform: none;

      @include for-desktop {
        --accordion-item-content-border-width: 0 0 1px;
      }
    }
  }

  .sf-accordion-item__header {
    --accordion-item-header-font-family: var(--font-family--primary);
    --accordion-item-header-font-weight: var(--font-weight--normal);
    --accordion-item-header-font-size: 1rem;
    --accordion-item-header-padding: 12px 16px;
    --accordion-item-header-border-color: var(--gray-dark-accent-color);
    --accordion-item-header-border-width: 0 0 1px;
    --accordion-item-header-font-line-height: var(--global-line-height);
    --button-height: auto;
    --button-border-radius: 0;
    --chevron-size: 16px;
    word-break: break-word;
    overflow: hidden;

    &.sf-button {
      --button-height: auto;

      &:hover,
      &:focus {
        box-shadow: none;
      }
    }

    @include for-desktop {
      --accordion-item-header-padding: 16px 16px 16px 24px;
      --accordion-item-header-font-size: 1rem;
      --button-height: auto;

      &:hover {
        background-color: var(--pink-background-color);
        --button-text-decoration: underline;

        .sf-accordion-item__chevron {
          background-color: var(--black-color);

          &:before {
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEyIDEuMzgzMzNMMTAuNzQ1OCA0LjY5NzEzZS0wN0w2IDUuMjM1MjJMMS4yNTQyMyA1LjQ4MjRlLTA4TC02LjA0NjcyZS0wOCAxLjM4MzMzTDYuMDAwMDIgOEwxMiAxLjM4MzMzWiIgZmlsbD0iI0ZBRTVFQSIvPjwvc3ZnPg==');
          }
        }
      }
    }

    &.is-open {
      --accordion-item-header-border-width: 0 0 1px;
      --accordion-item-header-color: var(--black-color);
      --chevron-color: var(--black-color);

      .sf-accordion-item__chevron {
        transform: rotate(-180deg);
      }
    }
  }

  .sf-accordion-item__chevron {
    --chevron-size: 26px;
    border-radius: 50%;
    background-color: var(--gray-background-color);
    margin-left: var(--spacer-2xs);
    transition: transform 0.2s ease;
    outline: none;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('data:image/svg+xml;utf8,<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 1.38333L10.7458 4.69713e-07L6 5.23522L1.25423 5.4824e-08L-6.04672e-08 1.38333L6.00002 8L12 1.38333Z" fill="black"/></svg>');
      background-size: calc(var(--spacer-base) / 2);
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }

    .sf-chevron__bar {
      display: none;
    }
  }

  .sf-accordion-item__content {
    --accordion-item-content-border-width: 1px 0;
    --accordion-item-content-padding: 10px 16px 10px;
    --accordion-item-content-font-size: 16px;
    background-color: var(--gray-background-color);
    margin-bottom: var(--spacer-sm);

    @include for-desktop {
      --accordion-item-content-padding: 10px 24px 10px;
      --accordion-item-content-font-size: 18px;
      margin-bottom: 0;
    }

    .rich-text {
      margin-bottom: 0;
      padding-left: 0;
      padding-right: 0;
      word-break: break-word;

      p,
      li {
        @include for-desktop {
          line-height: var(--global-line-height);
        }
      }

      @media (min-width: 1300px) {
        margin-left: 0;
        margin-right: 0;
      }

      > * {
        margin-bottom: 0;
        max-width: unset;
      }
    }
  }
}
</style>
