<template>
    <div class="stock-warning-message">
        {{ $t('Almost sold out shop now') }}
    </div>
</template>
<style lang="scss">
html.theme--storefront {
    .stock-warning-message {
        width: 100%;
        background-color: var(--red-background-color);
        margin-bottom: var(--spacer-sm);
        font-size: var(--font-size-xs);
        padding: var(--spacer-xs);
    }
}
</style>