<template>
  <div class="footer-top">
    <br-component component="footer">
      <template v-slot:default="{ component }">
        <br-component v-for="(child, key) in component.getChildren()" :key="key" :component="child" />
      </template>
    </br-component>
  </div>
</template>

<script></script>

<style lang="scss">
html.theme--storefront {
  .page-footer .footer-top {
    border-top: 1px solid var(--black-color);
    background-color: var(--gray-background-color);
    padding: var(--spacer-2xs) 0 var(--spacer-xs);

    @include for-desktop {
      padding: var(--spacer-xl) 0 var(--spacer-3rem);
      text-align: left;
    }

    h4 {
      margin-bottom: var(--spacer-xs);
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--black-color);
      border-radius: 4px;
      min-height: 48px;
      width: 100%;
      background: none;
      color: var(--black-color);
      font-family: var(--font-family--primary);
      font-weight: var(--font-weight--semibold);
      font-size: var(--font-size--base);
      cursor: pointer;
      transition: all 0.2s;

      @include for-desktop {
        margin: 0 auto;
        min-height: 56px;
        max-width: 200px;
        font-size: var(--font-size--lg);
      }

      &:hover {
        background: var(--black-color);
        color: var(--white-color);
      }

      &__icon {
        margin: 0 12px 0 0;
      }
    }

    .hst-container {
      @include for-desktop {
        grid-gap: var(--spacer-md);
      }
    }

    .hst-container-item {
      position: relative;
      display: flex;
      width: 100%;

      &:last-child {
        border: none;
      }

      @include for-mobile {
        border-bottom: 1px solid var(--gray-dark-accent-color);
        padding: var(--spacer-base) 0 var(--spacer-md);
      }

      @include for-desktop {
        grid-column: span 4;

        &:first-child,
        &:nth-child(3n + 1) {
          grid-column: 2 / span 4;
        }

        &:after {
          content: '';
          position: absolute;
          right: -0.75rem;
          top: 0;
          bottom: 0;
          border-right: 1px solid var(--gray-dark-accent-color);
        }

        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
  }
}
</style>
