<template>
  <div class="swiper-slide">
    <div class="swiper-slide-transform">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss">
html.theme--storefront {
  .swiper-css-mode .swiper-slide {
    display: flex;
    height: 100%;
    width: auto;

    &:not(:first-child) {
      padding-left: var(--swiper-space-between);
    }

    &:first-child {
      .swiper-slide-transform {
        padding-left: var(--swiper-offset-before); // Offset fix for css mode
      }
    }

    &:last-child {
      .swiper-slide-transform {
        padding-right: var(--swiper-offset-after); // Offset fix for css mode
      }
    }
  }

  .swiper-slide-transform {
    height: inherit;
    width: inherit;
    text-align: center;
  }
}
</style>
