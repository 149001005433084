<template>
  <div class="image-text-cards" data-columns="2">
    <CardItem
      v-for="(banner, index) in props.banners"
      :key="index"
      class="sf-card--article-latest"
      :title="banner.bannerTitle"
      :description="banner.bannerDescription"
      :category="getCategory(banner)"
      :image="banner.bannerImage"
      :asset="banner.asset"
      :link="formatUrl(banner.ctaUrl || banner.ctaLink)"
    />
  </div>
</template>

<script setup lang="ts">
import CardItem from '~/storefront/components/Bloomreach/Banner/CardItem.vue'

interface Props {
  banners: any[]
}

const props = defineProps<Props>()
const getCategory = (banner) =>
  banner?.hideCategory ? '' : banner.categoryManually ? banner.categoryManually : banner.category || ''
</script>
