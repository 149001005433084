<template>
  <div class="form-birthday exponea-form__horizontal">
    <div class="exponea-form__element" :name="`${name}.day`">
      <SfSelect
        class="exponea-form__element form__element--half form__element--half-even form__select sf-select--underlined"
        :label="$t('Day')"
        :name="`${name}.day`"
        :options="birthDays"
        :value="inputValueDay"
        @change="handleChangeDay"
      />
    </div>
    <div class="exponea-form__element" :name="`${name}.month`">
      <SfSelect
        class="exponea-form__element form__element--half form__element--half-even form__select sf-select--underlined"
        :label="$t('Month')"
        :name="`${name}.month`"
        :options="birthMonths"
        :value="inputValueMonth"
        @change="handleChangeMonth"
      />
    </div>
    <div class="exponea-form__element" :name="`${name}.year`">
      <SfSelect
        class="exponea-form__element form__element--half form__element--half-even form__select sf-select--underlined"
        :label="$t('Year')"
        :name="`${name}.year`"
        :options="birthYears()"
        :value="inputValueYear"
        @change="handleChangeYear"
      />
    </div>
    <p v-for="error in errors" class="error-message">
      {{ error }}
    </p>
  </div>
</template>

<script setup>
const { locale } = useI18n()

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  value: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
})

const {
  value: inputValueDay,
  errorMessageDay,
  handleChange: handleChangeDay,
} = useField(`${props.name}.day`, undefined, {
  initialValue: props.value.value,
})

const {
  value: inputValueMonth,
  errorMessageMonth,
  handleChange: handleChangeMonth,
} = useField(`${props.name}.month`, undefined, {
  initialValue: props.value.value,
})

const {
  value: inputValueYear,
  errorMessageYear,
  handleChange: handleChangeYear,
} = useField(`${props.name}.year`, undefined, {
  initialValue: props.value.value,
})

const birthDays = [...Array(31).keys()].map((x) => {
  return { label: x + 1, value: x + 1 }
})

const birthMonths = [...Array(12).keys()].map((x) => {
  const date = new Date(2022, x, 1).toLocaleString(locale.value, { month: 'long' })
  return { label: date, value: x + 1 }
})

const birthYears = () => {
  const years = []
  const min = new Date().getFullYear()
  const max = min - 80

  for (let i = max; i <= min; i++) {
    years.push(i)
  }
  return years.reverse().map((x) => {
    return { label: x, value: x }
  })
}

const errors = computed(() => [errorMessageDay, errorMessageMonth, errorMessageYear].filter((error) => error))
</script>

<style lang="scss" scoped>
html.theme--storefront {
  .form-birthday {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: var(--spacer-lg);
  }
}
</style>
