<template>
  <div class="sf-bar">
    <div>
      <slot name="back">
        <SfButton
          class="sf-button--pure sf-bar__icon"
          :class="{ 'display-none': !back }"
          aria-label="back"
          type="button"
          @click="$emit('click:back')"
        >
          <SfIcon icon="chevron_left" size="0.875rem" />
        </SfButton>
      </slot>
    </div>
    <div>
      <slot name="title" v-bind="{ title }">
        <div class="sf-bar__title">{{ title }}</div>
      </slot>
    </div>
    <div>
      <slot name="close">
        <SfButton
          class="sf-button--pure sf-bar__icon"
          :class="{ 'display-none': !close }"
          aria-label="close"
          type="button"
          @click="$emit('click:close')"
        >
          <SfIcon icon="cross" size="14px" />
        </SfButton>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SfBar',
  props: {
    title: {
      type: String,
      default: '',
    },
    back: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
html.theme--storefront {
  @import './styles/molecules/SfBar.scss';
}
</style>
