<template>
  <SfModal
    class="sf-modal product__sold-out-form"
    :visible="soldOutForm.isModalVisible"
    @close="setSoldOutFormData({ isModalVisible: false, optionLabel: null })"
  >
    <ProductSoldOutForm @toggleSoldOutForm="setSoldOutFormData({ isModalVisible: false, optionLabel: null })" />
  </SfModal>
</template>
<script setup lang="ts">
const { soldOutForm } = storeToRefs(useUiState())
const { setSoldOutFormData } = useUiState()
</script>
<style lang="scss" scoped>
html.theme--storefront {
  .product {
    &__sold-out-form {
      position: relative;
      z-index: 4;
    }
  }
}
</style>
