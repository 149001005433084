<template>
  <div>
    <div v-if="addingProductToCart" class="variant-selection-modal__content variant-selection-modal__content--adding">
      <SfLoader />
      <h4>
        {{ product.name }}
      </h4>
      <p>
        {{ $t('Adding product to cart...') }}
      </p>
    </div>
    <div v-show="!addingProductToCart" class="variant-selection-modal__content">
      <h4>{{ product.name }}</h4>
      <ProductVariantMatrix ref="productVariantMatrix" type="cart" :sku="sku" :showProductRelatedColors="false" />
      <ProductActions
        ref="productActions"
        :product="product"
        :productConfiguration="productConfiguration"
        :isAtcModal="true"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
const { products: storeProducts, activeProductSku } = storeToRefs(useProductStore())
const { showAtcModal } = storeToRefs(useCartStore())

const product = computed(() => {
  return storeProducts.value[activeProductSku.value]
})
const sku = computed(() => product.value?.sku)

const productVariantMatrix = ref()
const productConfiguration = computed(() => productVariantMatrix.value?.productConfiguration || {})

const productActions = ref()
const addingProductToCart = computed(() => productActions.value?.isLoadingAddToCart)

const addToCartImediately = computed(
  () =>
    showAtcModal.value &&
    (product.value?.configurable_options?.every((option) => option.values.length <= 1) ||
      product.value?.__typename === 'SimpleProduct'),
)

onMounted(() => {
  // use nextTick here to allow ProductActions to mount and the computeds to run
  if (addToCartImediately.value) nextTick(productActions.value?.addItemToCart)
})
</script>

<style lang="scss" scoped>
html.theme--storefront {
  .variant-selection-modal {
    .sf-loader {
      min-height: var(--spacer-2xl);

      &__overlay {
        background: none;
      }
    }

    .sf-modal__container {
      --modal-index: 21;
    }

    .sf-modal__overlay {
      --overlay-z-index: 20;
    }

    &__content {
      min-height: var(--spacer-2xl);
      display: flex;
      flex-direction: column;
      gap: var(--spacer-sm);

      &--adding {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        .sf-loader {
          min-height: var(--spacer-2xl);
        }

        p {
          margin-top: var(--spacer-10);
        }
      }
    }

    h4 {
      margin-bottom: 0;
      max-width: 92%;
      line-height: 126%;
    }

    p {
      margin-top: 0;
    }
  }
}
</style>
