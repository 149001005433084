export const useGiftWrapProducts = () => {
  const { locale } = useI18n()
  const loading = ref<boolean | null>(null)
  const products = ref<[] | null>(null)

  const fetchProducts = async () => {
    return $fetch(`/api/giftWrapProducts?locale=${locale.value}`)
      .catch(() => ({
        success: false,
        data: [],
      }))
  }

  const load = async () => {
    loading.value = true
    products.value = await fetchProducts()
    loading.value = false
  }

  return {
    load,
    loading,
    products,
  }
}

export default useGiftWrapProducts
