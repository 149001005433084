<template>
  <div v-if="component" :key="tiktokUrl" :class="['tiktok-component', isPreview && 'has-edit-button']">
    <h2 v-if="title" v-html="title" />
    <div class="tik-tok">
      <SfLoader v-if="!htmlContent" />
      <HTMLContent v-else :id="tiktokUrl" :content="htmlContent" />
    </div>
    <Script
      v-if="htmlContent"
      async="true"
      defer="true"
      src="https://www.tiktok.com/embed.js"
      type="application/javascript"
    ></Script>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  component: null,
  page: null,
})

const params = computed(() => props.component?.model?.meta?.paramsInfo)
const isPreview = computed(() => props.page?.isPreview())
const title = computed(() => params.value?.title || false)
const tiktokUrl = computed(() => params.value?.url || false)

const htmlContent = ref('')
const url = computed(() => props?.component?.model?.meta?.paramsInfo?.url)

onMounted(async () => {
  const data = await $fetch('https://www.tiktok.com/oembed?url=' + url.value)
  htmlContent.value = data?.html
})
</script>

<style lang="scss">
html.theme--storefront {
  .tiktok-component {
    text-align: center;
  }

  .tik-tok {
    position: relative;
    display: flex;
    border-radius: 8px;
    margin: 0 auto;
    height: 757px;
    width: 323px;
    background: var(--c-light);

    blockquote {
      margin: 0;
      padding: 0;
    }

    .sf-loader__overlay {
      background: none;
    }
  }
}
</style>
