<template>
  <ul v-if="menu" class="top-nav-menu" :class="{ 'has-edit-button': page.isPreview() }">
    <br-manage-menu-button :menu="menu" />
    <TopNavMenuItem v-for="(item, index) in menu.getItems()" :key="index" :index="index + 1" :item="item" :level="1" />
  </ul>
</template>

<script lang="ts" setup>
import type { Component as BrComponent, Page } from '@bloomreach/spa-sdk'

const props = defineProps({
  component: {
    type: Object as () => BrComponent,
    required: true,
  },
  page: {
    type: Object as () => Page,
    required: true,
  },
})

const { menu } = useMenu(props)
</script>

<style lang="scss">
html.theme--storefront {
  .top-nav-menu {
    display: flex;
    list-style-type: none;
    align-items: center;

    & > li {
      position: relative;
      line-height: var(--xs-line-height);
      font-weight: var(--font-weight--semibold);
      width: 100%;

      &:hover {
        > a {
          color: var(--secondary-color);
        }

        > ul {
          visibility: visible;
          opacity: 1;
        }
      }

      & > .nav-link,
      & > a {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--white-color);
        padding: var(--spacer-xs) 0;
        min-height: 48px;
        width: 100%;
        transition: color 0.25s ease;
        text-align: center;
      }
    }
  }
}
</style>
