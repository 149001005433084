<template>
  <div class="search__products">
    <h5 v-if="showNoProductsHeader" class="h5 search__header height-100">
      {{ $t('No product suggestions found') }}
    </h5>
    <h5 v-if="products?.length" class="h5 search__header">
      {{ $t('Product suggestions') }}
    </h5>

    <div class="search__products-list">
      <ProductCard
        v-for="product in products"
        :key="product.sku"
        class="result-card"
        :product="product"
        :product-image-first="true"
      />

      <template v-for="item in customContentSearchResults" :key="item.url">
        <BloomreachContentSearchResult :url="item.url" :imageUrl="item.imageUrl" :title="item.title" />
      </template>
    </div>

    <div v-if="products?.length" class="show-all">
      <SfLink class="sf-button sf-button--outline" @click="handleShowSearchResults(query)">
        {{ $t('Show all results') }}
      </SfLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  products: {
    type: Object,
    default: () => ({}),
  },
  customContentSearchResults: {
    type: Object,
    default: () => ({}),
  },
  query: {
    type: String,
    default: '',
  },
  handleShowSearchResults: {
    type: Function,
    default: () => {},
  },
})

const showNoProductsHeader = computed(() => !props.products?.length && !props.customContentSearchResults?.length)
</script>

<style lang="scss" scoped>
h5.search__header {
  margin: 0 0 var(--spacer-sm);

  @include for-mobile {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: var(--spacer-xs);
  }
}

.search__products {
  @include for-mobile {
    order: 3;
  }

  @include for-desktop {
    grid-area: products;
    margin-left: var(--spacer-md);
  }

  .h5 {
    @include for-mobile {
      padding: 0 0 0 var(--spacer-15);
    }
  }

  .sf-scrollable__content {
    @include for-mobile {
      padding: 0 0 0 var(--spacer-xs);
    }
  }

  &-list {
    display: flex;
    flex-flow: row nowrap;

    @include for-mobile {
      padding: 0 var(--spacer-15);
      gap: var(--spacer-xs);
      overflow-x: scroll;
      scroll-snap-type: x mandatory;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .product-card {
        min-width: 140px;
        --product-card-max-width: 140px;
        scroll-snap-align: start;
        scroll-margin-left: 15px;
      }
    }

    @include for-desktop {
      display: grid;
      gap: var(--spacer-md);
      grid-template-columns: repeat(6, 1fr);
    }
  }
}
</style>
