<template>
  <TransitionGroup
    class="notifications"
    :class="{
      'notifications--visible': notifications?.length,
      'notifications--on-top-of-all': isCartSidebarOpen || isWishlistSidebarOpen,
      'notifications--top-header-visible': isTopHeaderVisible && !isCartSidebarOpen && !isWishlistSidebarOpen,
      'notifications--header-notification-visible':
        isHeaderNotificationVisible && !isCartSidebarOpen && !isWishlistSidebarOpen,
    }"
    tag="div"
    name="slide-fade"
  >
    <SfNotification
      v-for="notification in notifications"
      :key="notification.id"
      :title="notification.title"
      :message="notification.message"
      :action="notification.action && notification.action.text"
      :type="notification.type"
      :persistent="notification.persist"
      visible
      @click:close="notification.dismiss"
      @click:action="notification.action && notification.action.onClick()"
    >
      <template #icon="{ icon }">
        <ShoppingBagIcon v-if="icon === 'added_to_cart'" class="sf-notification__icon" width="24" height="24" />
        <DangerInfo v-else-if="icon === 'info_shield'" class="sf-notification__icon" width="24" height="24" />
        <SfIcon
          v-else
          class="sf-notification__icon"
          :class="{ 'display-none': !icon }"
          :icon="icon"
          size="lg"
          color="white"
        />
      </template>
      <template #title="{ title }">
        <div class="sf-notification__title" :class="{ 'display-none': !title }">{{ title }}</div>
      </template>
      <template #message="{ message }">
        <span v-if="!notification.link" class="sf-notification__message" :class="{ 'display-none': !message }">
          {{ message }}
        </span>
        <div
          v-else
          class="sf-notification__message"
          :class="{ 'display-none': !message }"
          :path="notification.message"
          tag="span"
        >
          <SfLink :link="notification.link">{{ notification.linkText }}</SfLink>
        </div>
      </template>
      <template #close>
        <SfButton
          class="sf-button--pure sf-notification__close sf-button"
          :aria-label="$t('Close notification')"
          @click="notification.dismiss"
        >
          <TimesIcon />
        </SfButton>
      </template>
    </SfNotification>
  </TransitionGroup>
</template>

<script setup>
import TimesIcon from '~/storefront/assets/icons/times-light.svg'
import ShoppingBagIcon from '~/storefront/assets/icons/shopping-bag.svg'
import DangerInfo from '~/storefront/assets/icons/danger-info.svg'

const { isTopHeaderVisible, isHeaderNotificationVisible, isCartSidebarOpen, isWishlistSidebarOpen } =
  storeToRefs(useUiState())
const { notifications } = useUiNotification()
</script>

<style lang="scss">
html.theme--storefront {
  .notifications {
    position: fixed;
    top: var(--spacer-xs);
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacer-xs);
    width: calc(100% - var(--spacer-xs));
    max-width: var(--container-width-lg);
    z-index: 8;
    transition: top 0.3s ease-out;

    @include for-desktop {
      width: calc(100% - var(--spacer-sm));
    }

    &--top-header-visible {
      top: 126px;

      @include for-desktop {
        top: 207px;
      }
    }

    &--top-header-visible.notifications--header-notification-visible {
      top: 174px;

      @include for-desktop {
        top: 257px;
      }
    }

    &--on-top-of-all {
      top: 10px;
      z-index: 10;
    }

    .sf-notification {
      --notification-border-radius: 0;
      --notification-max-width: 100%;
      --notification-padding: var(--spacer-xs) var(--spacer-lg) var(--spacer-xs) var(--spacer-xs);
      --notification-font-family: var(--font-family--primary);
      --notification-font-line-height: 1.4286;
      --notification-font-size: var(--font-size-sm);
      --notification-font-weight: var(--font-weight--normal);
      --notification-title-font-family: var(--font-family--primary);
      --notification-title-font-line-height: var(--global-line-height);
      --notification-title-font-size: var(--font-size--base);
      --notification-title-font-weight: var(--font-weight--semibold);
      --notification-icon-margin: 0 var(--spacer-xs) 0 0;
      --notification-box-shadow: none;
      width: 100%;

      @include for-desktop {
        --notification-padding: var(--spacer-xs) 3rem var(--spacer-xs) var(--spacer-sm);
        --notification-font-line-height: var(--global-line-height);
        --notification-font-size: var(--font-size--base);
        --notification-icon-margin: 0 14px 0 0;
      }

      &__title {
        margin-bottom: 1px;
      }

      &__message {
        position: relative;

        a {
          color: inherit;
          text-decoration: underline;
        }
      }

      &__close {
        --notification-close-top: 5px;
        --notification-close-right: 0;

        @include for-desktop {
          --notification-close-right: var(--spacer-xs);
        }
      }

      .sf-button {
        --button-width: var(--spacer-lg);
        --button-height: var(--spacer-lg);
        color: inherit;
      }

      &.color-info {
        --notification-background: var(--blue-background-color);
        border: 1px solid currentColor;
        color: var(--blue-color);

        .sf-icon.color-white {
          --icon-color: var(--blue-color);
        }
      }

      &.color-warning,
      &.color-danger {
        --notification-background: var(--red-background-color);
        color: var(--red-color);

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: 1px solid currentColor;
        }

        .sf-icon.color-white {
          --icon-color: var(--red-color);
        }
      }

      &.color-success {
        --notification-background: var(--notification-succes-background);
        --notification-color: var(--notification-succes-color);
        color: var(--notification-succes-color);
        border: 1px solid currentColor;

        .sf-notification__icon {
          --icon-color: var(--notification-succes-color);
        }
      }

      .sf-icon.size-lg {
        --icon-size: 2.25rem;
      }
    }
  }
}
</style>
