export type SearchSuggestQuery = {
  _br_uid_2?: string
  account_id: string
  request_id: number
  ref_url: string
  url: string
  catalog_views: string
  request_type: string
  q: string
}

export type SearchSuggestAttributeSuggestion = {
  name: string
  value: string
  attributeType: string
  query?: string
  displayText?: string
}

export type SearchSuggestSearchSuggestion = {
  sale_price: number
  sku: string
  original_price: number
  hover_image_url: string
  pid: string | number
  url: string
  title: string
  thumb_image: string
  thumbnail: string
  variants: any[]
}

export type SearchSuggestResponse = {
  suggestions: SearchSuggestAttributeSuggestion[]
  products: SearchSuggestSearchSuggestion[]
}

type ContentSearchQuery = {
  _br_uid_2?: string
  account_id: string
  catalog_name: string
  domain_key: string
  fl: string
  q: string
  ref_url: string
  request_type: string
  rows: number
  search_type: string
  start: number
  url: string
}

type ContentResult = {
  title: string
  item_id: string
  url: string
  description: string
}

export const useSearchStore = defineStore('search', () => {
  const {
    public: {
      bloomreachDiscovery: { stagingEnabled, accountId, domainKey },
    },
  } = useRuntimeConfig()
  const { locale } = useI18n()
  const logger = useAppLogger('useSearchStore')

  const state = reactive({
    term: '',
    suggestResults: <SearchSuggestResponse>{},
    contentResults: <ContentResult[]>[],
    loading: false,
  })

  const domainKeys = Object.fromEntries(domainKey.split(',').map((k) => k.split('=')))
  const currentDomainKey = domainKeys[locale.value?.substring(0, 2)] || Object.values(domainKeys)[0]

  const searchSuggestQuery = (q: string): SearchSuggestQuery => {
    const requestId = Math.floor(1000000000000 + Math.random() * 9000000000000)

    return {
      account_id: accountId,
      request_id: requestId,
      catalog_views: currentDomainKey,
      ref_url: `${document.location.protocol}//${document.location.hostname}:${document.location.port}`,
      url: `${document.location.protocol}//${document.location.hostname}:${document.location.port}`,
      request_type: 'suggest',
      q,
    }
  }

  const contentSearchQuery = (q: string): ContentSearchQuery => ({
    account_id: accountId,
    catalog_name: `content_${locale.value.substring(0, 2)}`,
    domain_key: currentDomainKey,
    fl: 'pid,title,titleArticleFeed,item_id,url,description, descriptionArticleFeed',
    q,
    ref_url: `${document?.location.protocol}//${document?.location.hostname}:${document?.location.port}`,
    request_type: 'search',
    rows: 20,
    search_type: 'keyword',
    start: 1,
    url: `${document?.location.protocol}//${document?.location.hostname}:${document?.location.port}`,
  })

  const getQueryWithbrID = (queryType: typeof searchSuggestQuery | typeof contentSearchQuery, q: string) => {
    const query = queryType(q)

    const brID = useCookie('_br_uid_2')
    if (brID.value) {
      query._br_uid_2 = encodeURIComponent(brID.value)
    }
    //generate querystring
    return Object.keys(query)
      .map((key) => {
        return `${key}=${encodeURIComponent(query[key])}`
      })
      .join('&')
  }

  const parseResponseData = (data: any) => {
    const { transformImageUrlToSize, ImageSize } = useMagentoImage()
    let parsedData = data

    parsedData.suggestionGroups?.[0]?.searchSuggestions?.forEach((doc, index: number) => {
      parsedData.suggestionGroups[0].searchSuggestions[index] = {
        ...doc,
        hover_image_url: transformImageUrlToSize(doc.hover_image_url, ImageSize.Default, 'bloomreach'),
        thumb_image: transformImageUrlToSize(doc.thumb_image, ImageSize.Default, 'bloomreach'),
        thumbnail: transformImageUrlToSize(doc.thumbnail, ImageSize.Default, 'bloomreach'),
      }
    })

    const termRegex = new RegExp(`(${state.term})`, 'gi')

    const products = parsedData.suggestionGroups?.[0].searchSuggestions?.slice(0, 6)
    const suggestions =
      parsedData.suggestionGroups?.[0]?.querySuggestions?.slice(0, 5).map(({ query, displayText }) => ({
        query,
        displayText,
        markedText: displayText.replace(termRegex, '<mark>$&</mark>'),
      })) || []

    return { products, suggestions }
  }

  const searchSuggest = async (q: string) => {
    state.loading = true

    const queryString = getQueryWithbrID(searchSuggestQuery, q)

    try {
      const result = await $fetch(
        `https://${stagingEnabled ? 'staging-' : ''}suggest.dxpapi.com/api/v2/suggest/?${queryString}`,
      )
      state.suggestResults = parseResponseData(result)
    } catch (error) {
      logger.error(error)
    } finally {
      state.loading = false
    }
  }

  const searchContent = async (q: string) => {
    state.loading = true

    const queryString = getQueryWithbrID(contentSearchQuery, q)

    try {
      const { response } = await $fetch(
        `https://${stagingEnabled ? 'staging-' : ''}core.dxpapi.com/api/v1/core/?${queryString}`,
      )
      state.contentResults = response.docs
    } catch (error) {
      logger.error(error)
    } finally {
      state.loading = false
    }
  }

  return {
    ...toRefs(state),
    searchSuggest,
    searchContent,
  }
})

export default useSearchStore
