<template>
  <SfButton class="product-card__info sf-button--pure" :link="link" data-testid="product-card__info">
    <slot name="title" v-bind="{ title, link }">
      <span class="product-card__title" data-testid="product-card__title">
        {{ title }}
      </span>
    </slot>

    <slot name="price" v-bind="{ specialPrice, regularPrice }">
      <SfPrice
        class="product-card__price"
        :class="{ 'display-none': !regularPrice }"
        :regular="regularPrice"
        :special="specialPrice ? specialPrice : null"
        data-testid="product-card__price"
      />
      <SaleLabel
        :originalPrice="regularPrice"
        :discountPrice="specialPrice"
        data-testid="product-card__special-price"
      />
    </slot>

    <slot name="color_wheels" v-bind="{ colors, hasColors, limitedColors }">
      <div
        v-if="hasColors"
        class="product-card__colors"
        :class="{ 'display-none': !hasColors }"
        data-testid="product-card__colors"
      >
        <div
          v-for="(color, i) in limitedColors"
          :key="title + color.name + i"
          class="product-card__color"
          :class="{ 'display-none': i > 3 && showBadge }"
          :style="{
            backgroundColor: color.image.indexOf('#') === 0 ? color.image : 'transparent',
            backgroundImage: getBackgroundColor(color),
          }"
          :data-color="color.name"
          data-testid="product-card__color"
        />
        <div v-if="showBadge" class="product-card__colors-more color-secondary">
          {{ `+${colors.length - 4}` }}
        </div>
      </div>
    </slot>
  </SfButton>
</template>

<script setup lang="ts">
interface Color {
  name: string
  image: string
  url: string
}

const props = defineProps({
  colors: {
    type: Array as () => Color[],
    default: () => [],
  },
  title: {
    type: String,
    default: '',
  },
  link: {
    type: [String, Object],
    default: null,
  },
  specialPrice: {
    type: String,
    default: '',
  },
  regularPrice: {
    type: String,
    default: '',
  },
})

const hasColors = computed(() => Boolean(props.colors?.length))
const limitedColors = computed(() => props.colors?.slice(0, 4) ?? [])
const showBadge = computed(() => props.colors?.length > 5)

const getBackgroundColor = (color) => {
  if (color.image.indexOf('#') === 0) return 'none'
  if (color.image.indexOf('https') === 0) return `url(${color.image})`
  return `url(https://www.my-jewellery.com/media/attribute/swatch/swatch_image/30x20/${color.image})`
}
</script>

<style lang="scss">
html.theme--storefront {
  .product-card .product-card__info.sf-link {
    display: grid;
    grid-template-areas: 'title title' 'price badge' 'colors colors';
    grid-template-columns: 1fr auto;
    padding: 0 var(--spacer-xs);
    gap: var(--spacer-2xs);
    min-height: 72px;
  }

  .product-card {
    &__title {
      grid-area: title;
      font-size: 0.825rem;
      font-weight: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 1.125rem;
    }

    &__price {
      grid-area: price;
      margin: 0;
      font-family: var(--font-family--primary);
      font-weight: 600;

      .sf-price__special,
      .sf-price__regular,
      .sf-price__old {
        font-size: var(--font-size-sm);

        @include for-mobile {
          font-size: var(--font-size-xs);
        }
      }

      .sf-price__regular {
        --price-regular-margin: 0;
      }

      .sf-price__old {
        padding-right: var(--spacer-2xs);
        color: var(--gray-secondary-color);
        text-decoration-line: line-through;
      }
    }

    &__colors {
      grid-area: colors;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      gap: 4px;
      font-size: var(--font-size-10);
      height: var(--spacer-18);
      pointer-events: none;

      &-more {
        font-size: var(--font-size-10);
        font-weight: var(--font-weight--normal);
        line-height: var(--spacer-18);
        color: var(--black-secondary-color);
      }
    }

    &__color {
      border: 0.5px solid;
      display: block;
      width: var(--spacer-xs);
      height: var(--spacer-xs);
      border-radius: 50%;
      background-position: 50% 50%;
      background-size: cover;
    }

    .sale-badge {
      margin-top: -2px;
      margin-bottom: -2px;
    }
  }
}
</style>
