<template>
  <div v-if="banners && banners.length">
    <div
      :class="`banner banner-type-${bannerType} ${isPreview ? 'has-edit-button' : ''} has-${
        banners.length || 1
      }-items mb-${marginBottom}`"
      :data-testid="bannerType"
    >
      <header v-if="showHeader" class="banner__header">
        <h2 class="banner__title">{{ params.title }} {{ params.subtitle }}</h2>
      </header>

      <component :is="currentComponent" :params="params" :banners="banners" :page="page" :style="styleAttr" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Component, Page } from '@bloomreach/spa-sdk'

import { stripTags } from '@/utils/stringHelpers'
import getBynderImageUrl from '@/utils/bynderImage'
import getSpacingClassName from '@/utils/spacing'

import GridBanner from './Banner/GridBanner.vue'
import HeroArticle from './Banner/HeroArticle.vue'
import HighlightedArticle from './Banner/HighlightedArticle.vue'
import ImageTextBanner from './Banner/ImageTextBanner.vue'
import ImageTextCards from './Banner/ImageTextCards.vue'
import LatestArticles from './Banner/LatestArticles.vue'
import MainBanner from './Banner/MainBanner.vue'
import PromotionBanner from './Banner/PromotionBanner.vue'
import RecommendedArticles from './Banner/RecommendedArticles.vue'

interface Props {
  component: Component
  page: Page
}

const props = defineProps<Props>()

const isPreview = computed(() => props.page?.isPreview())

const params = computed(() => props.component.model.meta.paramsInfo)

const showHeader = computed(() => ['image-and-text'].includes(bannerType.value))

const marginBottom = computed(() => getSpacingClassName(params.value.marginBottom || ''))

const styleAttr = computed(() =>
  marginBottom.value ? { '--mj-component-margin-bottom': `var(--margin-bottom-${marginBottom.value})` } : null,
)

const banners = computed(() =>
  // Get the banners, remove empties, convert to readable object
  Object.values(
    Object.entries(props.component.getModels())
      .filter(([k, v]) => v !== null)
      .sort(([k1], [k2]) => k1.localeCompare(k2))
      .map((v) => getBannerObject(v[1])),
  ),
)

const bannerType = computed(() =>
  // convert so we can also use it for classnames
  params.value.bannerType?.toLowerCase().replaceAll(' ', '-'),
)

const currentComponent = computed(() => {
  switch (bannerType.value) {
    case 'grid-banner':
      return GridBanner
    case 'hero-article':
      return HeroArticle
    case 'highlighted-article':
      return HighlightedArticle
    case 'image-and-text-left':
    case 'image-and-text-right':
      return ImageTextBanner
    case 'image-and-text':
      return ImageTextCards
    case 'latest-articles':
      return LatestArticles
    case 'promotion-banner':
      return PromotionBanner
    case 'recommended-no-background':
    case 'recommended-pink':
    case 'recommended-sand':
      return RecommendedArticles
    default:
      return MainBanner
  }
})

const getBannerObject = (ref) => {
  let articleData = {}
  const content = props.page.getContent(ref)
  const data = content?.getData()

  const getCategory = (data) => {
    const values = data?.category?.selectionValues
    return values ? values[0]?.label : false
  }

  const getDescription = (data) => {
    return stripTags(data?.bannerDescription?.value || '')
  }

  const getHtmlDescription = (data) => {
    return data?.bannerDescription?.value || ''
  }

  if (!data) {
    return null
  }

  if (data.article) {
    const article = props.page.getContent(data.article)?.getData()

    articleData = {
      ...article,
      content,
      category: getCategory(article),
      bannerTitle: article?.title,
      bannerDescription: article?.description || '',
      bannerDescriptionHtml: article?.description || '',
      bannerImage: getBynderImageUrl(article?.image),
      asset: article?.image,
    }
  }

  const localeCtaLink = data.ctaLink && props.page.getContent(data.ctaLink)?.getUrl()
  const newData = Object.fromEntries(
    Object.entries({
      ...data,
      content,
      category: getCategory(data),
      bannerDescription: getDescription(data),
      bannerDescriptionHtml: getHtmlDescription(data),
      bannerImage: getBynderImageUrl(data.bannerImage),
      asset: data.bannerImage,
      ctaLink: localeCtaLink,
    }).filter(([_, v]) => v !== null && v !== ''),
  )

  return { ...articleData, ...newData }
}
</script>

<style lang="scss">
html.theme--storefront {
  .banner__header {
    --heading-title-font-line-height: var(--global-line-height);
    --heading-description-font-family: var(--font-family--primary);
    --heading-description-font-size: var(--font-size-sm);
    --heading-description-margin: 0 0 var(--spacer-sm);
    --heading-description-font-line-height: var(--global-line-height);
    --heading-padding: 0 var(--spacer-xs);

    h2 {
      margin-bottom: var(--spacer-xs);
    }

    @include for-desktop {
      --heading-description-font-size: var(--font-size-md);
      --heading-description-margin: 0 0 var(--spacer-md);
    }
  }

  @include for-mobile {
    .hst-container-item:has(.banner:not(.banner-type-grid-banner):not(.banner-type-highlighted-article)) {
      grid-column: 1 / -1;
    }
  }

  .banner {
    .sf-button--invisible {
      opacity: 0;
      padding: 0;
      height: 0;
    }
  }
}
</style>
