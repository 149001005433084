import type { ExponeaProduct } from '~/types/exponea'
import useExponeaConstants from '~/utils/constants/exponea'

export interface ExponeaRecommendation {
  products: ExponeaProduct[]
  loading: boolean
}

export interface ExponeaState {
  segmentations: Map<number, string>
  segmentationsLoading: boolean
  recommendations: Map<number, ExponeaRecommendation>
}

export const useExponeaStore = defineStore('exponea', () => {
  const { $i18n } = useNuxtApp()
  const { locale } = $i18n
  const {
    SEGMENTATION_REVIEW_OR_COMPANY_FEEDBACK_ID,
    SEGMENATION_RECOMMENDATIONS_ID,
    SEGMENTATION_JEWELLERY_PREFERENCE_ID,
  } = useExponeaConstants(locale)
  const logger = useAppLogger('useExponeaStore')

  const state: ExponeaState = reactive({
    segmentations: new Map(),
    segmentationsLoading: true,
    recommendations: new Map(),
  })

  const getRecommendationById = (id: number) => {
    const recommendations = state.recommendations.get(id)
    if (!recommendations) return []
    if (recommendations.products.length > 0) return recommendations.products
    return []
  }
  const getRecommendationLoadingStateById = (id: number) => {
    const recommendations = state.recommendations.get(id)
    return recommendations?.loading === true
  }
  const getExponeaRecommendationById = (id: number) => state.recommendations.get(id)?.products
  const getSegmentationById = (id: number) => state.segmentations.get(id)

  const getSegmentations = async () => {
    await waitUntilExponeaCookieIsSet()
    await Promise.all([
      getSegmentation({ id: SEGMENTATION_REVIEW_OR_COMPANY_FEEDBACK_ID }),
      getSegmentation({ id: SEGMENATION_RECOMMENDATIONS_ID }),
      getSegmentation({ id: SEGMENTATION_JEWELLERY_PREFERENCE_ID }),
    ])
    state.segmentationsLoading = false
  }

  const getRecommendation = async ({
    id,
    productId = null,
    fillWithRandom,
    size,
    force = false,
  }: {
    id: number
    productId?: string | null
    fillWithRandom?: boolean
    size?: number
    force?: boolean
  }) => {
    // https://documentation.bloomreach.com/engagement/reference/customer-recommendations
    // Use force if you need to do a new call, otherwise it'll use the existing one
    if (!window) return
    if (!id) return logger.warn('[Exponea] No recommendation ID provided')
    await waitUntilSegmentationsAreLoaded()
    if (state.recommendations.get(id) && !force) return
    if (!state.recommendations.get(id)) {
      state.recommendations.set(id, { products: [], loading: true })
    }

    await waitUntilExponeaCookieIsSet()

    await new Promise<void>((resolve) => {
      const options = {
        recommendationId: id,
        fillWithRandom,
        size,
        catalogAttributesWhitelist: [
          'title',
          'image',
          'hover_image_url',
          'price',
          'sale_price',
          'url',
          'reviews_rating_summary',
          'review_count',
          'sku',
          'date_online',
          'url_silver',
          'url_gold',
        ],
        items: productId ? { [productId]: 1 } : {},
        callback: setProducts,
      }

      window.exponea.getRecommendation(options)

      function setProducts(products) {
        if (!products || products.length < 1) {
          logger.error(`No products found for recommendation ${id}`)
          return resolve()
        }

        const productsWithPreferenceAndTypeName = products.map((product) => {
          const preference = state.segmentations.get(SEGMENTATION_JEWELLERY_PREFERENCE_ID) ?? 'Gold'
          if (preference === 'Gold' && product.url_gold) {
            return {
              ...product,
              image: product.url_gold,
              __typename: 'ExponeaProduct',
            }
          } else if (preference === 'Silver' && product.url_silver) {
            return {
              ...product,
              image: product.url_silver,
              __typename: 'ExponeaProduct',
            }
          }
          return { ...product, __typename: 'ExponeaProduct' }
        })

        state.recommendations.set(id, { products: productsWithPreferenceAndTypeName, loading: false })
        resolve()
      }
    })
  }

  const getSegmentation = async ({ id }) => {
    if (!window || !id) return
    // https://documentation.bloomreach.com/engagement/reference/customer-segmentations-test
    if (getSegmentationById(id)) return
    const { post } = useExponeaApi()
    const customerStore = useCustomerStore()
    await waitUntilExponeaCookieIsSet()
    const userId = useCookie('__exponea_etc__').value
    const body = {
      customer_ids: {},
      attributes: [
        {
          type: 'segmentation',
          id,
        },
      ],
    }

    if (customerStore.user?.email) {
      body.customer_ids['registered'] = customerStore.user?.email
    } else {
      body.customer_ids['cookie'] = userId
    }

    if (body.customer_ids['cookie'] === undefined && body.customer_ids['registered'] === undefined) {
      return logger.warn(`[Exponea] No user id found - getSegmentation ${id}`)
    }

    try {
      const results = await post('/customers/attributes', body)
      if (!results) return
      state.segmentations.set(id, results[0]?.value)
    } catch (error) {
      logger.error(error)
    }
  }
  const waitUntilExponeaCookieIsSet = async (): Promise<void> => {
    if (import.meta.server) return
    while (!useCookie('__exponea_etc__').value) {
      await new Promise((resolve) => requestAnimationFrame(resolve))
    }
  }

  const waitUntilSegmentationsAreLoaded = async (): Promise<void> => {
    if (import.meta.server) return
    while (state.segmentationsLoading) {
      await new Promise((resolve) => requestAnimationFrame(resolve))
    }
  }

  return {
    ...toRefs(state),
    waitUntilExponeaCookieIsSet,
    waitUntilSegmentationsAreLoaded,
    getRecommendation,
    getRecommendationById,
    getExponeaRecommendationById,
    getRecommendationLoadingStateById,
    getSegmentationById,
    getSegmentations,
    getSegmentation,
  }
})

export default useExponeaStore
