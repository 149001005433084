<template>
  <div v-if="colors.length > 1" class="product-variant product-variant--related-colors">
    <div class="product-variant__heading">
      <SfProperty :name="$t('Color')" :value="selectedColorName" />
    </div>
    <div class="product-colors">
      <SfColor
        v-for="color in colors"
        :key="color.url"
        class="product-colors__color"
        :class="{ 'product__color--white': color.image.indexOf('#fff') === 0 }"
        :color="color.image.indexOf('#') === 0 ? color.image : `url(${color.image})`"
        :selected="productUrl === color.url"
        :link="localePath(`/${color.url}`)"
        :data-sku="color.sku"
        :data-id="color.id"
        :data-label="color.name"
        data-attribute_code="related-color"
        @click="navigateTo(localePath('/' + color.url))"
      >
        <template #badge>
          <SfBadge class="sf-color__badge" :class="{ 'sf-color__badge--selected': productUrl === color.url }">
            <CheckmarkIcon
              :style="{
                color: getContrast(color.image.indexOf('#') === 0 ? color.image : `url(${color.image})`),
              }"
            />
          </SfBadge>
        </template>
      </SfColor>
    </div>
  </div>
</template>

<script setup lang="ts">
import CheckmarkIcon from '~/storefront/assets/icons/checkmark-alt.svg'
import { getContrast } from '~/utils/colorHelpers'

const props = defineProps({
  sku: {
    type: String,
    required: true,
  },
})

const localePath = useLocalePath()

const { products } = storeToRefs(useProductStore())

const SILVER_ID = '61'
const GOLD_ID = '66'

const colors = computed(
  () =>
    products.value[props.sku]?.colour_wheels
      ?.map((color) => ({
        ...color,
        url: color.url?.split('/').pop(), // Obtain the product-slug.html off the absolute url
      }))
      .filter((color) => color.url) || [],
)

const selectedColorName = computed(() => {
  const baseColorOptions = products.value[props.sku].base_color.options || []
  if (baseColorOptions.some((option) => option?.value === SILVER_ID || option?.value === GOLD_ID)) {
    return ''
  }

  return products.value[props.sku].base_color.options?.[0]?.label
})

const productUrl = computed(() => products.value[props.sku]?.canonical_url || '')
</script>

<style lang="scss" scoped>
html.theme--storefront {
  .product-colors {
    display: flex;
    flex-flow: row wrap;

    &__color {
      --color-size: var(--variant-button-height);
      --color-border-radius: var(--spacer-2xs);
      background-size: 133%;
      background-position: center;
      margin: 0 var(--spacer-xs) 0 0;
      transition: all 0.12s ease;
      border: 1px solid var(--black-color);

      .sf-color__badge {
        --badge-padding: 0;
        --badge-height: 14px;
        --badge-background: none;
        opacity: 0;
        visibility: hidden;
        transition: all 0.12s ease;

        &:hover,
        &--selected {
          visibility: visible;
          opacity: 1;
        }
      }

      &--white {
        border: 1px solid var(--gray-dark-accent-color);
        --badge-color: var(--black-color);
      }

      &.is-active,
      &:hover {
        transform: none;
      }

      &.is-active {
        border: 1px solid var(--black-secondary-color);

        &:not(:hover):not(:focus) {
          --color-box-shadow-opacity: 0;
        }
      }
    }
  }
}
</style>
