<template>
  <picture
    v-if="asset && asset.type === 'IMAGE'"
    class="bynder-asset bynder-asset--image"
    :class="{ 'has-ratio': ratio }"
    :style="cssVars"
  >
    <source v-if="asset.srcsetMobile" media="(max-width: 1024px)" :srcset="asset.srcsetMobile" />
    <source
      v-if="asset.srcsetDesktop"
      :media="asset.srcsetMobile ? '(min-width: 1025px)' : null"
      :srcset="asset.srcsetDesktop"
    />
    <img
      :alt="alt || asset.title"
      :src="asset.thumbnailUrl"
      :height="asset.height"
      :width="asset.width"
      :loading="loading || 'lazy'"
      :sizes="size"
    />
  </picture>
  <div
    v-else-if="asset && asset.type === 'VIDEO'"
    ref="videoRef"
    class="bynder-asset bynder-asset--video"
    :class="{ 'has-ratio': ratio }"
    :style="cssVars"
  >
    <video v-if="showVideo" :poster="asset.thumbnailUrl" autoplay muted loop playsinline>
      <source :src="videoSource" :type="`video/${asset.extension || getExtensionFromUrl(asset.url) || 'mp4'}`" />
      Your browser does not support the video tag.
    </video>
  </div>
</template>

<script setup lang="ts">
import { getBynderAsset } from '~/utils/bynderImage'
import type { BynderAsset } from '~/utils/bynderImage'

const props = defineProps({
  data: {
    type: String,
    default: '',
  },
  dataMobile: {
    type: String,
    required: false,
  },
  size: {
    type: String,
    required: false,
    default: '100vw',
  },
  ratio: {
    type: Number,
    required: false,
  },
  ratioMobile: {
    type: Number,
    required: false,
  },
  alt: {
    type: String,
    required: false,
  },
  loading: {
    type: String,
    required: false,
  },
  transformation: {
    type: Symbol,
    required: false,
  },
})

const { isMobile } = useDevice()

const ratioPercentage = computed(() => {
  if (isMobile && props.ratioMobile) {
    return Math.round(100 * props.ratioMobile)
  }
  return props.ratio ? Math.round(100 * props.ratio) : null
})
const cssVars = computed(() => {
  if (!ratioPercentage.value) return
  return {
    '--padding-top': ratioPercentage.value + '%',
  }
})

const asset = computed(() => {
  return getBynderAsset(
    props.data,
    props.dataMobile,
    props.size,
    props.ratio,
    props.ratioMobile,
    props.transformation,
  ) as BynderAsset
})

const getExtensionFromUrl = (url) => {
  return url?.split('.')?.pop()
}

const showVideo = ref(false)
const videoRef = ref()
const observer = ref()

const videoSource = ref(isMobile && asset.value?.urlMobile ? asset.value.urlMobile : asset.value?.url)

onMounted(() => {
  if (asset.value?.type !== 'VIDEO') return
  observer.value = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        showVideo.value = true
        observer.value.unobserve(videoRef.value)
      }
    })
  })
  observer.value.observe(videoRef.value)
})

onUnmounted(() => {
  observer.value?.disconnect()
})
</script>

<style lang="scss">
html.theme--storefront {
  .bynder-asset {
    position: relative;
    display: flex;

    img,
    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    &.has-ratio {
      &:before {
        content: '';
        display: block;
        padding-top: var(--padding-top);
        width: 100%;
      }

      img,
      video {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
</style>
