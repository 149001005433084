<template>
  <div v-if="items" class="empty-cart__alternatives">
    <p v-if="title" class="empty-cart__alternatives-title">
      <strong>{{ title }}</strong>
    </p>
    <div class="empty-cart__alternatives-grid product-grid">
      <ProductCard
        v-for="(product, index) in exponeaMappedToBloomreachProducts"
        :key="index"
        class="result-card"
        :product="product"
      >
        <template #image="imageSlotProps">
          <SfButton
            class="sf-button--pure product-card__link"
            :link="imageSlotProps.link"
            aria-label="Go To Product"
            v-on="$listeners"
            data-testid="product-link"
          >
            <template v-if="Array.isArray(imageSlotProps.image)">
              <nuxt-img
                v-for="(picture, key) in imageSlotProps.image.slice(0, 2)"
                :key="key"
                class="product-card__picture"
                :src="picture"
                :alt="imageSlotProps.title"
                :width="imageSlotProps.imageWidth"
                :height="imageSlotProps.imageHeight"
              />
            </template>
            <nuxt-img
              v-else
              class="product-card__image lol"
              :src="imageSlotProps.image"
              :alt="imageSlotProps.title"
              :width="imageSlotProps.imageWidth"
              :height="imageSlotProps.imageHeight"
            />
          </SfButton>
        </template>
      </ProductCard>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ExponeaProduct } from '~/types/exponea'
import type { BloomreachProduct } from '~/types/bloomreach'

interface Props {
  title: string
  items: ExponeaProduct[]
}

const props = withDefaults(defineProps<Props>(), {
  title: '',
  items: [],
})

const mapExponeaToBloomreach = (exponeaProduct: ExponeaProduct): BloomreachProduct => ({
  brand: 'MyJewellery',
  colors: exponeaProduct.color_codes,
  date_online: exponeaProduct.date_added?.toString(),
  hover_image_url: exponeaProduct.hover_image_url,
  original_price: exponeaProduct.price,
  pid: exponeaProduct.product_id,
  price: exponeaProduct.price,
  sale_price: Number(exponeaProduct.sale_price),
  sizes: exponeaProduct.variant_code,
  sku: exponeaProduct.sku,
  thumb_image: exponeaProduct.image,
  title: exponeaProduct.title,
  url: exponeaProduct.url,
  variants: exponeaProduct.variant_code,
})

// Map Exponea products to Bloomreach products
const exponeaMappedToBloomreachProducts = computed(() => {
  return props.items.map((item) => ({
    ...mapExponeaToBloomreach(item),
  })) as BloomreachProduct[]
})
</script>

<style lang="scss">
html.theme--storefront {
  .empty-cart__alternatives {
    --heading-title-color: var(--c-text);

    align-self: stretch;

    @include for-desktop {
      padding-top: var(--spacer-base);
    }

    &-title {
      margin: -20px 0 var(--spacer-base);

      @include for-mobile {
        font-size: var(--font-size-10);
        line-height: var(--spacer-18);
        margin-top: var(--spacer-sm);
      }

      @include for-desktop {
        margin: 0 0 var(--spacer-xs);
      }
    }

    .product-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: var(--spacer-xs);
    }

    .sf-price {
      --price-regular-margin: 0;
    }
  }
}
</style>
