<template>
  <SfButton
    :class="{
      'is-disabled': loading,
    }"
    :aria-label="$t(actionText)"
    :data-sku="selectedVariant.sku"
    :data-id="selectedVariant.id"
    data-testid="add-to-wishlist"
    @click="toggleIsInWishlist"
  >
    <SfLoader :class="{ loading }" :loading="loading">
      <component :is="icon" data-test="sf-wishlist-icon" />
    </SfLoader>
  </SfButton>
</template>

<script lang="ts" setup>
import HeartIcon from '~/storefront/assets/icons/heart.svg'
import HeartIconFilled from '~/storefront/assets/icons/heart-fill.svg'
import type { Product } from '@/types/product'

const logger = useAppLogger('AddToWishList')

const props = defineProps({
  product: {
    type: Object,
    default: () => ({}),
  },
  selectedVariant: {
    type: Object,
    default: () => ({}),
  },
})

const { getProductDetails } = useProduct()
const { setProduct } = useProductStore()
const { activeProductSku } = storeToRefs(useProductStore())
const { isInWishlist: fetchIsInWishlist, removeItem } = useWishlistStore()
const { showWishlistModal } = storeToRefs(useWishlistStore())

const isInWishlist = computed(() => fetchIsInWishlist({ product: props.product }))
const icon = computed(() => (isInWishlist.value ? HeartIconFilled : HeartIcon))
const actionText = computed(() => (isInWishlist ? 'Remove from Wishlist' : 'Add to Wishlist'))

const loading = ref(false)
const toggleIsInWishlist = async () => {
  loading.value = true
  const sku = props.product?.sku
  activeProductSku.value = sku

  if (isInWishlist.value) {
    await removeItem({
      product: props.product as Product,
    })
  } else {
    showWishlistModal.value = true
  }

  // Otherwise, fetch it
  const product = await getProductDetails({
    filter: {
      sku: {
        eq: sku,
      },
    },
  })

  if (!product) {
    return logger.error('Product not found')
  }
  await setProduct(product)
  loading.value = false
}
</script>

<style lang="scss" scoped>
html.theme--storefront {
  .product__add-to-wishlist-button {
    --button-width: var(--button-height);
    --button-padding: 0;
    --button-font-size: 0;

    &.is-disabled {
      box-shadow: none;
      opacity: 0.4;
    }

    .sf-loader {
      display: flex;
      align-items: center;
      justify-content: center;
      width: var(--spacer-base);
      height: var(--spacer-base);
    }
  }
}
</style>
