<template>
  <div class="sf-collected-product">
    <div class="sf-collected-product__main">
      <div class="sf-collected-product__details">
        <slot name="title" v-bind="{ title }">
          <div class="sf-collected-product__title-wraper">
            <component :is="componentIs" class="sf-collected-product__title" :link="link ? link : ''">
              {{ title }}
            </component>
          </div>
        </slot>
        <slot name="price" v-bind="{ specialPrice, regularPrice }">
          <SfPrice :class="{ 'display-none': !regularPrice }" :regular="regularPrice" :special="specialPrice" />
        </slot>
        <div class="sf-collected-product__configuration">
          <slot name="configuration">
            <SfProperty name="Size" value="XS" />
            <SfProperty name="Color" value="white" />
          </slot>
        </div>
      </div>
      <div class="sf-collected-product__actions">
        <slot name="actions">
          <SfButton class="sf-button--text desktop-only">Save for later</SfButton>
        </slot>
      </div>
    </div>
    <div class="sf-collected-product__aside">
      <slot name="image" v-bind="{ image, title }">
        <SfImage
          class="sf-collected-product__image"
          :src="image"
          :alt="title"
          :width="imageWidth"
          :height="imageHeight"
        />
      </slot>
      <slot name="input">
        <div class="sf-collected-product__quantity-wrapper">
          <SfQuantitySelector
            class="sf-collected-product__quantity-selector"
            :qty="quantity"
            :min="minQty"
            :max="maxQty"
            @input="onInput"
          />
        </div>
      </slot>
    </div>
    <slot name="remove" v-bind="{ removeHandler }">
      <template :class="{ 'display-none': !hasRemove }">
        <SfCircleIcon
          class="sf-circle-icon--small sf-collected-product__remove sf-collected-product__remove--circle-icon"
          icon="cross"
          aria-label="Remove"
          @click="removeHandler"
        />
        <SfButton
          class="sf-button--text sf-collected-product__remove sf-collected-product__remove--text"
          data-testid="collected-product-desktop-remove"
          @click="removeHandler"
          >Remove</SfButton
        >
      </template>
    </slot>
    <slot name="more-actions" v-bind="{ actionsHandler }">
      <template :class="{ 'display-none': !hasMoreActions }">
        <SfButton
          class="sf-button--pure sf-collected-product__more-actions mobile-only"
          aria-label="More actions"
          @click="actionsHandler"
        >
          <SfIcon icon="more" size="18px" />
        </SfButton>
      </template>
    </slot>
  </div>
</template>
<script setup>
import SfLink from './Link.vue'

const props = defineProps({
  image: {
    type: String,
    default: '',
  },
  imageWidth: {
    type: [String, Number],
    default: 140,
  },
  imageHeight: {
    type: [String, Number],
    default: 200,
  },
  title: {
    type: String,
    default: '',
  },
  regularPrice: {
    type: [Number, String],
    default: null,
  },
  specialPrice: {
    type: [Number, String],
    default: null,
  },
  qty: {
    type: [Number, String],
    default: 1,
  },
  minQty: {
    type: Number,
    default: null,
  },
  maxQty: {
    type: Number,
    default: null,
  },
  link: {
    type: [String, Object],
    default: null,
  },
  hasRemove: {
    type: Boolean,
    default: true,
  },
  hasMoreActions: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits(['input', 'click:remove', 'click:actions'])
const componentIs = computed(() => (props.link ? SfLink : 'div'))
const quantity = computed(() => (typeof props.qty === 'string' ? Number(props.qty) : props.qty))

const removeHandler = () => {
  emit('click:remove')
}

const actionsHandler = () => {
  emit('click:actions')
}

const onInput = (event) => {
  emit('input', event)
}
</script>

<style lang="scss">
html.theme--storefront {
  @import './styles/organisms/SfCollectedProduct.scss';
  @import '~/storefront/assets/css/components/sf-collected-product';
}
</style>
