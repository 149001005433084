<template>
  <div
    class="bloomreach-data"
    :class="{ 'has-edit-button': page.isPreview() }"
    :data-id="identifier"
    :data-type="type"
  ></div>
</template>

<script setup lang="ts">
// Component only used to fetch data from Bloomreach, no further use here but for debugging purposes
const props = defineProps({
  component: null,
  page: null,
})

const identifier = props.component?.getProperties()?.id
const type = props.component?.getProperties()?.documentTypes?.replace('brxsaas:', '')
</script>

<style lang="scss">
html.theme--storefront {
  .hst-container:has(.bloomreach-data) {
    display: none;
  }
}
</style>
