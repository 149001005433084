<template>
  <span class="sf-badge">
    <slot />
  </span>
</template>

<script setup></script>

<style lang="scss">
html.theme--storefront {
  @import './styles/atoms/SfBadge.scss';
}
</style>
